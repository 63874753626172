import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) { }

  getCompanyCounts() {
    return this.http.get(
      environment.baseUrl +
      environment.administrationServices.DashboardService + '/companies-count-by-category'
    );

  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private http: HttpClient) {}

  getCountries() {
    return this.http
      .get(
        environment.baseUrl + environment.administrationServices.countryService
      )
      .pipe(retry(3));
  }

  updateCountry(request: any) {
    return this.http.put(
      environment.baseUrl +
        environment.administrationServices.countryService +
        `/${request.Code}`,
      request
    );
  }

  addCountry(request: any) {
    return this.http.post(
      environment.baseUrl + environment.administrationServices.countryService,
      request
    );
  }
}

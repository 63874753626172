import { ChangeDetectorRef, Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import * as uuid from 'uuid';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrl: './opening-hours.component.css',
})
export class OpeningHoursComponent implements OnInit{
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() myId: any;
  @Input() infoBlockId:string ='';

  companyForm = inject(CompanyFormService).form;
  companyOpeningHoursCommentForm =
    inject(CompanyFormService).form.controls.CompanyOpeningHoursComment;
  days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  daysChecked: number[][] = [[], [], [], []];
  TotalHoursForm = new FormGroup({
    totalDayHours: new FormControl(''),
  });
  Hour1FromFocus = false;
  Hour1ToFocus = false;
  Hour2FromFocus = false;
  Hour2ToFocus = false;
  DayFocus = false;
  errorMsg: string = '';
  disabledDays: boolean[][] = Array(10)
    .fill(null)
    .map(() => Array(7).fill(false));
  openingHours = [
    {
      Id: '',
      Dayfrom: '',
      Dayto: '',
      Hours1from: '',
      Hours2from: '',
      Hours1to: '',
      Hours2to: '',
      Childnum: 1,
      Company_id: '',
      DayHours: '',
    },
    {
      Id: '',
      Dayfrom: '',
      Dayto: '',
      Hours1from: '',
      Hours2from: '',
      Hours1to: '',
      Hours2to: '',
      Childnum: 2,
      Company_id: '',
      DayHours: '',
    },
    {
      Id: '',
      Dayfrom: '',
      Dayto: '',
      Hours1from: '',
      Hours2from: '',
      Hours1to: '',
      Hours2to: '',
      Childnum: 3,
      Company_id: '',
      DayHours: '',
    },
    {
      Id: '',
      Dayfrom: '',
      Dayto: '',
      Hours1from: '',
      Hours2from: '',
      Hours1to: '',
      Hours2to: '',
      Childnum: 4,
      Company_id: '',
      DayHours: '',
    },
  ];
  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private subjectService:SubjectService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.DayFocus = true;
        this.Hour1FromFocus = true;
        this.Hour1ToFocus = true;
        this.Hour2FromFocus = true;
        this.Hour2ToFocus = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      if (this.companyData.CompanyOpeningHours.length) {
        this.getCompanyHours().clear();
        this.daysChecked = Array.from(
          { length: this.companyData.CompanyOpeningHours.length },
          () => []
        );
        this.companyData.CompanyOpeningHours.sort(
          (a: any, b: any) => a.Childnum - b.Childnum
        );
        this.companyData.CompanyOpeningHours.forEach((item: any) => {
          const OpeningHourGroup = new FormGroup({
            Id: new FormControl(item.Id),
            Dayfrom: new FormControl(item.Dayfrom, this.DaysValidation()),
            Dayto: new FormControl(item.Dayto),
            Hours1from: new FormControl(
              item.Hours1from.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Hours2from: new FormControl(
              item.Hours2from.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Hours1to: new FormControl(
              item.Hours1to.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Hours2to: new FormControl(
              item.Hours2to.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Childnum: new FormControl(item.Childnum),
            Company_id: new FormControl(item.Company_id),
            DayHours: new FormControl(item.DayHours),
          });
          if (!item.Dayfrom) {
            OpeningHourGroup.disable();
          }
          this.getCompanyHours().push(OpeningHourGroup);
        });
        this.populateDaysChecked(this.companyData.CompanyOpeningHours);
        this.calculateTotalDayHours();
        this.updateDisabledDays();
      } else {
        this.setFormValues();
      }
      this.companyOpeningHoursCommentForm.patchValue({
        Company_id: this.companyData?.Id,
        Txt: this.companyData.CompanyOpeningHoursComment?.Txt,
      });
      this.TotalHoursForm.controls.totalDayHours.disable();
    } else {
      this.companyForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (this.companyData.CompanyOpeningHours.length == 0) {
        this.setFormValues();
      } else {
        this.getCompanyHours().clear();
        this.companyData.CompanyOpeningHours.sort(
          (a: any, b: any) => a.Childnum - b.Childnum
        );
        this.companyData.CompanyOpeningHours.forEach((item: any) => {
          const OpeningHourGroup = new FormGroup({
            Id: new FormControl(item.Id),
            Dayfrom: new FormControl(item.Dayfrom, this.DaysValidation()),
            Dayto: new FormControl(item.Dayto),
            Hours1from: new FormControl(
              item.Hours1from.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Hours2from: new FormControl(
              item.Hours2from.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Hours1to: new FormControl(
              item.Hours1to.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Hours2to: new FormControl(
              item.Hours2to.trim(),
              Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
            ),
            Childnum: new FormControl(item.Childnum),
            Company_id: new FormControl(item.Company_id),
            DayHours: new FormControl(item.DayHours),
          });
          if (!item.Dayfrom) {
            OpeningHourGroup.disable();
          }
          this.getCompanyHours().push(OpeningHourGroup);
        });
        this.populateDaysChecked(this.companyData.CompanyOpeningHours);
        this.calculateTotalDayHours();
        this.updateDisabledDays();
      }
      this.companyOpeningHoursCommentForm.patchValue({
        Company_id: this.myId,
        Txt: this.companyData.CompanyOpeningHoursComment?.Txt,
      });
      this.TotalHoursForm.controls.totalDayHours.disable();
      if (!this.companyData.CompanyOpeningHoursComment) {
        this.companyData.CompanyOpeningHoursComment = {};
      }
    }
  }

  getControls(index: any, fieldName: string) {
    return (this.getCompanyHours().at(index) as FormGroup).controls[fieldName];
  }

  isDisabled(rowIndex: number, dayIndex: number): boolean {
    return this.disabledDays[rowIndex][dayIndex];
  }

  updateDisabledDays() {
    this.disabledDays = Array(10)
      .fill(null)
      .map(() => Array(7).fill(false));
    this.daysChecked.forEach((days, rIndex) => {
      days?.forEach((day) => {
        for (let i = 0; i < this.disabledDays.length; i++) {
          if (i !== rIndex) {
            this.disabledDays[i][day] = true;
          } else {
            this.disabledDays[i][day] = false;
          }
        }
      });
    });
  }

  getControlValue(index: any) {
    let data = '';
    for (let field of ['Hours1from', 'Hours1to', 'Hours2from', 'Hours2to']) {
      data = (this.getCompanyHours().at(index) as FormGroup).controls[field]
        .value;
      if (data) {
        this.errorMsg = 'You must select days as well';
        break;
      }
    }
    return data;
  }

  getErrorMsgs(index: any, fieldName: string, focus: boolean = false) {
    const control = (this.getCompanyHours().at(index) as FormGroup).controls[
      fieldName
    ];
    if (control.hasError('pattern')) {
      return 'Time format is not correct - ex 23:00';
    }
    if (control.hasError('required')) {
      return 'Enter a timespan as well';
    }
    if (control.hasError('DaysMoreThanThree')) {
      return 'You can select either a from-to period or at most 3 individual days on each row';
    }
    return '';
  }

  getCompanyHours() {
    return this.companyForm.get('CompanyOpeningHours') as FormArray;
  }

  getLabelText(labelId:string,infoBlockId:string){
    return this.appService.getLabelText(labelId,infoBlockId)
  }


  setFormValues() {
    this.getCompanyHours().clear();
    this.openingHours.sort((a: any, b: any) => a.Childnum - b.Childnum);
    this.openingHours.forEach((item: any) => {
      const OpeningHourGroup = new FormGroup({
        Id: new FormControl(uuid.v4()),
        Dayfrom: new FormControl('', this.DaysValidation()),
        Dayto: new FormControl(''),
        Hours1from: new FormControl(
          '',
          Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
        ),
        Hours2from: new FormControl(
          '',
          Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
        ),
        Hours1to: new FormControl(
          '',
          Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
        ),
        Hours2to: new FormControl(
          '',
          Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')
        ),
        Childnum: new FormControl(item.Childnum),
        Company_id: new FormControl(this.myId),
        DayHours: new FormControl(''),
      });
      OpeningHourGroup.disable();
      this.getCompanyHours().push(OpeningHourGroup);
    });
  }
  handleDay(event: any, data: any, index: any, formIndex: any) {
    const openingHours = this.getCompanyHours().at(formIndex) as FormGroup;    
    if (event.target.checked) {
      this.daysChecked[formIndex].push(index);
      //this.uncheckDayInOtherRows(index, formIndex);
    } else {
      // if(index == 0 || this.daysChecked[formIndex].indexOf(index) == 0){
      //   this.daysChecked[formIndex].splice(this.daysChecked[formIndex].indexOf(index),1)
      // }
      // else{
      //   this.daysChecked[formIndex] = this.daysChecked[formIndex].filter(day => day < index);
      // }
      this.daysChecked[formIndex].splice(
        this.daysChecked[formIndex].indexOf(index),
        1
      );
    }
    this.daysChecked[formIndex].sort();
    //this.ensureSequentialDays(formIndex);
    this.updateDisabledDays();
    this.daysChecked.forEach((days, index) => {
      if (days.length !== 0) {
        (this.getCompanyHours().at(index) as FormGroup).enable();
        openingHours.get('Hours1from')?.addValidators([Validators.required]);
        openingHours.get('Hours1to')?.addValidators([Validators.required]);
        this.Hour1FromFocus = true;
        this.Hour1ToFocus = true;
        openingHours.get('Hours1from')?.updateValueAndValidity()
        openingHours.get('Hours1to')?.updateValueAndValidity()
        this.cdr.detectChanges()
      } else {
        (this.getCompanyHours().at(index) as FormGroup).disable();
        (this.getCompanyHours().at(index) as FormGroup)
          .get('Hours1from')
          ?.setValue('');
        (this.getCompanyHours().at(index) as FormGroup)
          .get('Hours1to')
          ?.setValue('');
        (this.getCompanyHours().at(index) as FormGroup)
          .get('Hours2from')
          ?.setValue('');
        (this.getCompanyHours().at(index) as FormGroup)
          .get('Hours2to')
          ?.setValue('');
        (this.getCompanyHours().at(index) as FormGroup)
          .get('DayHours')
          ?.setValue('');
      }
    });

    if (this.daysChecked[formIndex].length == 1) {
      openingHours
        .get('Dayfrom')
        ?.setValue(
          this.days[this.daysChecked[formIndex][0]]?.toLocaleLowerCase() || ''
        );
      openingHours.get('Dayto')?.setValue('');
    } else if (this.isInSequence(formIndex)) {
      openingHours
        .get('Dayfrom')
        ?.setValue(
          this.days[this.daysChecked[formIndex][0]]?.toLocaleLowerCase() || ''
        );
      openingHours
        .get('Dayto')
        ?.setValue(
          this.days[
            this.daysChecked[formIndex][this.daysChecked[formIndex].length - 1]
          ]?.toLocaleLowerCase() || ''
        );
    } else if (!this.isInSequence(formIndex) && this.daysChecked[formIndex].length <=3 ) {
      let days = [];
      for (let day of this.daysChecked[formIndex]) {
        days.push(this.days[day]);
      }
      openingHours
        .get('Dayfrom')
        ?.setValue(days.join(',')?.toLocaleLowerCase() || '');
      openingHours.get('Dayto')?.setValue('');
    }
    else if (this.daysChecked[formIndex].length > 3) {
      if(this.findSequences(formIndex)){
        const mid = Math.ceil(this.daysChecked[formIndex].length / 2);
        const firstPart = this.daysChecked[formIndex].slice(0, mid);
        const secondPart = this.daysChecked[formIndex].slice(mid);
        const arr = [firstPart, secondPart];      
        let dayFrom = [];
        let dayTo = [];
        for (let day of arr[0]) {
          dayFrom.push(this.days[day]);
        }for (let day of arr[1]) {
          dayTo.push(this.days[day]);
        }
        openingHours
          .get('Dayfrom')
          ?.setValue(dayFrom.join(',')?.toLocaleLowerCase() || '');
        openingHours.get('Dayto')?.setValue(dayTo.join(',')?.toLocaleLowerCase() || '');
      }
      else{
        let days = [];
      for (let day of this.daysChecked[formIndex]) {
        days.push(this.days[day]);
      }
      openingHours
        .get('Dayfrom')
        ?.setValue(days.join(',')?.toLocaleLowerCase() || '');
      openingHours.get('Dayto')?.setValue('');
      }
      
    }
    // else if(this.daysChecked[formIndex].length){
    //   let days =[];
    //   for(let day of this.daysChecked[formIndex]){
    //     days.push(this.days[day])
    //   }
    //   openingHours.get('Dayfrom')?.setValue((days.join(','))?.toLocaleLowerCase()|| '');
    //   openingHours.get('Dayto')?.setValue('');
    // }
    else {
      openingHours.get('Dayfrom')?.setValue('');
      openingHours.get('Dayto')?.setValue('');
    }
    this.calculateDayHours(formIndex);
    this.calculateTotalDayHours();
    this.companyForm.controls.CompanyOpeningHours.markAsDirty()
  }

  // uncheckDayInOtherRows(dayIndex: number, currentRowIndex: number) {
  //   this.daysChecked.forEach((days, rowIndex) => {
  //     if (rowIndex !== currentRowIndex && days.includes(dayIndex)) {
  //       days.splice(days.indexOf(dayIndex), 1);
  //       const openingHours = this.getCompanyHours().at(rowIndex) as FormGroup;
  //       openingHours.get('Dayfrom')?.setValue('');

  //     }
  //   });
  // }

  // ensureSequentialDays(formIndex: number) {
  //   const selectedDays = this.daysChecked[formIndex];
  //   if (selectedDays.length > 1) {
  //     const startDay = selectedDays[0];
  //     const endDay = selectedDays[selectedDays.length - 1];
  //     for (let i = startDay; i <= endDay; i++) {
  //       if (!selectedDays.includes(i)) {
  //         selectedDays.push(i);
  //         this.uncheckDayInOtherRows(i, formIndex);
  //       }
  //     }
  //     selectedDays.sort();
  //   }
  // }

  isInSequence(formIndex: number): boolean {
    const arr = this.daysChecked[formIndex];
    if (arr.length < 2) {
      return true;
    }

    const sortedArr = arr.slice().sort((a, b) => a - b);
    for (let i = 1; i < sortedArr.length; i++) {
      if (sortedArr[i] !== sortedArr[i - 1] + 1) {
        return false;
      }
    }
    return true;
  }

  isPartofSequence(formIndex: number): boolean {
    const arr = this.daysChecked[formIndex];
    if (arr.length < 2) {
      return true;
    }

    const sortedArr = arr.slice().sort((a, b) => a - b);
    for (let i = 0; i < sortedArr.length - 1; i++) {
      let currentSequence = sortedArr[i];
      let j = i + 1;
      while (j < sortedArr.length && arr[j] === currentSequence + 1) {
        j++;
      }
      if (j - 1 >= 2) {
        return true;
      }
    }

    return false;
  }

  DaysValidation() {
    return (control: AbstractControl): ValidationErrors | null => {
      let error = false;
      let days = control.value?.split(',');
      if (days?.length > 3) {
        error = true;
      }
      return error ? { DaysMoreThanThree: { value: control.value } } : null;
    };
  }

  findSequences(formIndex: number): boolean {
    let daysIndex = this.daysChecked[formIndex];
    if(!(daysIndex[0] === 0 && daysIndex[daysIndex.length-1] === 6)){
      return false;
    }
    if (daysIndex.length === 0) return false;
    let sequences: number[][] = [];
    let currentSequence: number[] = [daysIndex[0]];
  
    for (let i = 1; i < daysIndex.length; i++) {
      if (daysIndex[i] === daysIndex[i - 1] + 1) {
        currentSequence.push(daysIndex[i]);
      } else {
        sequences.push(currentSequence);
        currentSequence = [daysIndex[i]];
      }
    }
    sequences.push(currentSequence);
  
    // Filter sequences based on the new rules
    // const validSequences = sequences.filter(seq => 
    //   (seq.length > 1 && (seq[0] === 0 || seq[seq.length - 1] === 6))
    // );
    // Return true if there are any valid sequences
    return sequences.length === 2;
  }

  populateDaysChecked(data: any) {
    data.forEach((item: any, index: any) => {
      if (item.Dayfrom && item.Dayto) {
        const dayFrom = item.Dayfrom?.split(',')
        const dayTo = item.Dayto?.split(',')

        if(dayFrom.length === 1 && dayTo.length === 1){
          const dayFromIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === item.Dayfrom?.toLowerCase()
          );
          const dayToIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === item.Dayto?.toLowerCase()
          );
          if (dayFromIndex > dayToIndex) {
            this.daysChecked[index].push(dayFromIndex);
            this.daysChecked[index].push(dayToIndex);
          }
          else if (dayFromIndex !== -1 && dayToIndex !== -1) {
            for (let i = dayFromIndex; i <= dayToIndex; i++) {
              this.daysChecked[index].push(i);
            }
          }
        }
        else{
        for (let dayPresent of dayFrom) {
          const dayFromIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === dayPresent?.toLowerCase()
          );
          if (dayFromIndex !== -1) {
            this.daysChecked[index].push(dayFromIndex);
          }
        }
        for (let dayPresent of dayTo) {
          const dayToIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === dayPresent?.toLowerCase()
          );
          if (dayToIndex !== -1) {
            this.daysChecked[index].push(dayToIndex);
          }
        }
        }
        const openingHours = this.getCompanyHours().at(index) as FormGroup;  
        openingHours.get('Hours1from')?.addValidators([Validators.required]);
        openingHours.get('Hours1to')?.addValidators([Validators.required]);
        this.Hour1FromFocus = true;
        this.Hour1ToFocus = true;
        openingHours.get('Hours1from')?.updateValueAndValidity()
        openingHours.get('Hours1to')?.updateValueAndValidity()
      } else {
        const dayToIndex = this.days.findIndex(
          (day) => day?.toLowerCase() === item.Dayto?.toLowerCase()
        );
        const days = item.Dayfrom?.split(',');
        for (let dayPresent of days) {
          const dayFromIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === dayPresent?.toLowerCase()
          );
          if (dayFromIndex !== -1) {
            this.daysChecked[index].push(dayFromIndex);
            const openingHours = this.getCompanyHours().at(index) as FormGroup;  
            openingHours.get('Hours1from')?.addValidators([Validators.required]);
            openingHours.get('Hours1to')?.addValidators([Validators.required]);
            this.Hour1FromFocus = true;
            this.Hour1ToFocus = true;
            openingHours.get('Hours1from')?.updateValueAndValidity()
            openingHours.get('Hours1to')?.updateValueAndValidity()
          }
        }
        if (dayToIndex !== -1) {
          this.daysChecked[index].push(dayToIndex);
          const openingHours = this.getCompanyHours().at(index) as FormGroup;  
          openingHours.get('Hours1from')?.addValidators([Validators.required]);
          openingHours.get('Hours1to')?.addValidators([Validators.required]);
          this.Hour1FromFocus = true;
          this.Hour1ToFocus = true;
          openingHours.get('Hours1from')?.updateValueAndValidity()
          openingHours.get('Hours1to')?.updateValueAndValidity()
        }
      }
    });
  }

  isChecked(data: any, index: any) {    
    if (data.value.Dayfrom && data.value.Dayto) {
        const dayFrom = data.value.Dayfrom?.split(',');
        const dayTo = data.value.Dayto?.split(',');
        if(dayFrom.length === 1 && dayTo.length === 1){
          const dayFromIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === data.value.Dayfrom?.toLowerCase()
          );
          const dayToIndex = this.days.findIndex(
            (day) => day?.toLowerCase() === data.value.Dayto?.toLowerCase()
          );
          if (dayFromIndex > dayToIndex) {
            if (index === dayFromIndex || index === dayToIndex) {
              return true;
            }
          }
          if (index >= dayFromIndex && index <= dayToIndex) {
            return true;
          }
        }
        else{
          for (let dayPresent of dayFrom) {
            const dayFromIndex = this.days.findIndex(
              (day) => day?.toLowerCase() === dayPresent?.toLowerCase()
            );
            if (index === dayFromIndex) {
              return true;
            }
          }
          for (let dayPresent of dayTo) {
            const dayToIndex = this.days.findIndex(
              (day) => day?.toLowerCase() === dayPresent?.toLowerCase()
            );
            if (index === dayToIndex) {
              return true;
            }
          }
        }
    } else {
      const dayToIndex = this.days?.findIndex(
        (day) => day?.toLowerCase() === data.value.Dayto?.toLowerCase()
      );
      const days = data.value.Dayfrom?.split(',') || [];
      for (let dayPresent of days) {
        const dayFromIndex = this.days?.findIndex(
          (day) => day?.toLowerCase() === dayPresent?.toLowerCase()
        );
        if (index === dayFromIndex) {
          return true;
        }
      }
      if (index === dayToIndex) {
        return true;
      }
    }
    return false;
  }

  calculateDayHours(formIndex: number) {
    const openingHours = this.getCompanyHours().at(formIndex) as FormGroup;
    const Hours1from = openingHours.get('Hours1from')?.value;
    const Hours1to = openingHours.get('Hours1to')?.value;
    const Hours2from = openingHours.get('Hours2from')?.value;
    const Hours2to = openingHours.get('Hours2to')?.value;
    const totalDays = this.daysChecked[formIndex].length;
    const time1 = this.getTimeDiff(Hours1from, Hours1to);
    const time2 = this.getTimeDiff(Hours2from, Hours2to);

    let totalMinutes = (isNaN(time1) ? 0 : time1) + (isNaN(time2) ? 0 : time2);
    totalMinutes *= totalDays;

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const totalTime = `${totalHours}:${
      remainingMinutes < 10 ? '0' : ''
    }${remainingMinutes}`;

    if (!isNaN(totalHours) && totalTime !== '0:00') {
      openingHours.get('DayHours')?.setValue(totalTime);
      this.calculateTotalDayHours();
    }
  }

  calculateTotalDayHours() {
    const totalMinutes = this.getCompanyHours()
      .getRawValue()
      ?.reduce((acc, time) => {
        let [hours, minutes] = [0, 0];
        if (time.DayHours) {
          [hours, minutes] = time.DayHours?.split(':').map(Number);
        }
        return acc + hours * 60 + minutes;
      }, 0);
    if (totalMinutes === 0) {
      this.TotalHoursForm.controls.totalDayHours.setValue('');
    } else {
      const totalHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      const totalTime = `${totalHours}:${
        remainingMinutes < 10 ? '0' : ''
      }${remainingMinutes}`;
      if (!isNaN(totalHours) && totalTime !== '0:00') {
        this.TotalHoursForm.controls.totalDayHours.setValue(totalTime);
      }
    }
  }

  getTimeDiff(startTime: any, endTime: any) {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const diffMs = end.getTime() - start.getTime();
    return diffMs / (1000 * 60);
  }

  saveDraft() {
    this.companyData.CompanyOpeningHours = this.getCompanyHours().getRawValue();
    this.companyData.CompanyOpeningHoursComment.Txt =
    this.companyOpeningHoursCommentForm.get('Txt')?.value || '';
    if (this.companyForm.controls.CompanyOpeningHours.dirty) {
      this.companyData.CompanyOpeningHours.forEach((element:any) => {        
        element.IsUpdated = true;
        element.Company_id = this.companyData.Id;
      });
    }
    if (this.companyForm.controls.CompanyOpeningHoursComment.dirty) {
      this.companyData.CompanyOpeningHoursComment.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

<div class="p-5">
  <tds-breadcrumbs>
    <tds-breadcrumb>
      <a routerLink="/">Dashboard</a>
    </tds-breadcrumb>

    <tds-breadcrumb current="{{ mode === '' }}">
      <a (click)="discardAll()">Address Types</a>
    </tds-breadcrumb>
    <tds-breadcrumb current class="{{ mode === '' ? 'hidden' : '' }}">
      <a>{{ mode == "Edit" ? "Details" : mode }} </a>
    </tds-breadcrumb>
  </tds-breadcrumbs>
</div>

<app-tab-component
  [actionList]="actionList"
  [selectedIndex]="selectedIndex"
  (emitIndex)="onEmitIndex($event)"
  (emitDeleteIndex)="onEmitDeleteIndex($event)"
></app-tab-component>

@for (item of actionList; track $index) { @if(item.name =='List' &&
item.active){
<app-list-adress-type
  [addressTypes]="addressTypes"
  (addressType)="getAddressType($event)"
  (mode)="getMode($event)"
  [displayOrderUrl]="displayOrderUrl"
  [selectedValues]="selectedValues"
></app-list-adress-type>

} @else if(item.name !='' && item.active) {
<app-add-edit-address-types
  [addressType]="item.data"
  [addressTypes]="addressTypes"
  [mode]="item.mode || ''"
  (setMode)="setMode($event)"
  (updateList)="updatedList($event)"
  [highestDisplayOrder]="highestDisplayOrder"
></app-add-edit-address-types>
} }

<!-- @if(mode!==''){
<app-add-edit-address-types
  [addressType]="addressType"
  [addressTypes]="addressTypes"
  [mode]="mode"
  (setMode)="setMode($event)"
  (updateList)="updatedList($event)"
  [highestDisplayOrder]="highestDisplayOrder"
></app-add-edit-address-types>
} @else{

<app-list-adress-type
  [addressTypes]="addressTypes"
  (addressType)="getAddressType($event)"
  (mode)="getMode($event)"
  [displayOrderUrl]="displayOrderUrl"
></app-list-adress-type>
} -->

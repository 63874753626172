<form [formGroup]="companyOtherCardsForm" (change)="saveDraft()">
  @if(this.mode == 'print'){ @for(options of this.OtherTxt?.value?.split(',');
  track $index ;let optionIndex = $index){
  <div class="ml-4">
    <span style="font-size: 14px">{{ options }}</span>
  </div>
  } }@else{
  <div class="flex flex-row overflow-auto no-scrollbar">
    <div class="p-2 w-full">
      <span class="font-bold"
        *ngIf="OtherCards_Title"
      >
        {{ OtherCards_Title }}
      </span>
      <div>
        <tds-textarea
          ngDefaultControl
          row="5"
          type="text"
          size="sm"
          formControlName="OtherTxt"
          (tdsFocus)="otherTxtFocus = true"
          (tdsBlur)="otherTxtFocus = true"
          [helper]="
            utilService.getErrorMsgs(
              OtherTxt,
              'Other Information',
              otherTxtFocus
            )
          "
          [state]="
            OtherTxt?.invalid &&
            (OtherTxt?.dirty || OtherTxt?.touched || otherTxtFocus)
              ? 'error'
              : 'success'
          "
          max-length="200"
        >
        </tds-textarea>
      </div>
    </div>
  </div>
  }
</form>

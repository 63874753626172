import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';
import { Router } from '@angular/router';
import { UtilsService } from '../../utils.service';

@Component({
  selector: 'app-deleted',
  standalone: false,
  templateUrl: './deleted.component.html',
  styleUrl: './deleted.component.css',
})
export class DeletedComponent implements OnChanges {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId: string = '';

  constructor(
    public CompanyService: CompanyService,
    public appService: AppService,
    public router: Router,
    private utilsServices: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData']?.currentValue) {
      this.companyData = changes['companyData']?.currentValue;
    }
  }

  MovetoCategory() {
    this.CompanyService.MovetoCategory(
      this.companyData.CompanyDeleted.Company_id,
      this.companyData.CompanyDeleted.Old_category_id
    ).subscribe((data: any) => {
      this.utilsServices.alert('Moved Successfully!');
      this.router.navigateByUrl('/');
    });
  }
}

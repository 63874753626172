import { Injectable } from '@angular/core';
import { CompanyInformationComponent } from '../common/infoblocks/company-information/company-information.component';
import { BankInformationComponent } from '../common/infoblocks/bank-information/bank-information.component';
import { PartsLogisticsComponent } from '../common/infoblocks/parts-logistics/parts-logistics.component';
import { DealerWorkshopComponent } from '../common/infoblocks/dealer-workshop/dealer-workshop.component';
import { ProductServicesComponent } from '../common/infoblocks/product-services/product-services.component';
import { OpeningHoursComponent } from '../common/infoblocks/opening-hours/opening-hours.component';
import { ScaniaAssistanceComponent } from '../common/infoblocks/scania-assistance/scania-assistance.component';
import { PhoneFaxComponent } from '../common/infoblocks/phone-fax/phone-fax.component';
import { AddressesComponent } from '../common/infoblocks/addresses/addresses.component';
import { ImportsComponent } from '../common/infoblocks/imports/imports.component';
import { ScaniaDealerOperatingStandardComponent } from '../common/infoblocks/scania-dealer-operating-standard/scania-dealer-operating-standard.component';
import { AssistanceNotesComponent } from '../common/infoblocks/scania-assistance/assistance-notes/assistance-notes.component';
import { DistributorOptionsComponent } from '../common/infoblocks/distributor-options/distributor-options.component';
import { PaymentMethodsComponent } from '../common/infoblocks/payment-methods/payment-methods.component';
import { OtherInformationComponent } from '../common/infoblocks/other-information/other-information.component';
import { ContactInformationComponent } from '../common/infoblocks/contact-information/contact-information.component';
import { CategorizationComponent } from '../common/infoblocks/categorization/categorization.component';
import { AdditionalInformationComponent } from '../common/infoblocks/additional-information/additional-information.component';
import { LocationComponent } from '../common/infoblocks/location/location.component';
import { EmergencyBreakdownChargesComponent } from '../common/infoblocks/emergency-breakdown-charges/emergency-breakdown-charges.component';
import { WebContactsComponent } from '../common/infoblocks/web-contacts/web-contacts.component';
import { HasScaniaAssistanceComponent } from '../common/infoblocks/has-scania-assistance/has-scania-assistance.component';
import { ContractedPartnerInfoComponent } from '../common/infoblocks/contracted-partner-info/contracted-partner-info.component';
import { SocialMediaTypeComponent } from '../common/infoblocks/social-media-type/social-media-type.component';
import { UsedVehiclePowerSolutionsComponent } from '../common/infoblocks/used-vehicle-power-solutions/used-vehicle-power-solutions.component';
import { DeletedComponent } from '../common/infoblocks/deleted/deleted.component';
import { WorkshopCapabilitiesComponent } from '../common/infoblocks/workshop-capabilities/workshop-capabilities.component';
import { environment } from '../../environments/environment';
import { AdressTypesHomeComponent } from '../administration/addressTypes/adress-types-home/adress-types-home.component';
import { AddEditAddressTypesComponent } from '../administration/addressTypes/add-edit-address-types/add-edit-address-types.component';
import { ListAdressTypeComponent } from '../administration/addressTypes/list-adress-type/list-adress-type.component';

const validationRuleConfig = [
  {
    rule: 'e164phoneformat',
    regex: /^\+[1-9]\d{1,14}$/,
    message:
      '(I) No spaces, parenthesis or other punctuation allowed. (II) Number should not have leading zero. (III) Country calling code should not be missing. (IV) Number must start with a ”+”. for Ex : +263665689880',
  },
  {
    rule: 'intlphonescania',
    regex: /^\(?\+?[\d\(\s\)]+$/,
    message:
      "Invalid phone number format - Start with '+' then numbers. Characters '(' and ')' are also allowed",
  },
  {
    rule: 'phonescania',
    regex: '',
  },
  {
    rule: 'email',
    regex: '/^[^s@]+@[^s@]+.[^s@]+$/',
    message: 'Email format is not valid - ex test@scania.com',
  },
  {
    rule: 'url',
    regex:
      '/(https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(.[a-zA-Z]{2,})(.[a-zA-Z]{2,})?/[a-zA-Z0-9]{2,}|((https://www.|http://www.|https://|http://)?[a-zA-Z]{2,}(.[a-zA-Z]{2,})(.[a-zA-Z]{2,})?)|(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})?/g',
    message: 'Invalid url format',
  },
  {
    rule: 'urlnoprotocol',
    regex:
      '/www.?[a-zA-Z]{2,}(.[a-zA-Z]{2,})(.[a-zA-Z]{2,})?/[a-zA-Z0-9]{2,}|(www.?[a-zA-Z]{2,}(.[a-zA-Z]{2,})(.[a-zA-Z]{2,})?)|www.?[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}.[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})?/g',
    message: 'WWW format is not correct - ex www.scania.com',
  },
];

const InfoBlockMapping = [
  {
    name: 'Company',
    componentName: CompanyInformationComponent,
    formName: ['companyInformation'],
  },
  {
    name: 'BankInformation',
    componentName: BankInformationComponent,
    formName: ['bankInfoForm'],
  },
  {
    name: 'PartsLogistics',
    componentName: PartsLogisticsComponent,
    formName: ['partsLogisticForm'],
  },
  {
    name: 'DealerWorkshop',
    componentName: DealerWorkshopComponent,
    formName: ['CompanyDealerWorkshop'],
  },
  {
    name: 'Services',
    componentName: ProductServicesComponent,
    formName: ['productAndServicesForm'],
  },
  {
    name: 'OpeningHours',
    componentName: OpeningHoursComponent,
    formName: ['CompanyOpeningHours', 'CompanyOpeningHoursComment'],
  },
  {
    name: 'Assistance',
    componentName: HasScaniaAssistanceComponent,
    formName: ['CompanyAssistance'],
  },
  {
    name: 'CompanyPhones',
    componentName: PhoneFaxComponent,
    formName: ['phoneFaxForm'],
  },

  {
    name: 'CompanyAddresses',
    componentName: AddressesComponent,
    formName: ['addressForm'],
  },
  {
    name: 'DistributorImport',
    componentName: ImportsComponent,
    formName: ['CompanyDistributorImport'],
  },
  {
    name: 'DOS',
    componentName: ScaniaDealerOperatingStandardComponent,
    formName: ['CompanyDOSCertification', 'CompanyDealerWorkshop'],
  },
  {
    name: 'ScaniaAssistanceList',
    componentName: ScaniaAssistanceComponent,
    formName: [
      'CompanyAssistanceNotesForm',
      'CompanyAssistanceProductServiceForm',
      'CompanyAssistanceRoleDetailsForm',
    ],
  },
  {
    name: 'ScaniaAssistanceNote',
    componentName: AssistanceNotesComponent,
    formName: ['CompanyAssistanceNotesForm'],
  },
  {
    name: 'DistributorOptions',
    componentName: DistributorOptionsComponent,
    formName: ['CompanyDistributorOptions'],
  },
  {
    name: 'AcceptedCreditCards',
    componentName: PaymentMethodsComponent,
    formName: ['paymentMethodForm'],
  },
  {
    name: 'OtherCards',
    componentName: OtherInformationComponent,
    formName: ['CompanyOtherCards'],
  },
  {
    name: 'OtherContacts',
    componentName: WebContactsComponent,
    formName: ['contactForm'],
  },
  {
    name: 'Categorization',
    componentName: CategorizationComponent,
    formName: ['categorizationForm'],
  },
  {
    name: 'AdministrativeIssues',
    componentName: AdditionalInformationComponent,
    formName: ['CompanyAdministrativeIssues'],
  },
  {
    name: 'Location',
    componentName: LocationComponent,
    formName: ['CompanyLocation'],
  },
  {
    name: 'AssistanceWorkoutCharges',
    componentName: EmergencyBreakdownChargesComponent,
    formName: ['CompanyAssistanceWorkoutCharges'],
  },
  {
    name: 'GlobalManagementList',
    componentName: ContactInformationComponent,
    formName: ['CompanyGlobalMangementConatctsForm'],
  },

  {
    name: 'ContractedPartners',
    componentName: ContractedPartnerInfoComponent,
    formName: ['CompanyContractedPartner'],
  },
  {
    name: 'SocialMediaType',
    componentName: SocialMediaTypeComponent,
    formName: ['CompanySocialMediaContacts'],
  },
  {
    name: 'UsedVehicleAndPowerSolutions',
    componentName: UsedVehiclePowerSolutionsComponent,
    formName: ['CompanyUsedVehicleAndPowerSolutions'],
  },
  {
    name: 'Deleted',
    componentName: DeletedComponent,
    formName: [],
  },
  {
    name: 'WorkshopCapabilities',
    componentName: WorkshopCapabilitiesComponent,
    formName: ['CompanyWorkshopCapabilities'],
  },
];

const adminComponentMapping = [
  {
    name: 'listaddressTypeHome',
    componentName: ListAdressTypeComponent,
  },
  {
    name: 'addEditAddressType',
    componentName: AddEditAddressTypesComponent,
  },
];

const ADD_MSG = 'Record added successfully';
const UPDATE_MSG = 'Record modified successfully';
const COMPANY_ADD_MSG = 'Company record added successfully';
const COMPANY_UPDATE_MSG = 'Company record modified successfully';
const DELETE_MSG = 'Record deleted successfully';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  getValidationRuleConfig() {
    return validationRuleConfig;
  }

  getInfoBlockMapping() {
    return InfoBlockMapping;
  }

  getInfoBlockMappingComponentByName(name: string) {
    return InfoBlockMapping.find((item) => item.name === name) || null;
  }

  getInternalUrl() {
    return environment.internalUrl;
  }

  getAdminMappingComponentByName(name: string) {
    return adminComponentMapping.find((item) => item.name === name) || null;
  }

  get ADD_MSG() {
    return ADD_MSG;
  }

  get UPDATE_MSG() {
    return UPDATE_MSG;
  }

  get DELETE_MSG() {
    return DELETE_MSG;
  }

  get COMPANY_ADD_MSG() {
    return COMPANY_ADD_MSG;
  }

  get COMPANY_UPDATE_MSG() {
    return COMPANY_UPDATE_MSG;
  }
}

import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { map, Observable, of, take } from 'rxjs';
import { AppService } from '../app.service';
import { Infoblock } from '../models/infoblock.model';
import { categoryList, menuItem } from '../models/shared.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private appService: AppService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    
    if(state.url.includes("sesuserid"))
      sessionStorage.setItem("redirectUrl", state.url)

    if (this.authService.isAuthenticated) {
      if (this.authService.User) {
        let urlSegments =
          state.url.length > 1
            ? state.url.substring(1, state.url.length).split('/')
            : [];
        return this.checkAdminAccess(urlSegments);
        //return true;
      } else {
        //user details not loaded
        let urlSegments =
          state.url.length > 1
            ? state.url.substring(1, state.url.length).split('/')
            : [];
        return this.authService.isUserDetailsLoadedObservable().pipe(
          map((response: any) => {
            return response ? this.checkAdminAccess(urlSegments) : false;
            //return response;
          })
        );
      }
    } else {
      this.router.navigate(['/auth-redirect']);
      setTimeout(() => {
        this.authService.login();
      }, 1000);
      return false;
    }
  }

  checkAccess(urlSegments: string[]) {
    if (this.authService.User?.IsAdmin()) return true;

    let submenuItem: string = '';
    let parentMenuItem: string = '';
    if (urlSegments && urlSegments.length) {
      parentMenuItem = urlSegments[0];
      submenuItem = urlSegments.length > 1 ? urlSegments[1] : '';
      if (
        parentMenuItem === menuItem.companyMenuItem &&
        decodeURI(submenuItem) &&
        this.appService.fetchCatgories()
      ) {
        let categoryList: categoryList[] = this.appService.fetchCatgories();
        let bool: boolean = false;
        categoryList.forEach((category: any) => {
          if (category.Name == decodeURI(submenuItem)) {
            bool = this.authService.User?.HasAccessToCategory(category.Id)
              ? this.authService.User?.HasAccessToCategory(category.Id)
              : false;
          }
        });
        if (bool) return bool;
        else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else if (
        parentMenuItem === decodeURI(menuItem.globalManagementListMenuItem) &&
        this.appService.fetchCatgories() &&
        this.appService.fetchInfoBlockdata()
      ) {
        let bool = false;
        let categoryList: categoryList[] = this.appService.fetchCatgories();
        let infoBlockData: Infoblock[] = this.appService.fetchInfoBlockdata();
        if (categoryList && categoryList.length) {
          bool = categoryList.some((item: any) => {
            this.authService.User?.hasInfoBlockName(
              'GlobalManagementList',
              infoBlockData,
              [...item.Leftinfoblock_Ids, ...item.Rightinfoblock_Ids]
            );
          });
        } else bool = false;
        if (bool) return bool;
        else {
          this.router.navigate(['/noaccess']);
          return false;
        }
      } else {
        this.router.navigate(['/unauthorized']);
        return false;
      }
    } else return true;
  }

  checkAdminAccess(urlSegments: string[]) {
    if (this.authService.User?.IsAdmin()) return true;
   
    let submenuItem: string = '';
    let parentMenuItem: string = '';
    if (urlSegments && urlSegments.length) {
      parentMenuItem = urlSegments[0];
      submenuItem = urlSegments.length > 1 ? urlSegments[1] : '';

      if(parentMenuItem == menuItem.accessControlMenuItem && submenuItem == "users" && this.authService.User?.IsSISCordinator())
        return true;

      let labelText: string = 'labelTexts';
      let taggroupText: string = 'tagGroups';
      if (
        (parentMenuItem === menuItem.administrationMenuItem ||
          parentMenuItem === menuItem.accessControlMenuItem) &&
        !labelText.includes(submenuItem) &&
        !taggroupText.includes(submenuItem)
      ) {
        this.router.navigate(['/unauthorized']);
        return false;
      }
      return true;
    }

    return true;
  }
}

import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-logout-msg',
    templateUrl: './logout-msg.component.html'
})
export class LogoutMsgComponent {

    constructor() {

        //logout from azure AD
        window.location.href = `${environment.authConfig.azureAdLogoutUri}?post_logout_redirect_uri=${environment.authConfig.oauth.redirectSignOut}`;
    }
}
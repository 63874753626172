import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoblockService {
  constructor(private http: HttpClient) {}
  getInfoblocks() {
    return this.http.get(environment.baseUrl + '/v1/infoblock').pipe(retry(3));
  }
}

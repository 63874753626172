<form [formGroup]="NotesForm" (change)="saveDraft()">
    <div class="flex flex-row">
        <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="p-2">
                <p class="tds-headline-06 mt-5 mb-0 pb-0">Expiry Date 01</p>
                <div class="bg-gray-50 p-1">
                    <tds-datetime ngDefaultControl id="datetime" mode-variant="primary" placeholder="Select Date"
                        type="date" size="sm" state="none" value="{{
                            NotesForm.value.expiryDate1
              | date : 'YYYY-MM-dd'
          }}" (tdsChange)="handleExpiryDate1DatePicker($event)"></tds-datetime>
                </div>


                <p class="tds-headline-06 mt-5 mb-0 pb-0">Short Term Notes</p>
                <div class="bg-gray-50 p-1">
                    <tds-textarea row="4" ngDefaultControl type="text" max-length="4000" size="sm"
                        formControlName="shortTermNote" placeholder="Enter Short Term Notes">
                    </tds-textarea>
                </div>
            </div>
            <div class="p-3">
                <p class="tds-headline-06 mt-5 mb-0 pb-0">Expiry Date 02</p>
                <div class="bg-gray-50 p-1">
                    <tds-datetime ngDefaultControl id="datetime" mode-variant="primary" placeholder="Select Date"
                        type="date" pal size="sm" state="none" value="{{
                      NotesForm.value.expiryDate2
                        | date : 'YYYY-MM-dd'
                    }}" (tdsChange)="handleExpiryDate2DatePicker($event)"></tds-datetime>
                </div>

                <p class="tds-headline-06 mt-5 mb-0 pb-0">Long Term Notes</p>
                <div class="bg-gray-50 p-1">
                    <tds-textarea row="4" ngDefaultControl type="text" max-length="4000" size="sm"
                        formControlName="longTermNote" placeholder="Enter Long Term Notes">
                    </tds-textarea>
                </div>


            </div>
        </div>
    </div>
    <div class="p-2 mb-3">
        <tds-button [disabled]="mode === 'Add'" (click)="ViewHistory()" text="View History" size="sm"
            variant="secondary" class="mt-5">
        </tds-button>
    </div>
</form>


<tds-modal header="Assistance Notes history" selector="#scania-history-modal-button" id="my-modal" size="lg"
    actions-position="static" prevent="false" closable="true" #historyModal>
    <span class="viewHistory-spanbody" slot="body">
        <tds-table class="viewHistory-table" vertical-dividers="true" compact-design="false" responsive="false"
            no-min-width>
            <tds-table-header>
                <tds-header-cell cell-key="date" cell-value="Date" custom-width="400px"></tds-header-cell>
                <tds-header-cell cell-key="byuser" cell-value="By user" custom-width="150px"></tds-header-cell>
                <tds-header-cell cell-key="expiryDate1" cell-value="Expiry Date1"
                    custom-width="150px"></tds-header-cell>
                <tds-header-cell cell-key="expiryDate2" cell-value="Expiry Date2"
                    custom-width="150px"></tds-header-cell>
                <tds-header-cell cell-key="shortTermNote" cell-value="Short Term Note"
                    custom-width="400px"></tds-header-cell>
                <tds-header-cell cell-key="longTermNote" cell-value="Long Term Note"
                    custom-width="400px"></tds-header-cell>
            </tds-table-header>
            <tds-table-body>
                @if(history && history.length){
                <tds-table-body-row *ngFor="let row of history">
                    <tds-body-cell cell-key="datum">{{
                        row?.Datum | date : "YYYY-MM-dd hh:mm:ss"
                        }}</tds-body-cell>
                    <tds-body-cell cell-key="byuser">{{ row?.ByUser }}</tds-body-cell>
                    <tds-body-cell cell-key="expiryDate1">{{
                        row?.Data.ExpiryDate1 | date : "YYYY-MM-dd"
                        }}</tds-body-cell>
                    <tds-body-cell cell-key="expiryDate2">{{
                        row?.Data.ExpiryDate2 | date : "YYYY-MM-dd"
                        }}</tds-body-cell>
                    <tds-body-cell cell-key="shortTermNote">{{
                        row?.Data.ShortTermNote
                        }}</tds-body-cell>
                    <tds-body-cell cell-key="longTermNote">{{
                        row?.Data.LongTermNote
                        }}</tds-body-cell>
                </tds-table-body-row>
                } @else {
                <tds-table-body-row>
                    <span class="text-center p-4">
                        No Data Found</span></tds-table-body-row>
                }
            </tds-table-body>
        </tds-table>
    </span>
    <span slot="actions" class="tds-u-flex tds-u-gap2">
        <tds-button data-dismiss-modal size="sm" text="Close" variant="primary"></tds-button>
        <!-- <tds-button data-dismiss-modal size="md" text="Button Text" variant="secondary"></tds-button> -->
    </span>
</tds-modal>
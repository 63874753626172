import { Infoblock } from './infoblock.model';
import { environment } from '../../environments/environment';

export class User {
  Id!: string;
  Loginname!: string;
  Name!: string;
  AccessType!: string;
  AccessData!: string;
  AccessTypeId!: string;
  UserAccessType!: {
    Id: string;
    Accesstype: string;
    CatSpecification: string;
    Isadministrator: boolean;
    IsCordinator: boolean;
    Catspec: {
      CategoryAccesses: Array<{
        CategoryId: string;
        InfoblockAccesses: Array<{
          InfoblockId: string;
          Hidden?: boolean;
        }>;
        Access?: AccessTypeEnum;
        TreeAccess?: TreeTypeEnum;
      }>;
    };
  };
  TreeAccesses!: Array<{
    Data: string;
    CountryList: string;
    CategoryId: string;
  }>;

  IsAdmin() {
    return this.UserAccessType != null && this.UserAccessType.Isadministrator;
  }

  IsCordinator() {
    if (this.UserAccessType.Accesstype == 'Coordinator1distrib')
      return (this.UserAccessType.IsCordinator = true);
    else return this.UserAccessType != null && this.UserAccessType.IsCordinator;
  }

  IsSISCordinator() {
    return this.AccessTypeId === environment.accessTypes.SISCooridinator;
  }
  
  IsSESAssistance() {
    return this.AccessTypeId === environment.accessTypes.SESAssistance;
  }

  IsScaniaAssistanceCoordinator() {
    return this.AccessTypeId === environment.accessTypes.ScaniaAssistanceCoordinator;
  }

  GetAccessForCategory(categoryId: string) {
    if (this.IsAdmin()) return AccessTypeEnum.AdminUpdate;
    if (this.IsCordinator()) return AccessTypeEnum.coordinator;
    var catacc = this.UserAccessType?.Catspec?.CategoryAccesses.find(
      (p) => p.CategoryId == categoryId
    );
    if (!catacc) return AccessTypeEnum.None;
    return catacc.Access;
  }

  
  filterAccountTypesForSISCoordinator(userAccessTypeList: any[]) : any[] {
    //show only "Scania Assistance Coordinator" for SIS Coordinators
    userAccessTypeList = userAccessTypeList?.sort(
      (a: any, b: any) => a?.Accesstype.localeCompare(b?.Accesstype)
    );
    
    if (this.IsSISCordinator()) {
      return userAccessTypeList.filter((x:any) => x.Id == environment.accessTypes.ScaniaAssistanceCoordinator)
    }
    else return userAccessTypeList;
  }

  public HasAccessToCategory(categoryId: string) {
    if (this.IsAdmin()) return true;

    var catacc = this.UserAccessType?.Catspec?.CategoryAccesses.find(
      (p) => p.CategoryId == categoryId
    );
    if (!catacc) return false;

    if (catacc.Access == AccessTypeEnum.None) return false;

    if (catacc.TreeAccess == TreeTypeEnum.All) return true;

    return (
      this.TreeAccesses != null &&
      this.TreeAccesses.some((v) => v.CategoryId == categoryId)
    );
  }

  HasReadOnlyAccessToCategory(categoryId: string) {
    if (this.IsAdmin()) return true;

    var catacc = this.UserAccessType?.Catspec?.CategoryAccesses.find(
      (p) => p.CategoryId == categoryId
    );
    if (!catacc) return false;

    if (catacc.Access == AccessTypeEnum.Read) return true;
    return false;
  }

  ShowInCountryList(countryCode: string, categoryId: string) {
    if (this.IsAdmin()) return true;
    var catacc = this.UserAccessType?.Catspec?.CategoryAccesses.find(
      (p) => p.CategoryId == categoryId
    );
    if (catacc == null) return false;

    if (catacc.Access == AccessTypeEnum.None) return false;

    if (catacc.TreeAccess == TreeTypeEnum.All) return true;

    var ac: any = this.TreeAccesses.find((v) => v.CategoryId == categoryId);

    var parts: [] = ac.CountryList.toLowerCase().split(',');
    return (
      parts.some((s) => s == countryCode.toLowerCase()) ||
      parts.some((s) => s == 'all')
    );
  }

  HasAccessToInfoblockForUserGrp(infoBlock: any, currentCountry: string) {
    if (
      !infoBlock.OnlyVisibleToGroups &&
      !infoBlock.OnlyVisibleToUsers &&
      !infoBlock.OnlyVisibleToCountry
    )
      return true;
    if (
      infoBlock.OnlyVisibleToGroups &&
      infoBlock.OnlyVisibleToGroups.Split(',').some(
        (p: any) => p.trim().toLowerCase() == this.AccessType.toLowerCase()
      )
    )
      return true;
    if (
      infoBlock.OnlyVisibleToUsers &&
      infoBlock.OnlyVisibleToUsers.Split(',').some(
        (p: any) => p.trim().toLowerCase() == this.Loginname.toLowerCase()
      )
    )
      return true;
    if (
      infoBlock.OnlyVisibleToCountry &&
      infoBlock.OnlyVisibleToCountry.Split(',').some(
        (p: any) => p.Trim().ToUpper() == currentCountry
      )
    )
      return true;
    return false;
  }

  HasAccessToInfoblock(
    infoBlock: any,
    currentCountry: string,
    categoryId: string = ''
  ) {
    if (this.IsAdmin()) return true;
    var catacc = this.UserAccessType?.Catspec?.CategoryAccesses.find(
      (p) => p.CategoryId == categoryId
    );
    if (catacc == null) return false;
    var infoacc = catacc?.InfoblockAccesses.find(
      (p) => p.InfoblockId == infoBlock.Id
    );
    if (infoacc == null || infoacc.Hidden) return false;
    // infoacc.

    return this.HasAccessToInfoblockForUserGrp(infoBlock, currentCountry);
  }

  hasInfoBlockName(
    name: string,
    allInfoBlocks: Infoblock[],
    allInfoBlocksIds: string[]
  ): boolean {
    if (name && allInfoBlocks && allInfoBlocks.length) {
      return allInfoBlocks.some(
        (item: Infoblock) =>
          item.Name === name && allInfoBlocksIds.includes(item.Id)
      );
    }

    return false;
  }

  public static readonly AccessTypeObj = {
    None: 'None',
    Read: 'Read',
    Update: 'Update',
    AdminUpdate: 'AdminUpdate',
    coordinator: 'coordinator',
  };

  public static readonly TreeTypeObj = {
    All: 'All',
    StartingAtNode: 'StartingAtNode',
    StartingAtCountry: 'StartingAtCountry',
  };
  public static readonly accessControlMenuItem = 'accessControl';
  public static readonly administrationMenuItem = 'administration';
  public static readonly globalManagementListMenuItem = 'globalManagementList';
}

export enum AccessTypeEnum {
  None,
  Read,
  Update,
  AdminUpdate,
  coordinator,
}

export enum TreeTypeEnum {
  All,
  StartingAtNode,
  StartingAtCountry,
}

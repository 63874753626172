<div class="flex">
  <div class="h-52 w-4/5 grid justify-items-center mt-3">
    <p
      class="font-bold text-3xl inline-block align-text-bottom mt-5"
      id="heading"
    >
      401 Unuthorized
    </p>
    <p class="tds-detail-04 tds-text-blue-500"></p>

    <tds-message variant="error" header="Access Denied">
      You are not authorized to access this url.
    </tds-message>
  </div>
</div>

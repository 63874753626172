import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagGroupService {

  constructor(private http: HttpClient) {}

  getTagGroupList() { // 3
    return this.http.get(environment.baseUrl + environment.administrationServices.tagGroup);
  }

  updateTagGroupList(request: any) {    // 6
    console.log(request);
    return this.http.put(
      environment.baseUrl + environment.administrationServices.tagGroup + '/' + request.Id, 
      request);
  }

  addTagGroup(request: any) {    // 4
    console.log(request);
    return this.http.post(
      environment.baseUrl + environment.administrationServices.tagGroup , 
      request);
  }

  getTagGroup(id: any) {    // 5
    return this.http.get(environment.baseUrl + environment.administrationServices.tagGroup + '/' + id);
  }

  getTags(id: any) { // 1
    return this.http.get(environment.baseUrl + environment.administrationServices.tagGroup + '/' + id + '/tags');
  }

  getUserList() {
    return this.http.get(environment.baseUrl + environment.administrationServices.userList);
  }
  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalManagementContactProfileService {
constructor(private http: HttpClient) {}

  getGlobalManagementContactProfiles() {
    return this.http
      .get(
        environment.baseUrl +
          environment.administrationServices.GlobalManagementContactProfile
      )
      .pipe(retry(3));
  }

  addGlobalManagementContactProfile(editForm: any) {
    return this.http.post<any>(
      environment.baseUrl +
        environment.administrationServices.GlobalManagementContactProfile,
      editForm
    );
  }

  getDisplayOrderUrl() {
    return (
      environment.baseUrl +
      environment.administrationServices.GlobalManagementContactProfile
    );
  }

}


<form [formGroup]="companyDealerWorkshopForm" (change)="saveDraft()">
  <div class="flex overflow-auto no-scrollbar flex-wrap">
    <div class="w-full md:w-1/2 lg:w-1/4 xl:w-1/4 2xl:w-1/4">
      <div class="m-2 inputField">
        <div class="flex justify-between">
          <p class="tds-headline-06 mt-2 mb-0 pb-0">
            {{ DealerIdLabel }}
            <span *ngIf="Required" class="text-red-600">*</span>
          </p>
          <tds-tooltip
            placement="right"
            text="{{ DealerIdPopupText }}"
            selector="#dealer-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-2 text-[#B0B7C4]"
            id="dealer-tooltip"
            size="15"
          ></tds-icon>
        </div>
        <div class="mt-2">
          <tds-text-field
            ngDefaultControl
            type="Text"
            size="sm"
            formControlName="DealerId"
            (tdsFocus)="dealerIdFocus = true"
            (tdsBlur)="dealerIdFocus = true"
            [state]="
              DealerId?.invalid &&
              (DealerId?.dirty || DealerId?.touched || dealerIdFocus)
                ? 'error'
                : 'success'
            "
            [helper]="
              utilService.getErrorMsgs(DealerId, 'Dealer ID', dealerIdFocus)
            "
          >
          </tds-text-field>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 lg:w-1/4 xl:w-1/4 2xl:w-1/4">
      <div class="m-2">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          Dealer Type<span *ngIf="Required" class="text-red-600">*</span>
        </p>
        <div class="mt-2">
          <fieldset class="demo-fieldset-reset">
            @for(types of workshopTypes;track $index){
            <tds-radio-button
              ngDefaultControl
              name="rb-example"
              value="{{ types.Code }}"
              radio-id="{{ types.Code }}"
              formControlName="DealerWorkshopType_code"
              checked="{{
                types.Code === dealertypeOptions.DealerWorkshopType_code
              }}"
              (tdsChange)="handleTypeChecked(types.Code)"
            >
              <div slot="label">{{ types.Title }}</div>
            </tds-radio-button>
            }
            <p
              *ngIf="DealerWorkshopType_code?.invalid"
              class="text-[#ff2340] text-xs p-0 m-0"
            >
              <tds-icon
                name="info"
                class="text-[#ff2340] pb-1 pr-2"
                id="Mandatory"
                size="16"
              ></tds-icon
              >Dealer Type is requried
            </p>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 lg:w-1/4 xl:w-1/4 2xl:w-1/4">
      <div class="m-2">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          Scania Ownership<span *ngIf="Required" class="text-red-600">*</span>
        </p>
        <div class="mt-2">
          <fieldset class="demo-fieldset-reset">
            @for(option of ownershipOptions;track $index){
            <tds-radio-button
              ngDefaultControl
              name="rb-example2"
              value="{{ option.Code }}"
              radio-id="{{ option.Code }}"
              formControlName="DealerWorkshopOwnership_code"
              checked="{{
                option.Code === ownership.DealerWorkshopOwnership_code
              }}"
              (tdsChange)="handleOwnershipChecked(option.Code)"
            >
              <div slot="label">{{ option.Title }}</div>
            </tds-radio-button>

            }
            <p
              *ngIf="DealerWorkshopOwnership_code?.invalid"
              class="text-[#ff2340] text-xs p-0 m-0"
            >
              <tds-icon
                name="info"
                class="text-[#ff2340] pb-1 pr-2"
                id="Mandatory"
                size="16"
              ></tds-icon
              >Scania Ownership is required
            </p>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 lg:w-1/4 xl:w-1/4 2xl:w-1/4">
      <div class="m-2 inputField">
        <p class="tds-headline-06 mt-2 mb-0 pb-0">Dealer/Distributor</p>
        <div class="mt-2">
          <tds-button
            type="button"
            variant="primary"
            size="sm"
            text="Select Parent ID"
            (click)="showDealersAndDistributors()"
          ></tds-button>
          @if(parentIdFocus){
          <div
            class="text-field-helper sc-tds-text-field"
            style="color: #ff2340"
          >
            <div class="text-field-helper-error-state sc-tds-text-field">
              <tds-icon
                class="sc-tds-text-field hydrated"
                name="error"
                size="16px"
              ></tds-icon
              >Select a parent.
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="flex mt-2 ml-3" *ngIf="dealerItems && dealerItems.Title">
    <div class="w-full">
      <ul class="tree">
        <ng-container
          *ngTemplateOutlet="recursiveList; context: { $implicit: dealerItems }"
        ></ng-container>
      </ul>
    </div>
  </div>
  <ng-template #recursiveList let-node>
    <li>{{ node.Title }}</li>
    <ul *ngIf="node.children && node.children.length">
      <ng-container *ngFor="let child of node.children">
        <ng-container
          *ngTemplateOutlet="recursiveList; context: { $implicit: child }"
        ></ng-container>
      </ng-container>
    </ul>
  </ng-template>
  <p
    class="tds-headline-05 tds-text-blue-500 w-full ml-2"
    style="margin-bottom: 0"
  >
    Termination of Agreement
  </p>
  <div class="flex flex-wrap">
    <div class="w-full md:w-1/2 lg:w-2/5 xl:w-2/5 2xl:w-2/5">
      <div class="inputField mbottom-zero">
        <p class="tds-headline-06 mt-3 mb-0 pb-0">Date of notice</p>
        <tds-datetime
          ngDefaultControl
          id="datetime_notice"
          type="date"
          size="sm"
          state="none"
          mode-variant="primary"
          value="{{
            companyDealerWorkshopForm.value.notice | date : 'YYYY-MM-dd'
          }}"
          (tdsChange)="setNoticeValue($event)"
        ></tds-datetime>
      </div>
    </div>
    <div class="w-full md:w-1/2 lg:w-2/5 xl:w-2/5 2xl:w-2/5">
      <div class="inputField mbottom-zero">
        <p class="tds-headline-06 mt-3 mb-0 pb-0">Date of Termination</p>
        <tds-datetime
          ngDefaultControl
          id="datetime_termination"
          type="date"
          size="sm"
          state="none"
          mode-variant="primary"
          (tdsChange)="setTerminationValue($event)"
          value="{{
            companyDealerWorkshopForm.value.Termination | date : 'YYYY-MM-dd'
          }}"
        ></tds-datetime>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
      <div class="inputField" style="height: 120px">
        <p class="tds-headline-06 mt-0 mb-0 pb-0">Comments</p>

        <div>
          <tds-textarea
            ngDefaultControl
            type="Text"
            size="sm"
            max-length="200"
            placeholder="Comment if any"
            formControlName="comment"
          >
          </tds-textarea>
        </div>
      </div>
    </div>
  </div>
</form>

<tds-modal
  header="Parent for Dealer ID"
  id="my-modal"
  size="lg"
  actions-position="static"
  prevent="false"
  closable="true"
  #dealerModal
>
  <span slot="body">
    <div class="container" *ngIf="tableData">
      <div class="table-scroll" style="margin: 20px">
        <tds-table
          vertical-dividers="false"
          compact-design="true"
          responsive
          no-min-width="true"
          *ngIf="tableData"
        >
          <tds-table-header>
            <tds-header-cell cell-key="scaniaId" cell-value="Scania Id">
              <div class="tds-u-flex mt-2">
                <tds-text-field
                  mode-variant="secondary"
                  name="text-field"
                  label=""
                  size="sm"
                  (tdsInput)="filterColumns($event, 'Id')"
                ></tds-text-field></div
            ></tds-header-cell>
            <tds-header-cell cell-key="type" cell-value="Type">
              <select
                name=""
                id=""
                style="
                  height: 39px;
                  border: none;
                  border-radius: 4px 4px 0 0;
                  display: block;
                  min-width: 80px;
                  display: block;
                "
                class="mt-2"
                value=""
                (change)="filterColumns($event, 'Type')"
              >
                <option value="">All</option>

                <option
                  style="
                    color: var(--tds-dropdown-option-color);
                    border-bottom: 1px solid var(--tds-dropdown-option-border);
                    font: var(--tds-detail-02);
                    letter-spacing: var(--tds-detail-02-ls);
                    overflow-wrap: anywhere;
                    padding: 11px 0px 12px;
                  "
                >
                  DealerWorkshops
                </option>

                <option
                  style="
                    color: var(--tds-dropdown-option-color);
                    border-bottom: 1px solid var(--tds-dropdown-option-border);
                    font: var(--tds-detail-02);
                    letter-spacing: var(--tds-detail-02-ls);
                    overflow-wrap: anywhere;
                    padding: 11px 0px 12px;
                  "
                >
                  Distributor
                </option>
              </select>
            </tds-header-cell>
            <tds-header-cell cell-key="country_code" cell-value="Country">
              <select
                name=""
                id=""
                style="
                  height: 39px;
                  border: none;
                  border-radius: 4px 4px 0 0;
                  display: block;
                  min-width: 80px;
                  display: block;
                "
                class="mt-2"
                value=""
                (change)="filterColumns($event, 'Country')"
              >
                <option value="">All</option>
                @for(country of uniqueCountryList; track $index){

                <option
                  style="
                    color: var(--tds-dropdown-option-color);
                    border-bottom: 1px solid var(--tds-dropdown-option-border);
                    font: var(--tds-detail-02);
                    letter-spacing: var(--tds-detail-02-ls);
                    overflow-wrap: anywhere;
                    padding: 11px 0px 12px;
                  "
                >
                  {{ country.Country }}
                </option>
                }
              </select>
            </tds-header-cell>

            <tds-header-cell cell-key="dealer_id" cell-value="Dealer ID">
              <div class="tds-u-flex mt-2">
                <tds-text-field
                  mode-variant="secondary"
                  name="text-field"
                  label=""
                  size="sm"
                  value=""
                  (tdsInput)="filterColumns($event.detail, 'DealerId')"
                ></tds-text-field>
              </div>
            </tds-header-cell>
            <tds-header-cell cell-key="name" cell-value="Dealer Name">
              <div class="tds-u-flex mt-2">
                <tds-text-field
                  mode-variant="secondary"
                  name="text-field"
                  label=""
                  size="sm"
                  (tdsInput)="filterColumns($event.detail, 'Name')"
                ></tds-text-field>
              </div>
            </tds-header-cell>
            <tds-header-cell cell-key="city" cell-value="City">
              <select
                name=""
                id=""
                style="
                  height: 39px;
                  border: none;
                  border-radius: 4px 4px 0 0;
                  display: block;
                "
                value=""
                (change)="filterColumns($event, 'City')"
              >
                <option value="">All</option>
                @for(city of dealerIdlist; track $index){

                <option
                  style="
                    color: var(--tds-dropdown-option-color);
                    border-bottom: 1px solid var(--tds-dropdown-option-border);
                    font: var(--tds-detail-02);
                    letter-spacing: var(--tds-detail-02-ls);
                    overflow-wrap: anywhere;
                    padding: 11px 0px 12px;
                  "
                >
                  {{ city.City }}
                </option>
                }
              </select>
            </tds-header-cell>
          </tds-table-header>
          <tds-table-body>
            <tds-table-body-row
              *ngFor="let row of tableData"
              (dblclick)="edit(row.Id, row?.Name, row?.DealerId, row?.Country)"
              (touchend)="edit(row.Id, row?.Name, row?.DealerId, row?.Country)"
            >
              <tds-body-cell
                cell-key="scaniaId"
                *ngIf="row?.Id"
                class="table-body-padding"
              >
                {{ row?.Id }}
              </tds-body-cell>
              <tds-body-cell cell-key="type" class="table-body-padding">
                {{ row?.Type }}
              </tds-body-cell>
              <tds-body-cell cell-key="country_code" class="table-body-padding">
                {{ row?.Country }}
              </tds-body-cell>

              <tds-body-cell cell-key="dealer_id" class="table-body-padding">
                {{ row?.DealerId }}
              </tds-body-cell>
              <tds-body-cell cell-key="name" class="table-body-padding">
                {{ row?.Name }}
              </tds-body-cell>
              <tds-body-cell cell-key="city" class="table-body-padding">
                {{ row?.City }}
              </tds-body-cell>
            </tds-table-body-row>
          </tds-table-body>
        </tds-table>
      </div>
      <div class="flex flex-row my-3 justify-between ml-3">
        <div class="flex flex-row gap-1 md:gap-2">
          <p class="palce-self-center tds-detail-02 pr-2.5">Rows per page</p>
          <!-- <select
            class="inline-flex items-center px-1 py-1 border border-none rounded-sm shadow-sm text-sm font-medium bg-[#EDEFF3]"
            style="width: 67px"
            [(value)]="rowsPerPage"
          >
            @for (item of pageOptions; track $index) {
            <option>{{ item }}</option>
            }
          </select> -->
          <tds-dropdown
            name="dropdown"
            size="md"
            open-direction="auto"
            default-value="10"
            class="pl-3 pr-3"
            (tdsChange)="updateRowCount($event)"
          >
            @for (item of pageOptions; track $index) {
            <tds-dropdown-option value="{{ item }}">{{
              item
            }}</tds-dropdown-option>
            }
          </tds-dropdown>
        </div>
        <div class="flex flex-row gap-2 mr-3">
          <p class="place-self-center tds-detail-02 pr-2.5">
            {{ page == 0 ? 1 : tableData.length * page + 1 }}-{{
              tableData.length < rowsPerPage
                ? tableData.length * (page + 1)
                : rowsPerPage * (page + 1)
            }}
            of
            {{ filterData().length }}
          </p>
          <tds-button
            type="button"
            variant="secondary"
            size="sm"
            mode-variant="secondary"
            (click)="handlePrevPage(page - 1)"
            [disabled]="page + 1 == 1"
          >
            <tds-icon slot="icon" size="16px" name="chevron_left"></tds-icon>
          </tds-button>
          <p class="place-self-center tds-detail-02">
            {{ page + 1 }} / {{ pages }}
          </p>
          <tds-button
            type="button"
            variant="secondary"
            size="sm"
            mode-variant="secondary"
            (click)="handlePrevPage(page + 1)"
            [disabled]="pages == 1"
          >
            <tds-icon slot="icon" size="16px" name="chevron_right"></tds-icon>
          </tds-button>
        </div>
      </div>
    </div>
  </span>
</tds-modal>

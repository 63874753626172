<tds-header>
  <tds-header-hamburger
    id="demo-hamburger"
    aria-label="Open application drawer"
    aria-haspopup="true"
    aria-expanded="false"
    (click)="toggleMobileSideMenu()"
  ></tds-header-hamburger>

  <tds-header-title>{{ title | uppercase }} </tds-header-title>

  <tds-header-dropdown slot="end" no-dropdown-icon class="user-dropdown">
    <div slot="icon" [hidden]="!isAuthenticated">
      <!-- <img
        src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
        alt="User menu."
      /> -->
      <tds-icon name="profile" size="28px"></tds-icon>
    </div>
    <tds-header-dropdown-list size="lg" *ngIf="isAuthenticated">
      <tds-header-dropdown-list-user
        header="{{ userName }}"
        subheader="{{ email }}"
      ></tds-header-dropdown-list-user>
      <tds-header-dropdown-list-item>
        <a (click)="openUserManual()" routerLink="#">
          <tds-icon name="support"></tds-icon>
          <div class="tds-u-pl1">Help</div>
        </a>
      </tds-header-dropdown-list-item>
      <tds-header-dropdown-list-item>
        <a routerLink="#" (click)="logout()">
          <div class="tds-u-pl1">Logout</div>
        </a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-brand-symbol slot="end">
    <a routerLink="/" aria-label="Scania - red gryphon on blue shield"></a>
  </tds-header-brand-symbol>
</tds-header>

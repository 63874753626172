<div class="flex flex-row justify-between">
  <div class="p-5 pb-0">
    <p class="text-md md:text-xl font-bold m-0">Address Type</p>
  </div>
  <div class="flex pr-4">
    <button
      class="border border-[#56657A] rounded-sm h-10 mt-5 mr-4 bg-white hover:bg-[#0F3263] hover:text-white cursor-pointer"
      style="width: 190px"
      (click)="add()"
    >
      <div class="px-2 py-0">
        <tds-icon name="plus" size="16px" class="pb-1 pr-1"></tds-icon
        ><span class="tds-detail-02 cursor-pointer">New Address Type</span>
      </div>
    </button>
    <button
      class="border border-[#56657A] rounded-sm h-10 mt-5 bg-white cursor-pointer hover:bg-[#0F3263] hover:text-white"
      id="openDisplayOrder"
      (click)="this.showDisplayOrderModal = true"
    >
      <div class="px-1 py-0">
        <tds-icon name="sorting" size="16px" class="pb-1 pr-1"></tds-icon
        ><span class="tds-detail-02">Set Display Order</span>
      </div>
    </button>
  </div>
</div>

<div class="p-4">
  <tds-table
    vertical-dividers="false"
    compact-design="true"
    responsive
    #table
    style="min-height: 200px"
  >
    <tds-table-header>
      <tds-header-cell
        cell-key="Name"
        cell-value="Name"
        [sortable]="isSort"
        (tdsSort)="sortColumns($event, 'Name')"
        style="overflow: visible"
      >
      </tds-header-cell>
      <tds-header-cell
        cell-key="Code"
        cell-value="Code"
        [sortable]="isSort"
        (tdsSort)="sortColumns($event, 'Code')"
        style="overflow: visible"
      >
      </tds-header-cell>
      <tds-header-cell
        cell-key="displayOrder"
        cell-value="Display Order"
        [sortable]="isSort"
        (tdsSort)="sortColumns($event, 'Displayorder')"
        style="overflow: visible"
      >
      </tds-header-cell>
    </tds-table-header>
    <tds-table-body>
      <tds-table-body-row class="filter-row">
        <tds-body-cell class="filter-cell">
          <div class="tds-u-flex mt-1">
            <tds-text-field
              ngDefaultControl
              class="filter-text-field"
              mode-variant="secondary"
              name="text-field"
              label=""
              size="sm"
              value=""
              (tdsInput)="filterList($event.detail, 'Name')"
              value="{{ selectedValues['Name'] || '' }}"
            >
            </tds-text-field>
          </div>
        </tds-body-cell>
        <tds-body-cell class="filter-cell">
          <div class="tds-u-flex mt-1">
            <tds-dropdown
              ngDefaultControl
              name="Code"
              placeholder="Select Code"
              size="sm"
              open-direction="down"
              (tdsChange)="filterList($event.detail, 'Code')"
              filter="true"
              [defaultValue]="selectedValues['Code'] || ''"
            >
              @for(address of sortAddressTypeList;track $index){
              <tds-dropdown-option
                [value]="address.Code"
                style="overflow-wrap: break-word"
              >
                {{ address.Code }}
              </tds-dropdown-option>
              }
            </tds-dropdown>
          </div>
        </tds-body-cell>
        <tds-body-cell class="filter-cell">
          <div class="tds-u-flex mt-1">
            <tds-dropdown
              ngDefaultControl
              name="Displayorder"
              placeholder="Select Display Order"
              size="sm"
              open-direction="down"
              (tdsChange)="filterList($event.detail, 'Displayorder')"
              filter="true"
              [defaultValue]="selectedValues['Displayorder'] || ''"
            >
              @for(address of addressTypes;track $index){
              <tds-dropdown-option
                [value]="address.Displayorder"
                style="overflow-wrap: break-word"
              >
                {{ address.Displayorder }}
              </tds-dropdown-option>
              }
            </tds-dropdown>
          </div>
        </tds-body-cell>
      </tds-table-body-row>
      @if(tableData.length > 0){ @for(address of tableData; track $index){
      <tds-table-body-row (dblclick)="edit(address)" (touchend)="edit(address)">
        <tds-body-cell
          cellKey="Name"
          class="table-body-padding"
          text-align="left"
        >
          {{ address.Name }}
        </tds-body-cell>
        <tds-body-cell
          cellKey="Code"
          class="table-body-padding"
          text-align="left"
        >
          {{ address.Code }}
        </tds-body-cell>
        <tds-body-cell
          cellKey="Displayorder"
          class="table-body-padding"
          text-align="left"
        >
          {{ address.Displayorder }}
        </tds-body-cell>
      </tds-table-body-row>
      }} @else {
      <tds-table-body-row
        ><tds-body-cell
          ><strong>No Data Found</strong>
        </tds-body-cell></tds-table-body-row
      >
      }
    </tds-table-body>
  </tds-table>
  <div
    class="flex flex-row my-3 justify-between"
    style="height: 40px; margin-top: 30px"
  >
    <div class="flex flex-row gap-1 md:gap-2">
      <p class="palce-self-center tds-detail-02 pr-2.5">Rows per page</p>

      <tds-dropdown
        name="dropdown"
        size="md"
        open-direction="auto"
        default-value="10"
        class="pl-3 pr-3"
        (tdsChange)="updateRowCount($event)"
      >
        @for (item of pageSizes; track $index) {
        <tds-dropdown-option value="{{ item }}">{{ item }}</tds-dropdown-option>
        }
      </tds-dropdown>
    </div>
    <div class="flex flex-row gap-2" style="height: 40px">
      <p class="place-self-center tds-detail-02 pr-2.5">
        {{ startIndex + 1 }}-{{
          endIndex > totalItems ? totalItems : endIndex
        }}
        of {{ totalItems }}
      </p>
      <tds-button
        type="button"
        variant="secondary"
        size="sm"
        mode-variant="secondary"
        (click)="onPageChange(pageIndex - 1)"
        disabled="{{ pageIndex === 0 }}"
        [class.disabled]="pageIndex === 0"
      >
        <tds-icon slot="icon" size="16px" name="chevron_left"></tds-icon>
      </tds-button>
      <p class="place-self-center tds-detail-02">
        {{ pageIndex + 1 }}/{{ totalPages }}
      </p>
      <tds-button
        type="button"
        variant="secondary"
        size="sm"
        mode-variant="secondary"
        (click)="onPageChange(pageIndex + 1)"
        disabled="{{ pageIndex + 1 >= totalPages }}"
        [class.disabled]="pageIndex + 1 >= totalPages"
      >
        <tds-icon slot="icon" size="16px" name="chevron_right"></tds-icon>
      </tds-button>
    </div>
  </div>
</div>

<ng-container *ngIf="showDisplayOrderModal">
  <app-display-order
    [items]="addressTypes"
    [displayOrderUrl]="fetchOrderUrl"
    (closeModal)="this.showDisplayOrderModal = false; this.handlePagination()"
  ></app-display-order>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) {}

  getTimeZone(lat:any,lng:any) {
    const timestamp = Math.floor(Date.now()/1000)
    return this.http
      .get(
        environment.googleMap.timeZone + `?location=${lat},${lng}&timestamp=${timestamp}&key=${environment.googleMap.apiKey}`
      )
      .pipe(retry(3));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import dealerworkshoptypescode from '../../Mockjson/dealerWorshop-typesCode.json';
import { of, retry } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DealerworkshopTypesService {
  dealerworkshopTypes: any;
  constructor(private http: HttpClient) {}

  getdealerworkshopTypeList() {
    return this.http
      .get(environment.baseUrl + '/v1/dealerworkshoptype')
      .pipe(retry(3));
    // return of(dealerworkshoptypescode);
  }

  getEditDealer(dealer: any) {
    this.dealerworkshopTypes = dealer;
  }

  updateDealerData(request: any) {
    return this.http.put(
      environment.baseUrl + '/v1/dealerworkshoptype/' + request.Code,
      request
    );
  }

  addDealerData(editForm: any) {
    return this.http.post(
      environment.baseUrl + '/v1/dealerworkshoptype',
      editForm.value
    );
  }

  getDisplayOrderUrl() {
    return (
      environment.baseUrl + '/v1/dealerworkshoptype'
    );
  }

}

import {
  Component,
  inject,
  Input,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-scania-assistance',
  templateUrl: './scania-assistance.component.html',
  styleUrl: './scania-assistance.component.css',
})
export class ScaniaAssistanceComponent implements OnInit {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() companyId: any = {};
  @Input() infoBlockId: string = '';

  countryHasScaniaAssistance: boolean;

  constructor(
    private subjectService: SubjectService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.countryHasScaniaAssistance = false;
    this.subjectService.companyCountrySubject.subscribe((country: string) => {
      this.countryHasScaniaAssistance = this.hasScaniaAssistance(country);
    });
  }

  hasScaniaAssistance(country: string) {
    if (this.mode == 'Add') {
      const countriesWithScaniaAssistance =
        this.appService.fetchCountriesWithScaniaAssistance();
      if (
        country != '' &&
        countriesWithScaniaAssistance &&
        countriesWithScaniaAssistance.includes(country)
      )
        return true;

      return false;
    }
    return true;
  }
}

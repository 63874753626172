<form [formGroup]="companyDOSCertificationForm" (change)="saveDraft()">
  <div class="flex flex-wrap space-x-5 p-5 mb-5">
    <div>
      <p class="tds-headline-06 mt-5 mb-0 pb-0">DOS Certification</p>
      <div class="flex w-52 justify-between bg-gray-50">
        @if(this.companyDOSCertificationForm.value.IsCertified){
        <p class="font-bold text-green-600 pl-3">Certified</p>
        <tds-icon
          slot="icon"
          size="20px"
          name="tick"
          class="text-green-600 p-3"
        ></tds-icon>
        }@else {
        <p class="font-bold text-[#FF2340] pl-3">Not Certified</p>
        <tds-icon
          slot="icon"
          size="20px"
          name="cross"
          class="text-[#FF2340] p-3"
        ></tds-icon>
        }
      </div>
      <div class="w-52">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("ExpiringDate", infoBlockId) }}
        </p>
        <div class="bg-gray-50 p-1">
          <p
            class="tds-detail-02 mt-2 mb-4 pl-2 pb-0"
            *ngIf="this.companyData.CompanyDOSCertification !== undefined"
          >
            {{
              this.companyData.CompanyDOSCertification.Expiringdate
                | date : "yyyy-MM-dd"
            }}
          </p>
        </div>
      </div>
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("SISadminoverrideexcemption", infoBlockId) }}
      </p>
      <div class="w-52">
        <tds-datetime
          ngDefaultControl
          id="datetime"
          mode-variant="primary"
          type="date"
          size="sm"
          state="none"
          value="{{
            companyDOSCertificationForm.value.ExcemptionuntilOverride
              | date : 'YYYY-MM-dd'
          }}"
          (tdsChange)="handleOverrideDatePicker($event)"
        ></tds-datetime>
      </div>
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("excemptionuntilcomment", infoBlockId) }}
      </p>
      <div class="w-52">
        <tds-textarea
          row="5"
          ngDefaultControl
          type="text"
          size="sm"
          formControlName="ExcemptionUntilComment"
          placeholder="Comment"
        >
        </tds-textarea>
      </div>
      <tds-button
        [disabled]="mode === 'Add'"
        (click)="ViewHistory()"
        text="View History"
        size="sm"
        variant="secondary"
        class="mt-5"
      ></tds-button>
    </div>
    <div class="space-y-3">
      <div class="bg-gray-50 w-72 p-2 mt-5">
        <tds-checkbox
          value="checkbox-1"
          checkbox-id="application-checkbox"
          checked="{{ applicant === true }}"
          (tdsChange)="handleApplicant($event)"
        >
          <div slot="label">{{ getLabelText("Evaluation", infoBlockId) }}</div>
        </tds-checkbox>
      </div>
      <div class="border-box flex w-72 justify-between bg-gray-50 p-2">
        <tds-checkbox
          value="external-check"
          checkbox-id="external-checkbox"
          checked="{{
            companyDOSCertificationForm.value.AvailableforExternalSystems ===
              true
          }}"
          (tdsChange)="handleExternalSystems($event)"
        >
          <div slot="label">
            {{ getLabelText("Availableforexternalsystems", infoBlockId) }}
          </div>
        </tds-checkbox>
        @if(!companyDOSCertificationForm.value.DOSStatus){
        <tds-icon
          slot="icon"
          size="20px"
          name="cross"
          class="text-[#FF2340]"
        ></tds-icon>
        } @else {
        <tds-icon
          slot="icon"
          size="20px"
          name="tick"
          class="text-green-600"
        ></tds-icon>
        }
      </div>
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("availableforExternalSystemsComment", infoBlockId)
        }}<span
          class="text-red-600"
          *ngIf="companyDOSCertificationForm.value.AvailableforExternalSystems"
          >*</span
        >
      </p>
      <tds-textarea
        row="5"
        ngDefaultControl
        type="text"
        size="sm"
        formControlName="Availableforexternalsystems_comment"
        placeholder="Comment"
      >
      </tds-textarea>
    </div>
  </div>
</form>

<tds-modal
  header="DOS Exemption history"
  selector="#scania-history-modal-button"
  id="my-modal"
  size="lg"
  actions-position="static"
  prevent="false"
  closable="true"
  #historyModal
>
  <span class="viewHistory-spanbody" slot="body">
    <tds-table
      class="viewHistory-table"
      vertical-dividers="true"
      compact-design="false"
      responsive="false"
      no-min-width
    >
      <tds-table-header>
        <tds-header-cell
          cell-key="date"
          cell-value="Date"
          custom-width="400px"
        ></tds-header-cell>
        <tds-header-cell
          cell-key="byuser"
          cell-value="By user"
          custom-width="150px"
        ></tds-header-cell>
        <tds-header-cell
          cell-key="until/removed"
          cell-value="Until/Removed"
          custom-width="150px"
        ></tds-header-cell>
        <tds-header-cell
          cell-key="comment"
          cell-value="Comment"
          custom-width="400px"
        ></tds-header-cell>
      </tds-table-header>
      <tds-table-body>
        @if(history && history.length){
        <tds-table-body-row *ngFor="let row of history">
          <tds-body-cell cell-key="type">{{
            row?.Datum | date : "YYYY-MM-dd hh:mm:ss"
          }}</tds-body-cell>
          <tds-body-cell cell-key="datum">{{ row?.ByUser }}</tds-body-cell>
          <tds-body-cell cell-key="byuser">{{
            row?.Data.ExcemptionUntil || "Removed" | date : "YYYY-MM-dd"
          }}</tds-body-cell>
          <tds-body-cell cell-key="data">{{
            row?.Data.ExcemptionComment
          }}</tds-body-cell>
        </tds-table-body-row>
        } @else {
        <tds-table-body-row>
          <span class="text-center p-4">
            No Data Found</span
          ></tds-table-body-row
        >
        }
      </tds-table-body>
    </tds-table>
  </span>
  <span slot="actions" class="tds-u-flex tds-u-gap2">
    <tds-button
      data-dismiss-modal
      size="sm"
      text="Close"
      variant="primary"
    ></tds-button>
    <!-- <tds-button data-dismiss-modal size="md" text="Button Text" variant="secondary"></tds-button> -->
  </span>
</tds-modal>

<tds-modal
  header="{{ headerText }}"
  selector="#Info-modal-button"
  id="my-modal"
  size="sm"
  actions-position="static"
  prevent="true"
  closable="true"
  #InfoModal
>
  <span class="spanbody" slot="body">
    {{ popUpMessage }}
  </span>
  <span slot="actions" class="tds-u-flex tds-u-gap2">
    <tds-button
      data-dismiss-modal
      size="sm"
      text="Close"
      variant="primary"
    ></tds-button>
  </span>
</tds-modal>

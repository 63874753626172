import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  DetachedRouteHandle,
  RouteReuseStrategy,
  RouterModule,
  provideRouter,
  withRouterConfig,
} from '@angular/router';
import { AppComponent } from './app.component';
import { TegelModule } from '@scania/tegel-angular';
import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CustomErrorHandlerService } from './services/error-handler.service';
import { AuthInterceptor } from './shared/http-interceptor/auth.interceptor';
import { HeaderComponent } from './common/header/header.component';
import { LeftMenuComponent } from './common/left-menu/left-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { User } from './models/user.model';

// export class CustomRouteStrategy implements BaseRouteReuseStrategy {
//   shouldDetach(route: ActivatedRouteSnapshot): boolean {
//     throw new Error('Method not implemented.');
//   }
//   store(
//     route: ActivatedRouteSnapshot,
//     detachedTree: DetachedRouteHandle
//   ): void {
//     throw new Error('Method not implemented.');
//   }
//   shouldAttach(route: ActivatedRouteSnapshot): boolean {
//     throw new Error('Method not implemented.');
//   }
//   retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
//     throw new Error('Method not implemented.');
//   }
//   public shouldReuseRoute(
//     future: ActivatedRouteSnapshot,
//     curr: ActivatedRouteSnapshot
//   ): boolean {
//     return false;
//   }
// }

@NgModule({
  declarations: [AppComponent],
  providers: [
    provideRouter(routes, withRouterConfig({ onSameUrlNavigation: 'reload' })),

    provideHttpClient(withFetch(), withInterceptorsFromDi()),

    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    TegelModule,
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    RouterModule,
    LeftMenuComponent,
    FontAwesomeModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.baseUrl],
        sendAccessToken: true,
      },
    }),
  ],
})
export class AppModule {}

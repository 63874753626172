import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TegelModule } from '@scania/tegel-angular';
import { CompanyFormService } from '../../../../company/company-form.service';
import { UtilsService } from '../../../utils.service';
import { AppService } from '../../../../app.service';
import { CompanyService } from '../../../../services/company.service';

@Component({
  selector: 'app-assistance-notes',
  templateUrl: './assistance-notes.component.html',
  styleUrl: './assistance-notes.component.css',
})
export class AssistanceNotesComponent implements OnChanges {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() companyId: string = '';
  @Input() infoBlockId:string ='';

  @ViewChild('historyModal', { static: true }) historyModal: any;

  NotesForm =
    inject(CompanyFormService).form.controls.CompanyAssistanceNotesForm;
  notes: any = {};
  history: any = [];

  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private companyService: CompanyService
  ) {}

  customerNoFocus: boolean = false;
  invoiceReeiverNoFocus: boolean = false;

  get getExpiryDate1() {
    return this.NotesForm.get('expiryDate1');
  }
  get getExpiryDate2() {
    return this.NotesForm.get('expiryDate2');
  }

  get getShortTemNote() {
    return this.NotesForm.get('shortTermNote');
  }
  get getELongTermNote() {
    return this.NotesForm.get('longTermNote');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.NotesForm.patchValue({
        expiryDate1: this.companyData?.CompanyAssistanceNotes?.ExpiryDate1,
        expiryDate2: this.companyData?.CompanyAssistanceNotes?.ExpiryDate2,
        shortTermNote: this.companyData?.CompanyAssistanceNotes?.ShortTermNote,
        longTermNote: this.companyData?.CompanyAssistanceNotes?.LongTermNote,
      });
    } else {
      this.NotesForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      this.NotesForm.patchValue({
        expiryDate1: this.companyData?.CompanyAssistanceNotes?.ExpiryDate1,
        expiryDate2: this.companyData?.CompanyAssistanceNotes?.ExpiryDate2,
        shortTermNote: this.companyData?.CompanyAssistanceNotes?.ShortTermNote,
        longTermNote: this.companyData?.CompanyAssistanceNotes?.LongTermNote,
      });
    }
  }

  handleExpiryDate1DatePicker(event: any) {
    this.NotesForm.controls.expiryDate1.setValue(
      event.target.value + 'T00:00:00'
    );
  }
  handleExpiryDate2DatePicker(event: any) {
    this.NotesForm.controls.expiryDate2.setValue(
      event.target.value + 'T00:00:00'
    );
  }

  ViewHistory() {
    this.companyService
      .GetHistory(this.companyData.Id)
      .subscribe((res: any) => {
        if (res) {
          this.history = [];
          res.forEach((element: any) => {
            if (element.Typ.includes('CompanyAssistanceNotes')) {
              element.Data = JSON.parse(element.Data);
              this.history.push(element);
            }
          });
          this.historyModal.showModal();
        }
      });
  }
  saveDraft() {
    if (!this.companyData.CompanyAssistanceNotes)
      this.companyData.CompanyAssistanceNotes = {};
    this.companyData.CompanyAssistanceNotes.ExpiryDate1 =
      this.NotesForm.get('expiryDate1')?.value;
    this.companyData.CompanyAssistanceNotes.ExpiryDate2 =
      this.NotesForm.get('expiryDate2')?.value;
    this.companyData.CompanyAssistanceNotes.ShortTermNote =
      this.NotesForm.get('shortTermNote')?.value;
    this.companyData.CompanyAssistanceNotes.LongTermNote =
      this.NotesForm.get('longTermNote')?.value;

    if (this.NotesForm.dirty) {
      this.companyData.CompanyAssistanceNotes.IsUpdated = true;
    }

    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-distributor-options',
  standalone: false,
  templateUrl: './distributor-options.component.html',
  styleUrl: './distributor-options.component.css',
})
export class DistributorOptionsComponent implements OnChanges {
  @Input() companyId: string = '';
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId: string = '';
  distributorOptions: any;
  required: boolean = false;
  options: any = [
    {
      Distributoroption_code: '',
    },
  ];

  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    private appService: AppService,
    public companyFormService: CompanyFormService
  ) {}
  distributorOptionsForm =
    inject(CompanyFormService).form.controls.CompanyDistributorOptions;

  ngOnChanges(changes: SimpleChanges): void {
    this.CompanyService.getDistributorOptions().subscribe((data: any) => {
      this.distributorOptions = data;
      this.distributorOptions = this.distributorOptions.sort((a:any,b:any)=> 
        a?.Displayorder?.localeCompare(b?.Displayorder));
    });

    if (this.companyFormService.checkAllFormValidationDetails()) {
      this.distributorOptionsForm.controls.Distributoroption_code.addValidators(
        Validators.required
      );
      this.required = true;
    } else {
      this.distributorOptionsForm.controls.Distributoroption_code.removeValidators(
        Validators.required
      );
      this.required = false;
    }
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.options = [...this.companyData.CompanyDistributorOptions];
      this.distributorOptionsForm.patchValue(this.options[0]);
    } else {
      this.distributorOptionsForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();

      if (!this.companyData.CompanyDistributorOptions) {
        this.companyData.CompanyDistributorOptions = [];
      } else {
        this.options = [...this.companyData.CompanyDistributorOptions];
        this.distributorOptionsForm.patchValue(this.options[0]);
      }
    }
  }

  handleChecked(code: any) {
    this.distributorOptionsForm.controls.Distributoroption_code.setValue(code);
    this.distributorOptionsForm.controls.Company_id.setValue(
      this.companyData.Id
    );

    this.options = [
      {
        Distributoroption_code: code,
        Company_id: this.companyData.Id,
      },
    ];
    this.distributorOptionsForm.markAsDirty();
    this.saveDraft();
  }

  get distributorCode() {
    return this.distributorOptionsForm.get('Distributoroption_code');
  }

  saveDraft() {
    if (this.distributorOptionsForm.dirty) {
      //  this.companyData.CompanyDistributorOptions.IsUpdated = true;
      this.companyData.CompanyDistributorOptions.forEach(
        (item: any) => (item.IsUpdated = true)
      );
    }
    if (this.mode !== 'Add') {
      this.companyData.CompanyDistributorOptions = [...this.options];
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.companyData.CompanyDistributorOptions = [...this.options];
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

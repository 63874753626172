<div
  *ngIf="contactInformationDetails && contactInformationDetails.length"
  [class]="this.mode == 'print' ? '' : 'overflow-auto p-4'"
>
  <tds-table
    class="min-w-full table-auto"
    vertical-dividers="true"
    compact-design="true"
    responsive="true"
    no-min-width
  >
    <tds-table-header>
      <tds-header-cell
        cell-key="position/responsible"
        cell-value="Position/Responsible"
        style="min-width: 250px"
      ></tds-header-cell>
      <tds-header-cell
        cell-key="name"
        cell-value="Name"
        style="min-width: 200px"
      ></tds-header-cell>
      <tds-header-cell cell-key="email" cell-value="Email"></tds-header-cell>
      <tds-header-cell cell-key="phone" cell-value="Phone"></tds-header-cell>
      <tds-header-cell cell-key="mobile" cell-value="Mobile"></tds-header-cell>
      <!-- <tds-header-cell
        cell-key="fax"
        cell-value="Fax"
        custom-width="50px"
      ></tds-header-cell> -->
      <tds-header-cell
        cell-key="profile"
        cell-value="Profile"
      ></tds-header-cell>
      <tds-header-cell cell-key="others" cell-value="Others"></tds-header-cell>
      @if(this.mode !== 'print'){
      <tds-header-cell cell-key="" cell-value=""></tds-header-cell>
      <tds-header-cell cell-key="" cell-value=""></tds-header-cell>
      }
    </tds-table-header>
    <tds-table-body>
      @if(contactInformationDetails && contactInformationDetails.length){
      <tds-table-body-row
        *ngFor="let info of contactInformationDetails; let i = index"
      >
        <tds-body-cell cell-key="Globalmanagementtype_code">{{
          getPositionName(info?.Globalmanagementtype_code)
        }}</tds-body-cell>
        <tds-body-cell cell-key="Name" class="text-nowrap">{{
          info?.Name || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Email" class="text-nowrap">{{
          info?.Email || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Phoneno" class="text-nowrap">{{
          info?.Phoneno || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Mobile" class="text-nowrap">{{
          info?.Mobile || "-"
        }}</tds-body-cell>
        <!-- <tds-body-cell cell-key="Fax">{{ info?.Fax || "-" }}</tds-body-cell> -->
        <tds-body-cell cell-key="Profile">{{
          info?.Profile || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Others">{{
          info?.Others || "-"
        }}</tds-body-cell>
        @if(this.mode !== 'print'){
        <tds-body-cell cell-key="">
          <tds-icon
            size="18px"
            name="edit"
            class="cursor-pointer"
            (click)="editFunc(info)"
          ></tds-icon>
        </tds-body-cell>
        <tds-body-cell cell-key="">
          <tds-icon
            size="18px"
            name="trash"
            class="cursor-pointer"
            (click)="delFunc(i)"
          ></tds-icon
        ></tds-body-cell>
        }
      </tds-table-body-row>
      } @else {
      <tds-table-body-row>
        <span class="text-center p-4"> No Data Found</span></tds-table-body-row
      >
      }
    </tds-table-body>
  </tds-table>
</div>

<div class="p-5 mb-3">
  <tds-button
    (click)="AddNewContact()"
    text="Add New Contact"
    size="sm"
    variant="secondary"
    class="mt-5"
  >
    <tds-icon slot="icon" size="20px" name="contact"></tds-icon
  ></tds-button>

  <tds-button *ngIf="contactInformationDetails && contactInformationDetails.length"
  (click)="ShowContact()"
  text="Expand"
  size="sm"
  variant="secondary"
  class="ml-2 mt-2 sc-tds-button-h sc-tds-button-s hydrated"
  > 
  <tds-icon slot="icon" size="20px" name="arrow_diagonal"></tds-icon>
  </tds-button>
</div>

<tds-modal 
actions-position="static"
size="xl" id="expand-modal" prevent="true" closable="true" #ShowContactModal>
<span slot="body"> 
  <div
  *ngIf="contactInformationDetails && contactInformationDetails.length"
  [class]="this.mode == 'print' ? '' : 'overflow-auto p-4'"
>
  <tds-table
    class="min-w-full table-auto"
    vertical-dividers="true"
    compact-design="true"
    responsive="true"
    no-min-width
  >
    <tds-table-header>
      <tds-header-cell
        cell-key="position/responsible"
        cell-value="Position/Responsible"
        style="min-width: 250px"
      ></tds-header-cell>
      <tds-header-cell
        cell-key="name"
        cell-value="Name"
        style="min-width: 200px"
      ></tds-header-cell>
      <tds-header-cell cell-key="email" cell-value="Email"></tds-header-cell>
      <tds-header-cell cell-key="phone" cell-value="Phone"></tds-header-cell>
      <tds-header-cell cell-key="mobile" cell-value="Mobile"></tds-header-cell>
      <!-- <tds-header-cell
        cell-key="fax"
        cell-value="Fax"
        custom-width="50px"
      ></tds-header-cell> -->
      <tds-header-cell
        cell-key="profile"
        cell-value="Profile"
      ></tds-header-cell>
      <tds-header-cell cell-key="others" cell-value="Others"></tds-header-cell>
      @if(this.mode !== 'print'){
      <tds-header-cell cell-key="" cell-value=""></tds-header-cell>
      <tds-header-cell cell-key="" cell-value=""></tds-header-cell>
      }
    </tds-table-header>
    <tds-table-body>
      @if(contactInformationDetails && contactInformationDetails.length){
      <tds-table-body-row
        *ngFor="let info of contactInformationDetails; let i = index"
      >
        <tds-body-cell cell-key="Globalmanagementtype_code">{{
          getPositionName(info?.Globalmanagementtype_code)
        }}</tds-body-cell>
        <tds-body-cell cell-key="Name" class="text-nowrap">{{
          info?.Name || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Email" class="text-nowrap">{{
          info?.Email || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Phoneno" class="text-nowrap">{{
          info?.Phoneno || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Mobile" class="text-nowrap">{{
          info?.Mobile || "-"
        }}</tds-body-cell>
        <!-- <tds-body-cell cell-key="Fax">{{ info?.Fax || "-" }}</tds-body-cell> -->
        <tds-body-cell cell-key="Profile">{{
          info?.Profile || "-"
        }}</tds-body-cell>
        <tds-body-cell cell-key="Others">{{
          info?.Others || "-"
        }}</tds-body-cell>
        @if(this.mode !== 'print'){
        <tds-body-cell cell-key="">
          <tds-icon
            size="18px"
            name="edit"
            class="cursor-pointer"
            (click)="editFunc1(info)"
          ></tds-icon>
        </tds-body-cell>
        <tds-body-cell cell-key="">
          <tds-icon
            size="18px"
            name="trash"
            class="cursor-pointer"
            (click)="delFunc(i)"
          ></tds-icon
        ></tds-body-cell>
        }
      </tds-table-body-row>
      } @else {
      <tds-table-body-row>
        <span class="text-center p-4"> No Data Found</span></tds-table-body-row
      >
      }
    </tds-table-body>
  </tds-table>
</div>  
</span>
</tds-modal>

<tds-modal 
  header="{{ action === 'Add' ? 'Add New' : 'Edit' }} Contact"
  selector="#scania-history-modal-button"
  id="my-modal"
  size="lg"
  actions-position="static"
  prevent="true"
  closable="false"
  #AddNewContactModal
>
<span slot="header">
  <button
        type="button"
        class="float-right no-border"
        style="width: 30px"
        (click)="cancelForm()">
         <div>
           <tds-icon slot="icon" name="cross" size="20px">
           </tds-icon>
         </div>
  </button>
</span>
<span class="viewHistory-spanbody" slot="body">
    <form [formGroup]="inputForm">
      <div class="flex flex-col p-5 mb-5">
        <div>
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="p-3">
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Type</p>
              <div class="bg-gray-50 p-1">
                <tds-dropdown
                  ngDefaultControl
                  name="dropdown"
                  size="sm"
                  id="Globalmanagementtype_code"
                  formControlName="Globalmanagementtype_code"
                  [defaultValue]="globalMgmtTypeCode[0]?.Code"
                  open-direction="down"
                  #tegeldropDown
                  (tdsChange)="handleAccessDropDown($event)"
                  filter="true"
                >
                  @for (type of globalMgmtTypeCode; track $index) {
                  <tds-dropdown-option
                    value="{{ type.Code }}"
                    [title]="type.Name"
                    >{{ type.Name }}</tds-dropdown-option
                  >
                  }
                </tds-dropdown>
              </div>
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Name</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  formControlName="Name"
                  id="name"
                  placeholder="Enter Name"
                >
                </tds-text-field>
              </div>
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Email</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  formControlName="Email"
                  id="email"
                  placeholder="Enter Email"
                  (tdsBlur)="EmailFocus = true"
                  (tdsFocus)="EmailFocus = true"
                  [helper]="
                    getErrorMsgs(getEmail('Email'), 'Email', EmailFocus)
                  "
                  [state]="
                    EmailFocus && getEmail('Email').invalid
                      ? 'error'
                      : 'success'
                  "
                >
                </tds-text-field>
              </div>
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Phone</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  formControlName="Phoneno"
                  id="phone"
                  placeholder="Enter Phone"
                  (tdsBlur)="PhoneFocus = true"
                  (tdsFocus)="PhoneFocus = true"
                  [helper]="
                    getErrorMsgs(getPhone('Phoneno'), 'Phoneno', PhoneFocus)
                  "
                  [state]="
                    PhoneFocus && getPhone('Phoneno').invalid
                      ? 'error'
                      : 'success'
                  "
                >
                </tds-text-field>
              </div>
            </div>
            <div class="p-3">
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Mobile</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  formControlName="Mobile"
                  id="mobile"
                  placeholder="Enter Mobile"
                  (tdsBlur)="PhoneFocus = true"
                  (tdsFocus)="PhoneFocus = true"
                  [helper]="
                    getErrorMsgs(getMobile('Mobile'), 'Mobile', PhoneFocus)
                  "
                  [state]="
                    PhoneFocus && getMobile('Mobile').invalid
                      ? 'error'
                      : 'success'
                  "
                >
                </tds-text-field>
              </div>
              <!-- <p class="tds-headline-06 mt-5 mb-0 pb-0">Fax</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  formControlName="Fax"
                  id="fax"
                  placeholder="Enter Fax"
                >
                </tds-text-field>
              </div> -->
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Others</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  placeholder="Enter Others"
                  formControlName="Others"
                  id="others"
                >
                </tds-text-field>
              </div>
              <p class="tds-headline-06 mt-5 mb-0 pb-0">Profile</p>
              <div class="bg-gray-50 p-1">
                <tds-text-field
                  ngDefaultControl
                  type="Text"
                  size="sm"
                  formControlName="Profile"
                  id="profile"
                  placeholder="Enter Profile"
                >
                </tds-text-field>
              </div>
              <!-- <div class="bg-gray-50 p-1">
                <tds-dropdown
                  ngDefaultControl
                  name="dropdown"
                  size="sm"
                  id="profile"
                  formControlName="Profile"
                  open-direction="down"
                  #profileDropDown
                  (tdsChange)="handleProfileDropDown($event)"
                  filter="true"
                >
                <tds-dropdown-option
                    value="Select"
                    >Select Profile</tds-dropdown-option
                  >
                  @for (profile of filteredProfiles; track $index) {
                  <tds-dropdown-option
                    value="{{ profile.Name }}"
                    [title]="profile.Name"
                    >{{ profile.Name }}</tds-dropdown-option
                  >
                  }
                </tds-dropdown>
              </div> -->
            </div>
          </div>
        </div>
        <div class="flex flex-row-reverse space-x-2 p-3 pl-5">
          <tds-button
            text="Save Contact"
            size="sm"
            variant="primary"
            type="button"
            (click)="saveContact()"
            style="padding: 10px"
          ></tds-button>
          <tds-button
            text="Cancel"
            size="sm"
            variant="secondary"
            (click)="cancelForm()"
          >
            <tds-icon slot="icon" size="15px" name="cross"></tds-icon>
          </tds-button>
        </div>
      </div>
    </form>
  </span>
</tds-modal>

import { inject, Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { PhoneTypeService } from '../services/phone-type.service';
import { AppService } from '../app.service';
import { sharedConfig } from '../shared/shared';
import { IFormStructure } from './company-form.interface';
import { SharedService } from '../services/shared.service';
import * as uuid from 'uuid';

@Injectable()
export class CompanyFormService {
  form = inject(FormBuilder).group({
    companyInformation: new FormGroup({
      Id: new FormControl('', Validators.required),
      Name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      Name_Local: new FormControl(''),
      Country: new FormControl(''),
      City: new FormControl('', [Validators.required, Validators.minLength(3)]),
      City_Local: new FormControl(''),
      Vat_no: new FormControl(''),
      Region: new FormControl(''),
      Region_Local: new FormControl(''),
      Lastupdated: new FormControl(''),
      Lastupdatedby: new FormControl(),
    }),
    partsLogisticForm: new FormGroup({
      deliveryCustomerNo: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      invoiceReceiverNo: new FormControl('', Validators.minLength(5)),
      distributorCustomerNo: new FormControl(''),
    }),
    bankInfoForm: new FormGroup({
      bankName: new FormControl('', Validators.maxLength(100)),
      bankCity: new FormControl('', Validators.maxLength(100)),
      bankAccountNo: new FormControl('', Validators.maxLength(100)),
      swiftBiCode: new FormControl('', Validators.maxLength(50)),
      iban: new FormControl('', Validators.maxLength(50)),
    }),
    productAndServicesForm: new FormGroup({
      CompanySupportedServices: new FormArray([]),
    }),
    CompanyOtherCards: new FormGroup({
      OtherTxt: new FormControl('', Validators.maxLength(255)),
      Company_id: new FormControl(''),
    }),
    CompanyAdministrativeIssues: new FormGroup({
      Administrative_issues: new FormControl('', Validators.maxLength(255)),
      Company_id: new FormControl(''),
    }),
    CompanyAssistanceWorkoutCharges: new FormGroup({
      AfterHourCompensation: new FormControl('', Validators.maxLength(200)),
      CalloutFee: new FormControl('', Validators.maxLength(200)),
      Company_id: new FormControl(''),
    }),
    CompanyDistributorImport: new FormGroup({
      Id: new FormControl(''),
      DistributorImportType_code: new FormControl(''),
      Company_id: new FormControl(''),
    }),
    CompanyGlobalMangementConatctsForm: new FormGroup({
      CompanyGlobalManagementContacts: new FormArray([]),
    }),
    CompanyDistributorOptions: new FormGroup({
      Id: new FormControl(''),
      Distributoroption_code: new FormControl('', Validators.required),
      Company_id: new FormControl(''),
    }),
    CompanyContractedPartner: new FormGroup({
      Is_ContractedPartner: new FormControl(false),
      Company_id: new FormControl(''),
    }),
    CompanyAssistance: new FormGroup({
      IsScaniaAssistance: new FormControl(false),
      Company_id: new FormControl(''),
    }),
    CompanyTag: new FormGroup({
      Tag_id: new FormControl(''),
      Company_id: new FormControl(''),
    }),
    phoneFaxForm: new FormGroup({}),
    contactForm: new FormGroup({}),
    paymentMethodForm: new FormGroup({
      CompanyAcceptedCreditCards: new FormArray([]),
    }),
    CompanyLocation: new FormGroup({
      Company_id: new FormControl(''),
      Latitude: new FormControl(''),
      Longitude: new FormControl(''),
      TimeZoneId: new FormControl(''),
    }),
    CompanyDOSCertification: new FormGroup({
      IsCertified: new FormControl(false),
      Evaluation: new FormControl(false),
      Expiringdate: new FormControl(''),
      Company_id: new FormControl(''),
      ExcemptionUntilComment: new FormControl(''),
      ExcemptionuntilOverride: new FormControl(''),
      AvailableforExternalSystems: new FormControl(false),
      Availableforexternalsystems_comment: new FormControl(''),
      DOSStatus: new FormControl(false),
    }),
    addressForm: new FormGroup({}),
    categorizationForm: new FormArray([]),
    CompanyOpeningHours: new FormArray([]),
    CompanyOpeningHoursComment: new FormGroup({
      Company_id: new FormControl(''),
      Txt: new FormControl(''),
    }),
    CompanyDealerWorkshop: new FormGroup({
      Company_id: new FormControl(''),
      DealerId: new FormControl('', Validators.required),
      DealerWorkshopOwnership_code: new FormControl('', Validators.required),
      DealerWorkshopType_code: new FormControl('', Validators.required),
      Parent_DealerId: new FormControl(''),
      Termination: new FormControl(''),
      comment: new FormControl(''),
      notice: new FormControl(''),
      Parent_Distributor_Id: new FormControl(''),
      ParentId: new FormControl(''),
    }),
    CompanyAssistanceRoleDetailsForm: new FormGroup({
      CompanyAssistanceRoleDetails: new FormArray([]),
    }),
    CompanyAssistanceProductServiceForm: new FormGroup({
      CompanyAssistanceProductService: new FormArray([]),
    }),
    CompanyAssistanceNotesForm: new FormGroup({
      shortTermNote: new FormControl('', Validators.maxLength(4000)),
      longTermNote: new FormControl('', Validators.maxLength(40000)),
      expiryDate1: new FormControl(''),
      expiryDate2: new FormControl(''),
    }),
    CompanyUsedVehicleAndPowerSolutions: new FormGroup({
      UsedVehicleAndPowerSolutions_Code: new FormControl(''),
      Company_id: new FormControl(''),
    }),
    CompanyWorkshopCapabilities: new FormGroup({
      workstation_large: new FormControl(''),
      workstation_intermediate: new FormControl(''),
      workstation_basic: new FormControl(''),
      accident_repair: new FormControl(''),
      diagnosis: new FormControl(''),
      washing_other: new FormControl(''),
      ownership: new FormControl(''),
      rent_exp_date: new FormControl(''),
      drive_time: new FormControl(''),
      Company_id: new FormControl(''),
    }),
    CompanySocialMediaContacts: new FormArray([]),
  });
  categoryData: any;
  catFieldConfig: any;

  resetAllForms() {
    this.form.reset();
  }

  constructor(
    private phoneTypeService: PhoneTypeService,
    private appService: AppService,
    private sharedService: SharedService
  ) {
    // Trigger side-effects for the form
  }

  getphoneFormFields() {
    let controlArr: any[] = [];

    if (this.appService.phoneTypeFieldConfig) {
      this.categoryData = this.appService.fetchSelectedCategoryData();

      if (this.categoryData) {
        let catFieldConfig = JSON.parse(this.categoryData?.FieldconfigDataJson);

        for (let prop in catFieldConfig) {
          try {
            catFieldConfig[prop] = catFieldConfig[prop]
              ? JSON.parse(catFieldConfig[prop])
              : '';
          } catch {
            catFieldConfig[prop] = catFieldConfig[prop];
          }
        }
        this.catFieldConfig = catFieldConfig;
        if (this.appService.fetchViewModelForPhoneTypes()) {
          let viewModel = this.appService.fetchViewModelForPhoneTypes();

          viewModel.forEach((item: any) => {
            for (let prop in catFieldConfig) {
              if (item.Min === prop) {
                if (
                  catFieldConfig[prop] > 0 &&
                  this.checkAllFormValidationDetails()
                ) {
                  item.required = true;
                  item.minFieldLength = catFieldConfig[prop];
                }
              }
              if (item.Max === prop) {
                if (catFieldConfig[prop] > 0) {
                  item.show = true;
                  item.maxFieldLength = catFieldConfig[prop];
                  if (item.maxFieldLength > 1) {
                    item.maxFieldLengthArr = Array(item.maxFieldLength).map(
                      (x, i) => i
                    );
                  }
                }
              }
            }

            if (this.sharedService.getValidationRuleConfig()) {
              let ValidationObj = this.sharedService
                .getValidationRuleConfig()
                .find((obj) => obj.rule == item.Validationrule);
              if (ValidationObj) {
                item.Regex = ValidationObj.regex;
                item.Message = ValidationObj.message;
              }
            }
          });

          controlArr = viewModel.filter((item: any) => {
            return (
              item.show == true && !item.Name.toLowerCase().includes('fax')
            );
          });

          // console.log(controlArr);
        }
      }
    }

    return controlArr;
  }

  getCategorizationFormFields() {
    let controlArr: any[] = [];

    if (this.appService.categorizationConfig) {
      let categorizationConfig = this.appService.categorizationConfig.filter(
        (item: any) => item.Type === 'categorization'
      );

      this.categoryData = this.appService.fetchSelectedCategoryData();
      // console.log('categoryData---', this.categoryData);

      if (this.categoryData) {
        let catFieldConfig = JSON.parse(this.categoryData?.FieldconfigDataJson);
        //  console.log('catFieldConfig---',this.catFieldConfig)

        for (let prop in catFieldConfig) {
          try {
            catFieldConfig[prop] = catFieldConfig[prop]
              ? JSON.parse(catFieldConfig[prop])
              : '';
          } catch {
            catFieldConfig[prop] = catFieldConfig[prop];
          }
        }
        this.catFieldConfig = catFieldConfig;
        //  console.log('catFieldConfig-2--', this.catFieldConfig);

        if (this.appService.fetchViewModelForCategorizationTypes()) {
          let viewModel =
            this.appService.fetchViewModelForCategorizationTypes();

          //  console.log('viewModel-2--', viewModel);

          viewModel.forEach((item: any) => {
            // console.log(
            //   'catFieldConfig---',
            //   catFieldConfig['TG' + item.Id + 'Showtype']
            // );

            if (
              catFieldConfig['TG' + item.Id + 'Showtype'] &&
              catFieldConfig['TG' + item.Id + 'Showtype'] !== 'NONE'
            ) {
              item.show = true;
            }

            if (catFieldConfig['TG' + item.Id]) {
              item.required = true;
            }

            if (
              catFieldConfig['CAT-NoRequired'] !== undefined &&
              catFieldConfig['CAT-NoRequired']
            ) {
              item.required = false;
            }
          });

          controlArr = viewModel.filter((item: any) => {
            return item.show == true;
          });

          //  console.log(controlArr);
        }
      }
    }

    return controlArr;
  }

  getaddressFormFields() {
    let controlArr: any[] = [];

    if (this.appService.addressTypeFieldConfig) {
      this.categoryData = this.appService.fetchSelectedCategoryData();

      if (this.categoryData) {
        let catFieldConfig = JSON.parse(this.categoryData?.FieldconfigDataJson);

        for (let prop in catFieldConfig) {
          try {
            catFieldConfig[prop] = catFieldConfig[prop]
              ? JSON.parse(catFieldConfig[prop])
              : '';
          } catch {
            catFieldConfig[prop] = catFieldConfig[prop];
          }
        }
        this.catFieldConfig = catFieldConfig;
        if (this.appService.fetchViewModelForAddressTypes()) {
          let viewModel = this.appService.fetchViewModelForAddressTypes();

          viewModel.forEach((item: any) => {
            for (let prop in catFieldConfig) {
              if (item.Min === prop) {
                if (catFieldConfig[prop] > 0) {
                  item.required = true;
                  item.minFieldLength = catFieldConfig[prop];
                }
              }
              if (item.Max === prop) {
                if (catFieldConfig[prop] > 0) {
                  item.show = true;
                  item.maxFieldLength = catFieldConfig[prop];
                  if (item.maxFieldLength > 1) {
                    item.maxFieldLengthArr = Array(item.maxFieldLength).map(
                      (x, i) => i
                    );
                  }
                }
              }
            }

            if (catFieldConfig['CA' + item.Code + '_ShowCompanyNameField']) {
              item.companyShow = true;
            }

            if (this.sharedService.getValidationRuleConfig()) {
              let ValidationObj = this.sharedService
                .getValidationRuleConfig()
                .find((obj) => obj.rule == item.Validationrule);
              if (ValidationObj) {
                item.Regex = ValidationObj.regex;
                item.Message = ValidationObj.message;
              }
            }
          });

          controlArr = viewModel.filter((item: any) => {
            return item.show == true;
          });

          //   console.log(controlArr);
        }
      }
    }

    return controlArr;
  }

  getcontactFormFields() {
    let controlArr: any[] = [];

    if (this.appService.contactFieldConfig) {
      let contactFieldConfig = this.appService.contactFieldConfig.filter(
        (item: any) => item.Type === 'contact'
      );

      this.categoryData = this.appService.fetchSelectedCategoryData();

      if (this.categoryData) {
        let catFieldConfig = JSON.parse(this.categoryData?.FieldconfigDataJson);

        for (let prop in catFieldConfig) {
          try {
            catFieldConfig[prop] = catFieldConfig[prop]
              ? JSON.parse(catFieldConfig[prop])
              : '';
          } catch {
            catFieldConfig[prop] = catFieldConfig[prop];
          }
        }
        this.catFieldConfig = catFieldConfig;
        if (this.appService.fetchViewModelForContactTypes()) {
          let viewModel = this.appService.fetchViewModelForContactTypes();

          viewModel.forEach((item: any) => {
            for (let prop in catFieldConfig) {
              if (item.Min.toLowerCase() === prop.toLowerCase()) {
                if (
                  catFieldConfig[prop] > 0 &&
                  this.checkAllFormValidationDetails()
                ) {
                  item.required = true;
                  item.minFieldLength = catFieldConfig[prop];
                }
              }
              if (item.Max.toLowerCase() === prop.toLowerCase()) {
                if (catFieldConfig[prop] > 0) {
                  item.show = true;
                  item.maxFieldLength = catFieldConfig[prop];
                  if (item.maxFieldLength > 1) {
                    item.maxFieldLengthArr = Array(item.maxFieldLength).map(
                      (x, i) => i
                    );
                  }
                }
              }
            }

            if (this.sharedService.getValidationRuleConfig()) {
              let ValidationObj = this.sharedService
                .getValidationRuleConfig()
                .find((obj) => obj.rule == item.Validationrule);
              if (ValidationObj) {
                item.Regex = ValidationObj.regex;
                item.Message = ValidationObj.message;
              }
            }
          });

          // console.log(viewModel);
          //  console.log(catFieldConfig);

          controlArr = viewModel.filter((item: any) => {
            return item.show == true;
          });

          // console.log(controlArr);
        }
      }
    }

    return controlArr;
  }
  formphoneFormFields(controlArr: any[]) {
    let formGroup: FormGroup = new FormGroup({});
    controlArr = controlArr.filter(
      (item: any) => item.Displayorder && item.show
    );
    controlArr.forEach((item: any) => {
      let validatorArr: Validators[] | any = [];
      let formObj: FormControl | any;
      // if (item.required) {
      //   validatorArr.push(Validators.required);
      // }

      if (item.Regex) {
        validatorArr.push(Validators.pattern(new RegExp(item.Regex)));
      }
      if (item.maxFieldLength > 1) {
        let minRequired = item.minFieldLength || 0;

        for (let i = 0; i < item.maxFieldLength; i++) {
          // if (item.minFieldLength && item.minFieldLength > 0) {
          //   minRequired = item.minFieldLength - 1;
          if (minRequired > 0) {
            if (this.checkAllFormValidationDetails()) {
              validatorArr.push(Validators.required);
            } else {
              validatorArr = validatorArr.filter(
                (validator: any) => validator !== Validators.required
              );
            }
            minRequired--;
          } else {
            validatorArr = [];
          }

          formGroup.addControl(
            item.Code + (i || ''),
            new FormControl('', validatorArr)
          );
          formGroup.addControl(
            item.Code + (i || '') + '_comment',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_id',
            new FormControl('')
          );
        }
      } else {
        if (item.required && this.checkAllFormValidationDetails()) {
          validatorArr.push(Validators.required);
        }
        formGroup.addControl(item.Code, new FormControl('', validatorArr));
        formGroup.addControl(item.Code + '_comment', new FormControl(''));
        formGroup.addControl(item.Code + '_id', new FormControl(''));
      }

      // formGroup.addControl(item.Code, formObj);
    });

    this.form.controls.phoneFaxForm = formGroup;
    // console.log('formGroup-----', formGroup);
    return formGroup;
  }

  formcontactFormFields(controlArr: any[]) {
    let formGroup: FormGroup = new FormGroup({});
    controlArr = controlArr.filter(
      (item: any) => item.Displayorder && item.show
    );
    controlArr.forEach((item: any) => {
      let validatorArr: Validators[] | any = [];
      let formObj: FormControl | any;

      if (item.Regex && item.Validationrule !== 'email') {
        validatorArr.push(Validators.pattern(new RegExp(item.Regex)));
      } else if (item.Validationrule == 'email') {
        validatorArr.push(Validators.email);
      }
      if (item.maxFieldLength > 1) {
        let minRequired = item.minFieldLength || 0;

        for (let i = 0; i < item.maxFieldLength; i++) {
          if (minRequired > 0) {
            if (this.checkAllFormValidationDetails()) {
              validatorArr.push(Validators.required);
            }
            minRequired--;
          } else {
            //validatorArr = [];
            validatorArr = validatorArr.filter(
              (validator: any) => validator !== Validators.required
            );
          }
          formGroup.addControl(
            item.Code + (i || ''),
            new FormControl('', validatorArr)
          );
          formGroup.addControl(
            item.Code + (i || '') + '_comment',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_id',
            new FormControl('')
          );
        }
      } else {
        if (item.required && this.checkAllFormValidationDetails()) {
          validatorArr.push(Validators.required);
        }
        formGroup.addControl(item.Code, new FormControl('', validatorArr));
        formGroup.addControl(item.Code + '_comment', new FormControl(''));
        formGroup.addControl(item.Code + '_id', new FormControl(''));
      }

      // formGroup.addControl(item.Code, formObj);
    });

    this.form.controls.contactForm = formGroup;

    return formGroup;
  }

  formCategorizationFormFields(controlArr: any[]) {
    let formGroup: FormGroup = new FormGroup({});
    controlArr = controlArr.filter((item: any) => item.show);
    controlArr.forEach((item: any) => {
      // let validatorArr: Validators[] | any = [];
      formGroup.addControl('Tag_id', new FormControl('', Validators.required));
      formGroup.addControl('Company_id', new FormControl(''));
    });

    (this.form.get('categorizationForm') as FormArray).push(formGroup);
    return formGroup;
  }

  getLocationFormValidation() {
    let required = false;
    this.categoryData = this.appService.fetchSelectedCategoryData();
    if (this.categoryData && this.categoryData.FieldconfigDataJson) {
      let catFieldConfig = JSON.parse(this.categoryData.FieldconfigDataJson);

      for (let prop in catFieldConfig) {
        try {
          catFieldConfig[prop] = catFieldConfig[prop]
            ? JSON.parse(catFieldConfig[prop])
            : '';
        } catch {
          catFieldConfig[prop] = catFieldConfig[prop];
        }
      }
      this.catFieldConfig = catFieldConfig;

      if (this.catFieldConfig['LocationRequired']) {
        required = true;
      }

      if (catFieldConfig['CAT-NoRequired']) {
        required = false;
      }
    }

    return required;
  }

  checkAllFormValidationDetails() {
    this.categoryData = this.appService.fetchSelectedCategoryData();
    if (this.categoryData && this.categoryData.FieldconfigDataJson) {
      let catFieldConfig = JSON.parse(this.categoryData.FieldconfigDataJson);

      for (let prop in catFieldConfig) {
        try {
          catFieldConfig[prop] = catFieldConfig[prop]
            ? JSON.parse(catFieldConfig[prop])
            : '';
        } catch {
          catFieldConfig[prop] = catFieldConfig[prop];
        }
      }
      this.catFieldConfig = catFieldConfig;
      if (this.catFieldConfig['CAT-NoRequired']) {
        return false;
      }
    }

    return true;
  }

  formAddressFormFields(controlArr: any[]) {
    let obj = {
      Code: {
        StreetAddress: '',
        StreetAddress_local: '',
        Postal_city: '',
        Postal_code: '',
        Postal_city_local: '',
        Country: '',
        Comment: '',
        Company_name: '',
      },
    };
    let formGroup: FormGroup = new FormGroup({});
    controlArr = controlArr.filter(
      (item: any) => item.Displayorder && item.show
    );
    controlArr.forEach((item: any) => {
      let validatorArr: Validators[] | any = [];
      let formObj: FormControl | any;

      if (item.maxFieldLength > 0) {
        let minRequired = item.minFieldLength || 0;

        for (let i = 0; i < item.maxFieldLength; i++) {
          if (minRequired > 0) {
            if (this.checkAllFormValidationDetails()) {
              validatorArr.push(Validators.required);
            } else validatorArr = [];
            minRequired--;
          } else {
            validatorArr = [];
          }
          formGroup.addControl(
            item.Code + (i || '') + '_streetAddress',
            new FormControl('', validatorArr)
          );
          formGroup.addControl(
            item.Code + (i || '') + '_streetAddress_local',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_postal_city',
            new FormControl('', validatorArr)
          );
          formGroup.addControl(
            item.Code + (i || '') + '_postal_city_local',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_postal_code',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_company_name',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_country',
            new FormControl('')
          );
          formGroup.addControl(
            item.Code + (i || '') + '_comment',
            new FormControl('')
          );

          formGroup.addControl(
            item.Code + (i || '') + '_id',
            new FormControl('')
          );
        }
      }
    });

    this.form.controls.addressForm = formGroup;

    return formGroup;
  }
}

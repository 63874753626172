<p class="m-5 tds-headline-04" style="height: 24px">
  {{ mode === "Add" ? "New" : mode }} Address Type
</p>

<div class="flex flex-col edit-container mx-5">
  <div class="flex bg-gray-300" style="height: 50px">
    <p class="tds-paragraph-04 mx-4 my-3.5">Address Type Information</p>
  </div>
  <form
    class="user-form"
    [formGroup]="editForm"
    (ngSubmit)="onSubmit()"
    (change)="updateDetails()"
  >
    <div class="mx-5">
      <div class="pt-5">
        <label class="tds-headline-04 tds-text-grey-958 tds-headline-06"
          >Code</label
        >
        <label class="tds-headline-04 tds-text-red-500">*</label>
      </div>

      <div class="inline-block w-52">
        <tds-text-field
          ngDefaultControl
          type="text"
          size="sm"
          state="default"
          label-position="no label"
          no-min-width
          placeholder="Code"
          formControlName="Code"
          (tdsBlur)="codeFocus = true"
          (tdsFocus)="codeFocus = true"
          [helper]="getErrorMsgs(Code, 'Code', codeFocus)"
          [state]="codeFocus && Code?.invalid ? 'error' : 'success'"
        >
        </tds-text-field>
      </div>
    </div>
    <div class="mx-5">
      <div class="pt-5">
        <label class="tds-headline-04 tds-text-grey-958 tds-headline-06"
          >Name</label
        >
        <label class="tds-headline-04 tds-text-red-500">*</label>
      </div>
      <div class="inline-block w-52">
        <tds-text-field
          ngDefaultControl
          type="text"
          size="sm"
          state="default"
          label-position="no label"
          no-min-width
          placeholder="Name"
          formControlName="Name"
          (tdsBlur)="nameFocus = true"
          (tdsFocus)="nameFocus = true"
          [helper]="getErrorMsgs(Name, 'Name', nameFocus)"
          [state]="nameFocus && Name?.invalid ? 'error' : 'success'"
        >
        </tds-text-field>
      </div>
    </div>

    <div class="m-4 space-x-2">
      <button
        type="button"
        class="cancel-border rounded-sm h-10 bg-white mb-4 cursor-pointer"
        style="width: 88px"
        (click)="cancel()"
      >
        <div class="px-1 py-0">
          <tds-icon
            name="cross"
            size="12px"
            class="pb-1 pr-1"
            style="color: #56657a"
          ></tds-icon
          ><span class="tds-detail-02 text-[#56657A]">Cancel</span>
        </div>
      </button>
      <tds-button
        type="submit"
        variant="primary"
        size="sm"
        text="Save"
      ></tds-button>
    </div>
  </form>
</div>

import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';

@Component({
  selector: 'app-additional-information',
  standalone: false,
  templateUrl: './additional-information.component.html',
  styleUrl: './additional-information.component.css',
})
export class AdditionalInformationComponent {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() companyId: string = '';
  @Input() infoBlockId:string ='';
  additionalInformationForm =
    inject(CompanyFormService).form.controls.CompanyAdministrativeIssues;
  textFocus = false;
  constructor(
    public utilService: UtilsService,
    public appService: AppService
  ) {}

  get AdditionalInformation() {
    return this.additionalInformationForm.get('Administrative_issues');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.additionalInformationForm.patchValue({
        Administrative_issues:
          this.companyData?.CompanyAdministrativeIssues?.Administrative_issues,
        Company_id: this.companyData?.Id,
      });
    } else {
      this.additionalInformationForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      this.additionalInformationForm.patchValue({
        Administrative_issues:
          this.companyData?.CompanyAdministrativeIssues?.Administrative_issues,
        Company_id: this.companyData?.Id,
      });
      if (!this.companyData.CompanyAdministrativeIssues) {
        this.companyData.CompanyAdministrativeIssues = {};
      }
    }
  }

  saveDraft() {
    if (!this.companyData.CompanyAdministrativeIssues) {
      this.companyData.CompanyAdministrativeIssues = {};
    }
    this.companyData.CompanyAdministrativeIssues.Administrative_issues =
      this.additionalInformationForm.get('Administrative_issues')?.value;
    if (this.additionalInformationForm.dirty) {
      this.companyData.CompanyAdministrativeIssues.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

<form [formGroup]="ContractedPartnerInfoForm" (change)="saveDraft()">
  @if(this.mode == 'print'){
  <div class="flex flex-row-" style="padding: 12px">
    <div>
      <div class="grid grid-cols-1">
        <div class="flex">
          <input type="checkbox" [checked]="ContractedPartnerInfoForm.value.Is_ContractedPartner" />
          <label style="font-size: 14px;">{{IsContractedPartnerLabel}}</label><br>
        </div>
      </div>
    </div>
  </div>
  }@else {
    <div class="flex p-5">
      <tds-checkbox checked="{{
          ContractedPartnerInfoForm.value.Is_ContractedPartner || false
        }}" (tdsChange)="handleChecked($event)" class="pt-1">
        <div slot="label">{{IsContractedPartnerLabel}}</div>
      </tds-checkbox>
      <tds-tooltip placement="right" text="{{ IsContractedPartnerPopupText }}" selector="#IsContractedPartner-tooltip" offset-distance="8"
        offset-skidding="0" mouse-over-tooltip="true" trigger="hover">
      </tds-tooltip>
      <tds-icon name="info" class="pt-2.5 pl-1 text-[#B0B7C4]" id="IsContractedPartner-tooltip" size="15"></tds-icon>
    </div>
  }
</form>
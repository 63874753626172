<form [formGroup]="bankInfoForm" (change)="saveDraft()">
  <div
    class="flex-col flex-wrap w-full md:w-1/3 sm:w-1/3 xl:w-1/3 2xl:w-1/3 inline-flex"
  >
    <div class="inputField">
      <div class="flex justify-between">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('Bankname',infoBlockId)}}</p>
      <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('Bankname',infoBlockId)}}"
            selector="#Bankname-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="Bankname-tooltip"
            size="15"
          ></tds-icon>
    </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="bankName"
          (tdsFocus)="nameFocus = true"
          (tdsBlur)="nameFocus = true"
          max-length="100"
          [state]="
            BankName?.invalid &&
            (BankName?.dirty || BankName?.touched || nameFocus)
              ? 'error'
              : 'success'
          "
          [helper]="utilService.getErrorMsgs(BankName, 'Bank Name', nameFocus)"
        >
        </tds-text-field>
      </div>
    </div>

    <div class="inputField inp-bottom">
      <div class="flex justify-between">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('Bankcity',infoBlockId)}}</p>
      <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('Bankcity',infoBlockId)}}"
            selector="#Bankcity-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="Bankcity-tooltip"
            size="15"
          ></tds-icon>
    </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="bankCity"
          (tdsFocus)="nameFocus = true"
          (tdsBlur)="nameFocus = true"
          max-length="100"
          [state]="
            BankCity?.invalid &&
            (BankCity?.dirty || BankCity?.touched || nameFocus)
              ? 'error'
              : 'success'
          "
          [helper]="utilService.getErrorMsgs(BankCity, 'Bank City', cityFocus)"
        >
        </tds-text-field>
      </div>
    </div>
  </div>
  <div
    class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/3 xl:w-1/3 2xl:w-1/3 inline-flex"
  >
    <div class="inputField">
      <div class="flex justify-between">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('Bankaccountno',infoBlockId)}}</p>
      <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('Bankaccountno',infoBlockId)}}"
            selector="#Bankaccountno-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="Bankaccountno-tooltip"
            size="15"
          ></tds-icon>
    </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="bankAccountNo"
          (tdsFocus)="accountNoFocus = true"
          (tdsBlur)="accountNoFocus = true"
          max-length="100"
          [state]="
            BankAccountNo?.invalid &&
            (BankAccountNo?.dirty || BankAccountNo?.touched || accountNoFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              BankAccountNo,
              'Bank Account No.',
              accountNoFocus
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="inputField inp-bottom">
      <div class="flex justify-between">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('Swiftbiccode',infoBlockId)}}</p>
      <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('Swiftbiccode',infoBlockId)}}"
            selector="#Swiftbiccode-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="Swiftbiccode-tooltip"
            size="15"
          ></tds-icon>
    </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="swiftBiCode"
          (tdsFocus)="biCodeFocus = true"
          (tdsBlur)="biCodeFocus = true"
          max-length="50"
          [state]="
            BankSwiftBiCode?.invalid &&
            (BankSwiftBiCode?.dirty || BankSwiftBiCode?.touched || biCodeFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              BankSwiftBiCode,
              'Swift Bi Code',
              biCodeFocus
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
  </div>
  <div
    class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/3 xl:w-1/3 2xl:w-1/3 inline-flex"
  >
    <div class="inputField">
      <div class="flex justify-between">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('Iban',infoBlockId)}}</p>
      <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('Iban',infoBlockId)}}"
            selector="#Iban-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="Iban-tooltip"
            size="15"
          ></tds-icon>
    </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="iban"
          (tdsFocus)="ibanFocus = true"
          (tdsBlur)="ibanFocus = true"
          max-length="50"
          [state]="
            BankIban?.invalid &&
            (BankIban?.dirty || BankIban?.touched || ibanFocus)
              ? 'error'
              : 'success'
          "
          [helper]="utilService.getErrorMsgs(BankIban, 'IBAN', ibanFocus)"
        >
        </tds-text-field>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="CompanyWorkshopCapabilitiesForm" (change)="saveDraft()">
  <div class="flex flex-col" style="padding: 12px">
    <div>
      <p class="my-0 py-0 pb-3">
        LABEL MISSING : WorkshopCapabilities + workstationsbays
      </p>
      <div class="flex flex-row">
        <div>
          <p class="pt-2">
            LABEL MISSING : WorkshopCapabilities + workstation_large
          </p>
          <p class="pt-2">
            LABEL MISSING : WorkshopCapabilities + WorkstationIntermediate
          </p>
          <p class="pt-2">
            LABEL MISSING : WorkshopCapabilities + WorkstationBasic
          </p>
        </div>
        <div>
          <div class="flex flex-row">
            <div class="pl-3 w-20">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="workstation_large"
                (tdsBlur)="workstation_largeFocus = true"
                (tdsFocus)="workstation_largeFocus = true"
                [helper]="
                  workstation_large?.hasError('negativeNumber') &&
                  workstation_largeFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  workstation_largeFocus && workstation_large?.invalid
                    ? 'error'
                    : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING : WorkshopCapabilities + workstation_large"
                selector="#workstation_large"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="workstation_large"
                size="15"
              ></tds-icon>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="pl-3 w-20">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="workstation_intermediate"
                (tdsBlur)="workstation_intermediateFocus = true"
                (tdsFocus)="workstation_intermediateFocus = true"
                [helper]="
                  workstation_intermediate?.hasError('negativeNumber') &&
                  workstation_intermediateFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  workstation_intermediateFocus &&
                  workstation_intermediate?.invalid
                    ? 'error'
                    : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING:WorkshopCapabilities + WorkstationIntermediate"
                selector="#workstation_intermediate"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="workstation_intermediate"
                size="15"
              ></tds-icon>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="pl-3 w-20">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="workstation_basic"
                (tdsBlur)="workstation_basicFocus = true"
                (tdsFocus)="workstation_basicFocus = true"
                [helper]="
                  workstation_basic?.hasError('negativeNumber') &&
                  workstation_basicFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  workstation_basicFocus && workstation_basic?.invalid
                    ? 'error'
                    : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING : WorkshopCapabilities + WorkstationBasic"
                selector="#workstation_basic"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="workstation_basic"
                size="15"
              ></tds-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p class="my-0 py-3">
        LABEL MISSING : WorkshopCapabilities + specificpurposes
      </p>
      <div class="flex flex-row">
        <div>
          <p class="pt-2">
            LABEL MISSING : WorkshopCapabilities + accident_repair
          </p>
          <p class="pt-2">LABEL MISSING : WorkshopCapabilities + diagnosis</p>
          <p class="pt-2">
            LABEL MISSING : WorkshopCapabilities + washing_other
          </p>
        </div>
        <div>
          <div class="flex flex-row">
            <div class="w-20" style="padding-left: 4.5rem">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="accident_repair"
                (tdsBlur)="accident_repairFocus = true"
                (tdsFocus)="accident_repairFocus = true"
                [helper]="
                  accident_repair?.hasError('negativeNumber') &&
                  accident_repairFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  accident_repairFocus && accident_repair?.invalid
                    ? 'error'
                    : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING : WorkshopCapabilities + accident_repair"
                selector="#accident_repair"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="accident_repair"
                size="15"
              ></tds-icon>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="w-20" style="padding-left: 4.5rem">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="diagnosis"
                (tdsBlur)="diagnosisFocus = true"
                (tdsFocus)="diagnosisFocus = true"
                [helper]="
                  diagnosis?.hasError('negativeNumber') && diagnosisFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  diagnosisFocus && diagnosis?.invalid ? 'error' : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING : WorkshopCapabilities + diagnosis"
                selector="#diagnosis"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="diagnosis"
                size="15"
              ></tds-icon>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="w-20" style="padding-left: 4.5rem">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="washing_other"
                (tdsBlur)="washing_otherFocus = true"
                (tdsFocus)="washing_otherFocus = true"
                [helper]="
                  washing_other?.hasError('negativeNumber') &&
                  washing_otherFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  washing_otherFocus && washing_other?.invalid
                    ? 'error'
                    : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING : WorkshopCapabilities + washing_other"
                selector="#washing_other"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="washing_other"
                size="15"
              ></tds-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; margin: 15px 0">
      <tds-divider orientation="horizontal"></tds-divider>
    </div>

    <div>
      <div class="flex flex-row">
        <div>
          <p class="pt-2">LABEL MISSING : WorkshopCapabilities + Ownership</p>
          <p *ngIf="showDate" class="pt-2">
            LABEL MISSING : WorkshopCapabilities + rent_exp_date
          </p>
        </div>
        <div>
          <div class="flex flex-row">
            <div class="w-40" style="padding-left: 5rem">
              <tds-dropdown
                ngDefaultControl
                size="sm"
                open-direction="down"
                [defaultValue]="
                  CompanyWorkshopCapabilitiesForm.value.ownership ||
                  'not_specified'
                "
                (tdsChange)="handleDropdown($event)"
              >
                <tds-dropdown-option value="not_specified"
                  >Not specified</tds-dropdown-option
                >
                <tds-dropdown-option value="owned">Owned</tds-dropdown-option>
                <tds-dropdown-option value="rented">Rented</tds-dropdown-option>
              </tds-dropdown>
            </div>
          </div>

          <div class="flex flex-row" *ngIf="showDate">
            <div class="w-30 pt-3" style="padding-left: 5rem">
              <tds-datetime
                ngDefaultControl
                id="datetime"
                mode-variant="primary"
                type="date"
                size="sm"
                [value]="
                  CompanyWorkshopCapabilitiesForm.value.rent_exp_date
                    ? (CompanyWorkshopCapabilitiesForm.value.rent_exp_date
                      | date : 'YYYY-MM-dd')
                    : ''
                "
                (tdsChange)="handleDatePicker($event)"
                (tdsBlur)="focus = true"
                (tdsFocus)="focus = true"
                [helper]="
                  rentExpiryDate?.hasError('required') && focus
                    ? 'Date must be set if rented'
                    : ''
                "
                [state]="focus && rentExpiryDate?.invalid ? 'error' : 'success'"
              ></tds-datetime>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; margin: 15px 0">
      <tds-divider orientation="horizontal"></tds-divider>
    </div>

    <div>
      <p class="my-0 py-3">
        LABEL MISSING : WorkshopCapabilities + catchmentarea
      </p>
      <div class="flex flex-row">
        <div>
          <p class="pt-2">LABEL MISSING : WorkshopCapabilities + drive_time</p>
        </div>
        <div>
          <div class="flex flex-row">
            <div class="w-20" style="padding-left: 6.5rem">
              <tds-text-field
                ngDefaultControl
                type="number"
                size="sm"
                state="default"
                no-min-width
                formControlName="drive_time"
                (tdsBlur)="drive_timeFocus = true"
                (tdsFocus)="drive_timeFocus = true"
                [helper]="
                  drive_time?.hasError('negativeNumber') && drive_timeFocus
                    ? 'Field must be digits only'
                    : ''
                "
                [state]="
                  drive_timeFocus && drive_time?.invalid ? 'error' : 'success'
                "
              ></tds-text-field>
            </div>
            <div>
              <tds-tooltip
                placement="right"
                text="LABEL MISSING : WorkshopCapabilities + drive_time"
                selector="#drive_time"
                offset-distance="8"
                offset-skidding="0"
                mouse-over-tooltip="true"
                trigger="hover"
              >
              </tds-tooltip>
              <tds-icon
                name="info"
                class="mt-3 text-[#B0B7C4]"
                id="drive_time"
                size="15"
              ></tds-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

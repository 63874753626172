<div class="h-52 grid">
  <div class="flex flex-row">
    <div class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 inline-flex">
      <p class="font-bold text-3xl inline-block align-text-bottom mt-5 ml-10" id="heading">
        Welcome to the SIS update
      </p>
    </div>
    <div class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 inline-flex mt-2">
      <div *ngIf="isAdmin" class="flex flex-wrap">
        <div class="flex-col flex-wrap md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 inline-flex mt-2"
          style="text-align: right;">
          <p class="inline-block mr-4">Filter By Country</p>
        </div>
        <div class="w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 flex flex-wrap inline-flex mt-3">
          <tds-dropdown ngDefaultControl name="dropdown" size="sm" open-direction="down" id="Country"
            [defaultValue]="-1" (tdsChange)="handleGlobalCountryChange($event)" filter="true">
            <tds-dropdown-option class="dropdown-item" [value]="-1">
              Select Country
            </tds-dropdown-option>
            <tds-dropdown-option *ngFor="let country of countryList; let i = index" [value]="country.Code">
              {{ country.Name }}
            </tds-dropdown-option>
          </tds-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="card-container">
      <tds-card *ngFor="let card of cards" header="" image-placement="below-header" part="card">
        <div slot="header" class="flex flex-wrap">
          <div class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 inline-flex">
            <p class="font-bold mb-0">
              <a href="/company/{{ card.Category.Name }}">{{
                card.Category.Title
                }}</a>
            </p>
          </div>
          <div class="w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 flex flex-wrap inline-flex">
            <tds-dropdown ngDefaultControl name="dropdown" size="sm" open-direction="down" id="Country"
              [defaultValue]="-1" (tdsChange)="handleCardCountryChange($event, card)" filter="true">
              <tds-dropdown-option class="dropdown-item" [value]="-1">
                Select Country
              </tds-dropdown-option>
              <tds-dropdown-option *ngFor="let country of card.CountryDetails; let i = index" [value]="country.Code">
                {{ country.Name }}
              </tds-dropdown-option>
            </tds-dropdown>
          </div>
        </div>
        <div slot="body">
          <div class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 inline-flex">
            @if(!card.SelectedCountry){
            <p class="mb-0">Total</p>
            <p class="font-bold text-2xl mt-0">{{ card.Total || 0}}</p>
            }@else{
            <p class="mb-0">{{ card.SelectedCountry.Name }}</p>
            <p class="font-bold text-2xl mt-0">
              {{ card.SelectedCountry.Companies.length }}
            </p>
            }
          </div>
          @if(card.Category.Name === "DealerWorkshops"){
          <div class="flex-col flex-wrap w-full md:w-1/2 sm:w-1/2 xl:w-1/2 2xl:w-1/2 inline-flex">
            <p class="mb-0">Non Certified</p>
            <p class="font-bold text-2xl mt-0" style="color: red">
              {{ getNonCertifiedTotal(card) || '0' }}
            </p>
          </div>
          }
        </div>
      </tds-card>
    </div>

  </div>
</div>
import { Injectable } from '@angular/core';
import response from '../../mock/response_1722586370043.json';
import { of, retry } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactTypeService {
  contactType: any;
  constructor(private http: HttpClient) {}

  getContactTypeList() {
    return this.http
      .get(
        environment.baseUrl +
          environment.administrationServices.contactTypeService
      )
      .pipe(retry(3));
  }
  getEditContact(contact: any) {
    this.contactType = contact;
  }

  updateContactData(request: any) {
    return this.http.put(
      environment.baseUrl +
        environment.administrationServices.contactTypeService +
        '/' +
        request.Code,
      request
    );
  }

  addContactDate(editForm: any) {
    return this.http.post(
      environment.baseUrl +
        environment.administrationServices.contactTypeService,
      editForm.value
    );
  }
  
  getDisplayOrderUrl() {
    return (
      environment.baseUrl +
      environment.administrationServices.contactTypeService
    );
  }
}

<form
  [formGroup]="addressForm"
  (change)="saveDraft()"
  (blur)="saveDraft()"
  class="mt-5 ml-4 pl-1"
>
  @for(item of controlArr;track $index){ @if(item.maxFieldLengthArr &&
  item.maxFieldLengthArr.length >1){ @for( fields of item.maxFieldLengthArr;
  track $index; let idx = $index){

  <p class="tds-headline-05 tds-text-blue-500 hi">
    {{ item.Name + " Address" }}
    <tds-tooltip
      placement="right"
      text="{{ getPopupHelpText('Type', infoBlockId) }}"
      selector="#address-tooltip"
      offset-distance="8"
      offset-skidding="0"
      mouse-over-tooltip="true"
      trigger="hover"
    >
    </tds-tooltip>
    <tds-icon
      name="info"
      class="mt-1 text-[#B0B7C4]"
      id="address-tooltip"
      size="15"
    ></tds-icon>
  </p>
  <div class="flex-wrap flex-col w-full md:w-2/5 lg:w-2/5 xl:w-2/5 inline-flex">
    <div class="mx-2 inputField" *ngIf="item.companyShow">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("CompanyName", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('CompanyName', infoBlockId) }}"
          selector="{{ '#CompanyName-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'CompanyName-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          [formControlName]="getFormName(item.Code, idx, '_company_name')"
          (tdsFocus)="setFocusElement(item, 'CompanynameFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'CompanynameFocus', idx)"
          placeholder="Enter company Name"
          [state]="
            getCurrentControl(item.Code, idx, '_company_name')?.invalid &&
            (getCurrentControl(item.Code, idx, '_company_name')?.dirty ||
              getCurrentControl(item.Code, idx, '_company_name')?.touched ||
              getFocusElement(item, 'CompanynameFocus', idx))
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_company_name'),
              'Company Name',
              getFocusElement(item, 'CompanynameFocus', idx),
              0,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="m-2 inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("StreetAddress", infoBlockId)
          }}<span
            class="text-red-600"
            *ngIf="isRequired(item.Code, idx, '_streetAddress')"
            >*</span
          >
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('StreetAddress', infoBlockId) }}"
          selector="{{ '#StreetAddress-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'StreetAddress-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>

      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          [formControlName]="getFormName(item.Code, idx, '_streetAddress')"
          placeholder="Enter street address"
          (tdsFocus)="setFocusElement(item, 'StreetaddressFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'StreetaddressFocus', idx)"
          [state]="
            getCurrentControl(item.Code, idx, '_streetAddress')?.invalid &&
            (getCurrentControl(item.Code, idx, '_streetAddress')?.dirty ||
              getCurrentControl(item.Code, idx, '_streetAddress')?.touched ||
              getFocusElement(item, 'StreetaddressFocus', idx))
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_streetAddress'),
              'Street Address',
              getFocusElement(item, 'StreetaddressFocus', idx),
              0,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="m-2 inputField">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("Streetaddress", infoBlockId) }}
        {{ getLabelText("Local", infoBlockId) }}
      </p>

      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          [formControlName]="
            getFormName(item.Code, idx, '_streetAddress_local')
          "
          (tdsFocus)="setFocusElement(item, 'StreetaddressLocalFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'StreetaddressLocalFocus', idx)"
          placeholder="Local language"
          [state]="
            getCurrentControl(item.Code, idx, '_streetAddress_local')
              ?.invalid &&
            (getCurrentControl(item.Code, idx, '_streetAddress_local')?.dirty ||
              getCurrentControl(item.Code, idx, '_streetAddress_local')
                ?.touched ||
              getFocusElement(item, 'StreetaddressLocalFocus', idx))
              ? 'error'
              : 'success'
          "
          [disabled]="
            getCurrentControl(item.Code, idx, '_postal_city')?.value == '' ||
            getCurrentControl(item.Code, idx, '_streetAddress')?.value == ''
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_streetAddress_local'),
              'Street Address Local',
              getFocusElement(item, 'StreetaddressLocalFocus', idx),
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="m-2 inputField">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">Comments</p>

      <div>
        <tds-textarea
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="400"
          [formControlName]="getFormName(item.Code, idx, '_comment')"
          (tdsFocus)="setFocusElement(item, 'commentFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'commentFocus', idx)"
          placeholder="Comment if any"
          [state]="
            getCurrentControl(item.Code, idx, '_comment')?.invalid &&
            (getCurrentControl(item.Code, idx, '_comment')?.dirty ||
              getCurrentControl(item.Code, idx, '_comment')?.touched ||
              getFocusElement(item, 'commentFocus', idx))
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_comment'),
              'Comments',
              getFocusElement(item, 'commentFocus', idx),
              3,
              item.Message
            )
          "
        >
        </tds-textarea>
      </div>
    </div>
  </div>
  <div class="inline-flex flex-wrap flex-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
    <div class="m-2 inputField" *ngIf="item.companyShow">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("Country", infoBlockId) }}
      </p>
      <div>
        <tds-dropdown
          ngDefaultControl
          name="dropdown"
          size="sm"
          open-direction="down"
          [formControlName]="getFormName(item.Code, idx, '_country')"
          [defaultValue]="getCurrentControl(item.Code, 0, '_country')?.value"
          (tdsFocus)="setFocusElement(item, 'countryFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'countryFocus', idx)"
          placeholder="{{
            getLabelText('CountryDropdownDefault', infoBlockId)
          }}"
          [state]="
            getCurrentControl(item.Code, idx, '_country')?.invalid &&
            (getCurrentControl(item.Code, idx, '_country')?.dirty ||
              getCurrentControl(item.Code, idx, '_country')?.touched ||
              getFocusElement(item, 'countryFocus', idx))
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_country'),
              'Country',
              getFocusElement(item, 'countryFocus', idx),
              3,
              item.Message
            )
          "
        >
          @for (country of countryList; track $index) {
          <tds-dropdown-option value="{{ country.Code }}">{{
            country.Name
          }}</tds-dropdown-option>
          }
        </tds-dropdown>
      </div>
    </div>
    <div class="m-2 inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Postal city", infoBlockId)
          }}<span
            class="text-red-600"
            *ngIf="isRequired(item.Code, idx, '_postal_city')"
            >*</span
          >
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Postal city', infoBlockId) }}"
          selector="{{ '#PostalCity-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'PostalCity-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          [formControlName]="getFormName(item.Code, idx, '_postal_city')"
          (tdsFocus)="setFocusElement(item, 'postalCityFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'postalCityFocus', idx)"
          placeholder="Enter postal city"
          [state]="
            getCurrentControl(item.Code, idx, '_postal_city')?.invalid &&
            (getCurrentControl(item.Code, idx, '_postal_city')?.dirty ||
              getCurrentControl(item.Code, idx, '_postal_city')?.touched ||
              getFocusElement(item, 'postalCityFocus', idx))
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_postal_city'),
              'Postal City',
              getFocusElement(item, 'postalCityFocus', idx),
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="m-2 inputField">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("Postal city", infoBlockId) }}
        {{ getLabelText("Local", infoBlockId) }}
      </p>

      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          [formControlName]="getFormName(item.Code, idx, '_postal_city_local')"
          (tdsFocus)="setFocusElement(item, 'postalCityLocalFocus', idx)"
          (tdsBlur)="setFocusElement(item, 'postalCityLocalFocus', idx)"
          placeholder="Local language"
          [disabled]="
            getCurrentControl(item.Code, idx, '_postal_city')?.value == '' ||
            getCurrentControl(item.Code, idx, '_streetAddress')?.value == ''
          "
          [state]="
            getCurrentControl(item.Code, idx, '_postal_city_local')?.invalid &&
            (getCurrentControl(item.Code, idx, '_postal_city_local')?.dirty ||
              getCurrentControl(item.Code, idx, '_postal_city_local')
                ?.touched ||
              getFocusElement(item, 'postalCityLocalFocus', idx))
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_postal_city_local'),
              'Postal City local',
              getFocusElement(item, 'postalCityLocalFocus', idx),
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
  </div>
  <div class="inline-flex flex-wrap flex-col w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
    <div class="m-2 inputField overflow-hidden">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Postal code", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Postal code', infoBlockId) }}"
          selector="{{ '#PostalCode-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'PostalCode-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          [formControlName]="getFormName(item.Code, idx, '_postal_code')"
          placeholder="Enter postal code"
          (tdsFocus)="item.postCodeFocus = true"
          (tdsBlur)="item.postCodeFocus = true"
          [state]="
            getCurrentControl(item.Code, idx, '_postal_code')?.invalid &&
            (getCurrentControl(item.Code, idx, '_postal_code')?.dirty ||
              getCurrentControl(item.Code, idx, '_postal_code')?.touched ||
              item.postCodeFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx, '_postal_code'),
              'Postal Code',
              item.postCodeFocus,
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
  </div>
  } } @else {
  <p class="tds-headline-05 tds-text-blue-500">
    {{ item.Name + " Address" }}
    <tds-tooltip
      placement="right"
      text="{{ getPopupHelpText('Type', infoBlockId) }}"
      selector="{{ '#address-' + $index + 'tooltip' }}"
      offset-distance="8"
      offset-skidding="0"
      mouse-over-tooltip="true"
      trigger="hover"
    >
    </tds-tooltip>
    <tds-icon
      name="info"
      class="text-[#B0B7C4]"
      id="{{ 'address-' + $index + 'tooltip' }}"
      size="15"
    ></tds-icon>
  </p>
  <div class="flex-wrap flex-col w-full md:w-2/5 lg:w-2/5 xl:w-2/5 inline-flex">
    <div class="mx-2 mb-1 h-24 inputField" *ngIf="item.companyShow">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-1 mb-0 pb-0">
          {{ getLabelText("CompanyName", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('CompanyName', infoBlockId) }}"
          selector="{{ '#CompanyName-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-1 text-[#B0B7C4]"
          id="{{ 'CompanyName-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          formControlName="{{ item.Code + '_company_name' }}"
          (tdsFocus)="item.CompanynameFocus = true"
          (tdsBlur)="item.CompanynameFocus = true"
          placeholder="Enter company Name"
          [state]="
            getCurrentControl(item.Code, 0, '_company_name')?.invalid &&
            (getCurrentControl(item.Code, 0, '_company_name')?.dirty ||
              getCurrentControl(item.Code, 0, '_company_name')?.touched ||
              item?.CompanynameFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_company_name'),
              'Company Name',
              item.CompanynameFocus,
              0,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="mx-2 mb-1 inputField h-24">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-1 mb-0 pb-0">
          {{ getLabelText("StreetAddress", infoBlockId)
          }}<span
            class="text-red-600"
            *ngIf="isRequired(item.Code, 0, '_streetAddress')"
            >*</span
          >
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('StreetAddress', infoBlockId) }}"
          selector="{{ '#StreetAddress-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-1 text-[#B0B7C4]"
          id="{{ 'StreetAddress-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          formControlName="{{ item.Code + '_streetAddress' }}"
          placeholder="Enter street address"
          (tdsFocus)="item.StreetaddressFocus = true"
          (tdsBlur)="item.StreetaddressFocus = true"
          [state]="
            getCurrentControl(item.Code, 0, '_streetAddress')?.invalid &&
            (getCurrentControl(item.Code, 0, '_streetAddress')?.dirty ||
              getCurrentControl(item.Code, 0, '_streetAddress')?.touched ||
              item.StreetaddressFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_streetAddress'),
              'Street Address',
              item?.StreetaddressFocus,
              0,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="mx-2 mb-1 h-24 inputField">
      <p class="tds-headline-06 mt-1 mb-0 pb-0">
        {{ getLabelText("Streetaddress", infoBlockId) }}
        {{ getLabelText("Local", infoBlockId) }}
      </p>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          formControlName="{{ item.Code + '_streetAddress_local' }}"
          (tdsFocus)="item.StreetaddressLocalFocus = true"
          (tdsBlur)="item.StreetaddressLocalFocus = true"
          placeholder="Local language"
          [disabled]="
            getCurrentControl(item.Code, 0, '_postal_city')?.value == '' ||
            getCurrentControl(item.Code, 0, '_streetAddress')?.value == ''
          "
          [state]="
            getCurrentControl(item.Code, 0, '_streetAddress_local')?.invalid &&
            (getCurrentControl(item.Code, 0, '_streetAddress_local')?.dirty ||
              getCurrentControl(item.Code, 0, '_streetAddress_local')
                ?.touched ||
              item.StreetaddressLocalFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_streetAddress_local'),
              'Street Address Local',
              item.StreetaddressLocalFocus,
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="mx-2 inputField">
      <p class="tds-headline-06 mt-1 mb-0 pb-0">
        {{ getLabelText("Comment", infoBlockId) }}
      </p>

      <div>
        <tds-textarea
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="400"
          formControlName="{{ item.Code + '_comment' }}"
          (tdsFocus)="item.commentFocus = true"
          (tdsBlur)="item.commentFocus = true"
          placeholder="Comment if any"
          [state]="
            getCurrentControl(item.Code, 0, '_comment')?.invalid &&
            (getCurrentControl(item.Code, 0, '_comment')?.dirty ||
              getCurrentControl(item.Code, 0, '_comment')?.touched ||
              item.commentFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_comment'),
              'Comments',
              item.commentFocus,
              3,
              item.Message
            )
          "
        >
        </tds-textarea>
      </div>
    </div>
  </div>
  <div class="inline-flex flex-wrap flex-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
    <div class="mx-2 mb-1 h-24 inputField" *ngIf="item.companyShow">
      <p class="tds-headline-06 mt-1 mb-0 pb-0">
        {{ getLabelText("Country", infoBlockId) }}
      </p>

      <div>
        <tds-dropdown
          ngDefaultControl
          name="dropdown"
          size="sm"
          open-direction="down"
          formControlName="{{ item.Code + '_country' }}"
          [defaultValue]="getCurrentControl(item.Code, 0, '_country')?.value"
          (tdsFocus)="item.CountryFocus = true"
          (tdsBlur)="item.CountryFocus = true"
          (tdsChange)="
            changeCountry(
              $event,
              item.Code + '_country',
              this.companyData?.CompanyAddress[$index].Country
            )
          "
          placeholder="{{
            getLabelText('CountryDropdownDefault', infoBlockId)
          }}"
          [state]="
            getCurrentControl(item.Code, 0, '_country')?.invalid &&
            (getCurrentControl(item.Code, 0, '_country')?.dirty ||
              getCurrentControl(item.Code, 0, '_country')?.touched ||
              item.CountryFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_country'),
              'Country',
              item.CountryFocus,
              3,
              item.Message
            )
          "
        >
          @for (country of countryList; track $index) {
          <tds-dropdown-option value="{{ country.Code }}">{{
            country.Name
          }}</tds-dropdown-option>
          }
        </tds-dropdown>
      </div>
    </div>
    <div class="mx-2 mb-1 h-24 inputField">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-1 mb-0 pb-0">
          {{ getLabelText("Postal city", infoBlockId)
          }}<span
            class="text-red-600"
            *ngIf="isRequired(item.Code, 0, '_postal_city')"
            >*</span
          >
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Postal city', infoBlockId) }}"
          selector="{{ '#PostalCity-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-1 text-[#B0B7C4]"
          id="{{ 'PostalCity-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          formControlName="{{ item.Code + '_postal_city' }}"
          (tdsFocus)="item.postalCityFocus = true"
          (tdsBlur)="item.postalCityFocus = true"
          placeholder="Enter postal city"
          [state]="
            getCurrentControl(item.Code, 0, '_postal_city')?.invalid &&
            (getCurrentControl(item.Code, 0, '_postal_city')?.dirty ||
              getCurrentControl(item.Code, 0, '_postal_city')?.touched ||
              item.postalCityFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_postal_city'),
              'Postal City',
              item.postalCityFocus,
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
    <div class="mx-2 inputField">
      <p class="tds-headline-06 mt-1 mb-0 pb-0">
        {{ getLabelText("Postal city", infoBlockId) }}
        {{ getLabelText("Local", infoBlockId) }}
      </p>

      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          max-length="255"
          formControlName="{{ item.Code + '_postal_city_local' }}"
          (tdsFocus)="item.postalCityLocalFocus = true"
          (tdsBlur)="item.postalCityLocalFocus = true"
          placeholder="Local language"
          [disabled]="
            getCurrentControl(item.Code, 0, '_postal_city')?.value == '' ||
            getCurrentControl(item.Code, 0, '_streetAddress')?.value == ''
          "
          [state]="
            getCurrentControl(item.Code, 0, '_postal_city_local')?.invalid &&
            (getCurrentControl(item.Code, 0, '_postal_city_local')?.dirty ||
              getCurrentControl(item.Code, 0, '_postal_city_local')?.touched ||
              item.postalCityLocalFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_postal_city_local'),
              'Postal City local',
              item.postalCityLocalFocus,
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
  </div>
  <div class="inline-flex flex-wrap flex-col w-full md:w-1/5 lg:w-1/5 xl:w-1/5">
    <div class="mx-2 mb-1 h-24 inputField overflow-hidden">
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ getLabelText("Postal code", infoBlockId) }}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('Postal code', infoBlockId) }}"
          selector="{{ '#PostalCode-' + $index + 'tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'PostalCode-' + $index + 'tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          formControlName="{{ item.Code + '_postal_code' }}"
          placeholder="Enter postal code"
          (tdsFocus)="item.postCodeFocus = true"
          (tdsBlur)="item.postCodeFocus = true"
          [state]="
            getCurrentControl(item.Code, 0, '_postal_code')?.invalid &&
            (getCurrentControl(item.Code, 0, '_postal_code')?.dirty ||
              getCurrentControl(item.Code, 0, '_postal_code')?.touched ||
              item.postCodeFocus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, 0, '_postal_code'),
              'Postal Code',
              item.postCodeFocus,
              3,
              item.Message
            )
          "
        >
        </tds-text-field>
      </div>
    </div>
  </div>

  }}
</form>

@if(countryHasScaniaAssistance) {
<app-assistance-role
  [mode]="mode"
  [companyData]="companyData"
  [companyId]="companyId"
></app-assistance-role>
<app-assistance-notes
  [mode]="mode"
  [companyData]="companyData"
  [companyId]="companyId"
></app-assistance-notes>
<app-assistance-prodservice
  [mode]="mode"
  [companyData]="companyData"
  [companyId]="companyId"
></app-assistance-prodservice>

}@else {
<div class="p-4">Scania Assistance is not applicable for this country</div>
}

<form [formGroup]="distributorImportForm" (change)="saveDraft()">
  @if(this.mode == 'print'){
  <div class="flex flex-row" style="padding: 10px;overflow: visible">
    <div>
      <div class="grid grid-cols-1">
        @for(imports of distributorImports; track $index ;let infoIndex =
        $index){
        @if(isavilabel(imports.Code)){
        <div class="flex">
          <input type="checkbox" checked name="{{ imports.Id }}" value="{{ imports.Id }}" />
          <label style="font-size: 14px;" for="{ imports.Id }}">{{ imports.Title }}</label><br>
        </div>
        }
        }
      </div>
    </div>
  </div>
  }@else {
  <div class="flex flex-row" style="padding: 10px; max-height: 350px; overflow-y: auto">
    <div>
      <div>
        @for(imports of distributorImports; track $index ;let infoIndex =
        $index){
        <div class="flex justify-between">
          <tds-checkbox value="{{ imports.Code }}-{{ '' }}" checkbox-id="{{ imports.Code }}"
            checked="{{ isavilabel(imports.Code) }}" (tdsChange)="handleChecked(imports.Code)" class="pt-1">
            <div slot="label">{{ imports.Title }}</div>
          </tds-checkbox>
        </div>
        }
      </div>
    </div>
  </div>
  }
</form>
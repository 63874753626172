<form [formGroup]="productAndServicesForm" (change)="saveDraft()">
  @if(this.mode == 'print'){
  <div class="flex flex-row" style="padding: 10px;overflow: visible">
    <div>
      <div class="grid grid-cols-1">
        @for(titles of dealerProductAndServices; track $index ;let infoIndex =
        $index){
          @if(isavilabel(titles.Id)){
          <div class="flex">
            <input type="checkbox" checked name="{{ titles.Id }}" value="{{ titles.Id }}" />
            <label style="font-size: 14px;" for="{ titles.Id }}">{{ titles.Title }}</label><br>
          </div>
          }
        }
      </div>
    </div>
  </div>
  }@else {
  <div class=" flex flex-row" style="padding: 10px; max-height: 350px; overflow-y: auto">
    <div>
      <div class="grid grid-cols-1">
        @for(titles of dealerProductAndServices; track $index ;let infoIndex =
        $index){
        <div class="flex justify-between">
          <tds-checkbox value="{{ titles.Id }}-{{ '' }}" checkbox-id="{{ titles.Id }}"
            checked="{{ isavilabel(titles.Id) }}" class="pt-1" (tdsChange)="handleChecked(titles.Id)">
            <div slot="label">{{ titles.Title }}</div>
          </tds-checkbox>
        </div>
        }
      </div>
    </div>
  </div>
  }
</form>
import { Component } from '@angular/core';
import { AuthService, IdentityClaims } from '../../services/auth.service';
import { CommonModule } from '@angular/common';
import { SubjectService } from '../../services/subject.service';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class AuthCallbackComponent {

    constructor(public authService: AuthService) {
        //comment below line to view auth callback result
        this.authService.redirectOnCallback();
    }

    login() {
        this.authService.login();
    }

    logout() {
        this.authService.logout();
    }

    get identityClaims(): IdentityClaims | null {
        return this.authService.identityClaims;
    }

    get token(): string | null {
        return this.authService.getAuthorizationToken();
    }
}
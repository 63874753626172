import { Component } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-no-access',
  standalone: true,
  imports: [TegelModule],
  providers: [SharedService],
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.css',
})
export class NoAccessComponent {
  constructor(private sharedService: SharedService) {}

  get internalUrl() {
    return this.sharedService.getInternalUrl();
  }
}

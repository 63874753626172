<form [formGroup]="emergencyBreakDownChargesForm" (change)="saveDraft()">
  <div class="flex flex-row overflow-auto no-scrollbar">
    <div class="p-2 w-full" >
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('Calloutfee',infoBlockId)}}</p>

      <div>
        <tds-textarea
          ngDefaultControl
          row="5"
          size="sm"
          formControlName="CalloutFee"
          (tdsFocus)="textFocusCalloutfee = true"
          (tdsBlur)="textFocusCalloutfee = true"
          [helper]="
            utilService.getErrorMsgs(
              CalloutFee,
              'CalloutFee',
              textFocusCalloutfee
            )
          "
          [state]="
            CalloutFee?.invalid &&
            (CalloutFee?.dirty || CalloutFee?.touched || textFocusCalloutfee)
              ? 'error'
              : 'success'
          "
          max-length="255"
        >
        </tds-textarea>
      </div>
      <p class="tds-headline-06 mt-5 mb-0 pb-0">{{getLabelText('AfterHourCompensation',infoBlockId)}}</p>

      <div>
        <tds-textarea
          ngDefaultControl
          row="5"
          size="sm"
          formControlName="AfterHourCompensation"
          (tdsFocus)="textFocus = true"
          (tdsBlur)="textFocus = true"
          [helper]="
            utilService.getErrorMsgs(
              AfterHourCompensation,
              'AfterHourCompensation',
              textFocus
            )
          "
          [state]="
            AfterHourCompensation?.invalid &&
            (AfterHourCompensation?.dirty ||
              AfterHourCompensation?.touched ||
              textFocus)
              ? 'error'
              : 'success'
          "
          max-length="255"
        >
        </tds-textarea>
      </div>
    </div>
  </div>
</form>

export class Company {
    Id!: string
    Country!: string
    Name!: string
    Name_Local!: string
    Category_Id!: string
    City!: string
    City_Local!: string
    Region!: string
    Region_Local!: string
    Lastupdated!: string
    Lastupdatedby!: string
    Vatno!: string
    CompanyAddress!: Array<{
        Id?: string
        Addresstype_code?: string
        Streetaddress?: string
        Streetaddress_local?: string
        Postalcode?: string
        Postalcity?: string
        Postalcity_local?: string
        Comment?: string
        Childnum?: number
        Company_id?: string
        CompanyName?: string
        Country?: string
    }>
    CompanyAdministrativeIssues!: {
        Administrative_issues?: string
        Company_id?: string
    }
    CompanyContacts!: Array<{
        Id?: string
        Contacttype_code?: string
        Contacttxt?: string
        Comment?: string
        Company_id?: string
    }>
    CompanyPhone!: Array<{
        Id?: string
        Phonetype_code?: string
        Phoneno?: string
        Comment?: string
        Company_id?: string
    }>
    CompanyBankInformation!: {
        Bankname?: string
        Bankcity?: string
        bankaccountno?: string
        swiftbiccode?: string
        iban?: string
        Company_id?: string
    }
    CompanyDealerWorkshop!: {
        DealerId?: string
        Parent_DealerId?: string
        DealerWorkshopType_code?: string
        DealerWorkshopOwnership_code?: string
        Company_id?: string
        notice?: string
        Termination?: string
        comment?: string
        ParentId?: string
    }
    CompanyDealerWorkshopOptions!: Array<{
        Id?: string
        DealerWorkshopOptions_Code?: string
        Company_id?: string
    }>
    CompanyAcceptedCreditCards!: Array<{
        Id?: string
        Paymentcard_id?: string
        Company_id?: string
        Accepted?: boolean
    }>
    CompanyDistributorImport!: Array<any>
    CompanyDistributorOptions!: Array<any>
    CompanyDOSCertification!: {
        IsCertified?: boolean
        Evaluation?: boolean
        Expiringdate?: string
        ExcemptionuntilOverride?: string
        Company_id?: string
        ExcemptionUntilComment?: string
        AvailableforExternalSystems?: boolean
        Availableforexternalsystems_comment?: string
    }
    CompanyLocation!: {
        Latitude?: string
        Longitude?: string
        TimeZoneId?: string
        Company_id?: string
    }
    CompanyOpeningHours!: Array<{
        Id?: string
        Dayfrom?: string
        Dayto?: string
        Hours1from?: string
        Hours2from?: string
        Hours1to?: string
        Hours2to?: string
        Childnum?: number
        Company_id?: string
        DayHours?: string
    }>
    CompanyOpeningHoursComment!: {
        Txt?: string
        Company_id?: string
    }
    CompanyOtherCards!: {
        OtherTxt?: string
        Company_id?: string
    }
    CompanyPartsLogistics!: {
        DeliveryCustomerNo?: string
        CustomerNo?: string
        Company_id?: string
    }
    CompanySupportedServices!: Array<{
        Id?: string
        DealerWorkshopServices_id?: string
        Company_id?: string
    }>
    CompanyTag!: Array<any>
    CompanyGlobalManagementContacts!: Array<any>
    CompanySocialMediaType!: Array<any>
    CompanyAssistanceRoleDetails!: Array<{
        Id?: string
        AssistanceRolesType_Code?: string
        Name?: string
        OfficeNumber?: string
        MobileNumber?: string
        Company_Id?: string
        Remarks?: string
        Email?: string
    }>
    CompanyAssistanceProductService!: Array<{
        Id?: string
        AssistanceProductServicetType_Code?: string
        CompanyName?: string
        OfficeNumber?: string
        MobileNumber?: string
        Company_Id?: string
        Remarks?: string
        Email?: string
    }>

    constructor() {
        this.CompanyAcceptedCreditCards = [];
        this.CompanyAddress = [];
        this.CompanyAdministrativeIssues = {};
        this.CompanyAssistanceProductService = [];
        this.CompanyBankInformation = {};
        this.CompanyContacts = [];
        this.CompanyDOSCertification = {};
        this.CompanyDealerWorkshop = {};
        this.CompanyDealerWorkshopOptions = [];
        this.CompanyDistributorImport = [];
        this.CompanyDistributorOptions = [];
        this.CompanyGlobalManagementContacts = [];
        this.CompanyLocation = {};
        this.CompanyOpeningHours = [];
        this.CompanyOpeningHoursComment = {};
        this.CompanyOtherCards = {};
        this.CompanyPartsLogistics = {};
        this.CompanyPhone = [];
        this.CompanySocialMediaType = [];
        this.CompanySupportedServices = [];
        this.CompanyTag = [];
    }
}

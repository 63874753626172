import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class UserManualService {
    constructor(private http: HttpClient) { }

    downloadUserManual() {
        return this.http.get(environment.baseUrl + '/v1/file/download/sismanual.pdf', { observe: 'response', responseType: 'blob' });
    }

    uploadUserManual(formData:any) {
        return this.http.post(environment.baseUrl + '/v1/file/upload', formData);
    }

}




import { DealerworkshopOwnershipService } from '../app/services/dealerworkshop-ownership.service';
import { DealerworkshopTypesService } from '../app/services/dealerworkshop-types.service';
import { InfoblocksService } from '../app/services/infoblocks.service';
import { TextsService } from '../app/services/texts.service';

export const environment = {
  baseUrl: 'https://api.sis.devtest.gf.aws.scania.com/api',
  production: false,
  authConfig: {
    region: 'eu-north-1',
    userPoolId: 'eu-north-1_BEbNDcDPG',
    userPoolWebClientId: '3148m68o4c01krf9f72kn5ar9h',
    azureAdLogoutUri:
      'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac/oauth2/v2.0/logout',
    oauth: {
      domain: 'https://sis-admin-dev.auth.eu-north-1.amazoncognito.com',
      issuer:
        'https://cognito-idp.eu-north-1.amazonaws.com/eu-north-1_BEbNDcDPG',
      scope: 'email openid profile',
      redirectSignIn:
        'https://admin.sis.devtest.gf.aws.scania.com/auth-callback',
      redirectSignOut: 'https://admin.sis.devtest.gf.aws.scania.com/logout',
      responseType: 'code',
    },
  },
  accessTypes: {
    SESAssistance : '5e9ffbed-bcd6-ec11-912f-0050569e683e',
    SISCooridinator : '4f9ffbed-bcd6-ec11-912f-0050569e683e',
    ScaniaAssistanceCoordinator : '27183160-0dfd-4b19-ad36-4b53a6dbb179',
  },
  accountTypesServices: {
    accountTypesService: '/v1/useraccesstype',
    userManagementService: '/v1/user',
  },
  administrationServices: {
    countryService: '/v1/country',
    addressTypeService: '/v1/addresstype',
    phoneTypeService: '/v1/phonetype',
    contactTypeService: '/v1/contacttype',
    DealerworkshopOwnershipService: '/v1/dealerworkshopownership',
    globalmanagementtype: '/v1/globalmanagementtype',
    DealerworkshopTypesService: '/v1/dealerworkshoptype',
    distributorOptionsService: '/v1/distributoroption',
    paymentCardsService: '/v1/paymentcards',
    distributorImportTypes: '/v1/distributorimporttype',
    relationTypes: '/v1/relationtype',
    tagGroup: '/v1/taggroup',
    labelText: '/v1/labeltext',
    userList: '/v1/user',
    InfoblocksService: '/v1/infoblock',
    TextsService: '/v1/text',
    scaniaAssistanceService: '/v1/assistancerolestype',
    productServicesService: '/v1/assistanceproductservicetype',
    CategoriesService: '/v1/category',
    DashboardService: '/v1/dashboard',
  },
  googleMap: {
    timeZone: 'https://maps.googleapis.com/maps/api/timezone/json',
    apiKey: 'AIzaSyAINHuCKaDlExRLZ_2niBKIZcPrAXtJvBk',
  },
  internalUrl: 'https://internal.sis.devtest.gf.aws.scania.com/',
};

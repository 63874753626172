<form [formGroup]="categorizationForm" (change)="saveDraft()" class="p-2">
  @if(!controlArr?.length) {
  <div class="flex" style="justify-content: center">
    <p class="tds-details-06 mb-0 pb-0">No Fields Added</p>
  </div>
  } @else { @for(item of controlArr;track $index){
  <div class="flex flex-col">
    <div class="m-2 inputField">
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ item.Name
        }}<span *ngIf="item?.required" class="text-red-600">*</span>
      </p>

      <div>
        <tds-dropdown
          name="dropdown"
          size="sm"
          open-direction="auto"
          [defaultValue]="getDefaultValue($index)"
          placeholder="{{ 'Select ' + item.Name }}"
          (tdsChange)="handleCategorizationDropDown($event, $index, item)"
          (tdsFocus)="categorizationFromFocus = true"
          (tdsBlur)="categorizationFromFocus = true"
          [error]="
            this.getControls($index, 'Tag_id')
              ? this.getControls($index, 'Tag_id').invalid
              : false &&
                (this.getControls($index, 'Tag_id').dirty ||
                  this.getControls($index, 'Tag_id').touched ||
                  categorizationFromFocus)
              ? true
              : false
          "
          [helper]="
            getErrorMsgs($index, 'Tag_id', categorizationFromFocus, item.Name)
          "
        >
          @if(DropDowndata[$index]) { @for (type of DropDowndata[$index]; track
          $index) {
          <tds-dropdown-option value="{{ type.Id }}">{{
            type.Name
          }}</tds-dropdown-option>
          } }
        </tds-dropdown>
      </div>
    </div>
  </div>
  } }
</form>

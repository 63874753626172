<form [formGroup]="companyForm" (change)="saveDraft()">
  <div class="flex flex-wrap space-x-5 p-2 pr-1 mb-5">
    <div class="pr-1">
      <div class="flex">
        @for(day of days; track $index){
        <p class="px-1 py-0 font-bold text-sm">{{ day.substring(0, 3) }}</p>
        }
      </div>
      @if(this.mode =='print') { @for(data of
      companyForm.controls.CompanyOpeningHours.controls; track $index; let
      formIndex= $index){
      <div class="flex">
        @for(day of days; track $index;let i= $index){
        <div style="padding: 8px 0px 8px 8px; width: 24px; height: 24px">
          <input
            type="checkbox"
            value="{{ day }}"
            checkbox-id="{{ day }}"
            [checked]="isChecked(data, i)"
          />
        </div>
        }
      </div>
      } } @else{ @for(data of companyForm.controls.CompanyOpeningHours.controls;
      track $index; let formIndex= $index){
      <div class="flex">
        @for(day of days; track $index;let i= $index){
        <tds-checkbox
          value="{{ day }}"
          checkbox-id="{{ day }}"
          checked="{{ isChecked(data, i) }}"
          disabled="{{ isDisabled(formIndex, i) }}"
          class="py-2 pl-2 pr-0"
          (tdsChange)="handleDay($event, data, i, formIndex)"
        >
        </tds-checkbox>
        }
      </div>
      <p class="text-wrap text-xs text-red-600 p-0 m-0 w-56">
        {{ getErrorMsgs(formIndex, "Dayfrom", Hour1FromFocus) }}
      </p>
      } }
    </div>
    <div class="pt-0" formArrayName="CompanyOpeningHours">
      <p>Note: Each day can only be selected in one row</p>
      @for(data of companyForm.controls.CompanyOpeningHours.controls; track
      $index; let i = $index){
      <div class="flex space-x-1" formGroupName="{{ i }}">
        <div class="w-20">
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            (tdsBlur)="Hour1FromFocus = true"
            (tdsFocus)="Hour1FromFocus = true"
            no-min-width
            formControlName="Hours1from"
            placeholder="HH:MM"
            (tdsChange)="calculateDayHours(i)"
            [state]="
              this.getControls(i, 'Hours1from').invalid &&
              (this.getControls(i, 'Hours1from').dirty ||
                this.getControls(i, 'Hours1from').touched ||
                Hour1FromFocus)
                ? 'error'
                : 'success'
            "
            [helper]="getErrorMsgs(i, 'Hours1from', Hour1FromFocus)"
          ></tds-text-field>
        </div>
        <p class="text-center">-</p>
        <div class="w-20">
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            no-min-width
            formControlName="Hours1to"
            (tdsBlur)="Hour1ToFocus = true"
            (tdsFocus)="Hour1ToFocus = true"
            placeholder="HH:MM"
            (tdsChange)="calculateDayHours(i)"
            [state]="
              this.getControls(i, 'Hours1to').invalid &&
              (this.getControls(i, 'Hours1to').dirty ||
                this.getControls(i, 'Hours1to').touched ||
                Hour1ToFocus)
                ? 'error'
                : 'success'
            "
            [helper]="getErrorMsgs(i, 'Hours1to', Hour1ToFocus)"
          ></tds-text-field>
        </div>
        <div class="w-20">
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            no-min-width
            formControlName="Hours2from"
            (tdsBlur)="Hour2FromFocus = true"
            (tdsFocus)="Hour2FromFocus = true"
            placeholder="HH:MM"
            (tdsChange)="calculateDayHours(i)"
            [state]="
              this.getControls(i, 'Hours2from').invalid &&
              (this.getControls(i, 'Hours2from').dirty ||
                this.getControls(i, 'Hours2from').touched ||
                Hour2FromFocus)
                ? 'error'
                : 'success'
            "
            [helper]="getErrorMsgs(i, 'Hours2from', Hour2FromFocus)"
          ></tds-text-field>
        </div>
        <p class="text-center">-</p>
        <div class="w-20">
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            no-min-width
            formControlName="Hours2to"
            placeholder="HH:MM"
            (tdsBlur)="Hour2ToFocus = true"
            (tdsFocus)="Hour2ToFocus = true"
            (tdsChange)="calculateDayHours(i)"
            [state]="
              this.getControls(i, 'Hours2to').invalid &&
              (this.getControls(i, 'Hours2to').dirty ||
                this.getControls(i, 'Hours2to').touched ||
                Hour2ToFocus)
                ? 'error'
                : 'success'
            "
            [helper]="getErrorMsgs(i, 'Hours2to', Hour2ToFocus)"
          ></tds-text-field>
        </div>

        <div class="w-20 pl-2.5">
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            no-min-width
            placeholder="HR:MM"
            [value]="this.getControls(i, 'DayHours').value"
            disabled
          ></tds-text-field>
        </div>
      </div>
      }
      <div class="flex">
        <div class="flex-1 pr-2" [formGroup]="companyOpeningHoursCommentForm">
          <p class="px-1 py-0 font-bold text-sm">
            {{ getLabelText("Comment", infoBlockId) }}
          </p>
          <tds-textarea
            row="1"
            ngDefaultControl
            type="text"
            size="sm"
            formControlName="Txt"
            placeholder="Comment"
          >
          </tds-textarea>
        </div>
        <div class="w-20" [formGroup]="TotalHoursForm">
          <p class="px-1 py-0 font-bold text-sm">
            {{ getLabelText("Total", infoBlockId) }}
          </p>
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            no-min-width
            placeholder="HR:MM"
            formControlName="totalDayHours"
          ></tds-text-field>
        </div>
      </div>
    </div>
  </div>
</form>

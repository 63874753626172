import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';
import { SubjectService } from '../../../services/subject.service';

import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-workshop-capabilities',
  standalone: false,
  templateUrl: './workshop-capabilities.component.html',
  styleUrl: './workshop-capabilities.component.css',
})
export class WorkshopCapabilitiesComponent implements OnChanges {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId: string = '';
  showDate: boolean = false;
  focus: boolean = false;
  workstation_largeFocus: boolean = false;
  workstation_intermediateFocus: boolean = false;
  workstation_basicFocus: boolean = false;
  accident_repairFocus: boolean = false;
  diagnosisFocus: boolean = false;
  washing_otherFocus: boolean = false;
  drive_timeFocus: boolean = false;

  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    public appService: AppService,
    private subjectService: SubjectService

  ) {}

  ngOnInit(): void {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.focus = true;
        this.workstation_largeFocus = true;
        this.workstation_intermediateFocus = true;
        this.workstation_basicFocus = true;
        this.accident_repairFocus = true;
        this.diagnosisFocus = true;
        this.washing_otherFocus = true;
        this.drive_timeFocus = true;
      }
    });
  }

  CompanyWorkshopCapabilitiesForm =
    inject(CompanyFormService).form.controls.CompanyWorkshopCapabilities;

  ngOnChanges(changes: SimpleChanges): void {
    this.CompanyWorkshopCapabilitiesForm.controls.workstation_large.addValidators(
      this.nonNegativeValidator()
    );
    this.CompanyWorkshopCapabilitiesForm.controls.workstation_intermediate.addValidators(
      this.nonNegativeValidator()
    );
    this.CompanyWorkshopCapabilitiesForm.controls.workstation_basic.addValidators(
      this.nonNegativeValidator()
    );
    this.CompanyWorkshopCapabilitiesForm.controls.accident_repair.addValidators(
      this.nonNegativeValidator()
    );
    this.CompanyWorkshopCapabilitiesForm.controls.diagnosis.addValidators(
      this.nonNegativeValidator()
    );
    this.CompanyWorkshopCapabilitiesForm.controls.washing_other.addValidators(
      this.nonNegativeValidator()
    );
    this.CompanyWorkshopCapabilitiesForm.controls.drive_time.addValidators(
      this.nonNegativeValidator()
    );

    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.CompanyWorkshopCapabilitiesForm.patchValue({
        Company_id:
          this.companyData?.CompanyWorkshopCapabilities?.Company_id ||
          this.companyData.Id,
        workstation_large:
          this.companyData?.CompanyWorkshopCapabilities?.workstation_large ||
          '',
        workstation_intermediate:
          this.companyData?.CompanyWorkshopCapabilities
            ?.workstation_intermediate || '',
        workstation_basic:
          this.companyData?.CompanyWorkshopCapabilities?.workstation_basic ||
          '',
        accident_repair:
          this.companyData?.CompanyWorkshopCapabilities?.accident_repair || '',
        diagnosis:
          this.companyData?.CompanyWorkshopCapabilities?.diagnosis || '',
        washing_other:
          this.companyData?.CompanyWorkshopCapabilities?.washing_other || '',
        ownership:
          this.companyData?.CompanyWorkshopCapabilities?.ownership || '',
        rent_exp_date:
          this.companyData?.CompanyWorkshopCapabilities?.rent_exp_date || '',
        drive_time:
          this.companyData?.CompanyWorkshopCapabilities?.drive_time || '',
      });
    } else {
      this.CompanyWorkshopCapabilitiesForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      this.CompanyWorkshopCapabilitiesForm.patchValue({
        Company_id:
          this.companyData?.CompanyWorkshopCapabilities?.Company_id ||
          this.myId,
        workstation_large:
          this.companyData?.CompanyWorkshopCapabilities?.workstation_large ||
          '',
        workstation_intermediate:
          this.companyData?.CompanyWorkshopCapabilities
            ?.workstation_intermediate || '',
        workstation_basic:
          this.companyData?.CompanyWorkshopCapabilities?.workstation_basic ||
          '',
        accident_repair:
          this.companyData?.CompanyWorkshopCapabilities?.accident_repair || '',
        diagnosis:
          this.companyData?.CompanyWorkshopCapabilities?.diagnosis || '',
        washing_other:
          this.companyData?.CompanyWorkshopCapabilities?.washing_other || '',
        ownership:
          this.companyData?.CompanyWorkshopCapabilities?.ownership || '',
        rent_exp_date:
          this.companyData?.CompanyWorkshopCapabilities?.rent_exp_date || '',
        drive_time:
          this.companyData?.CompanyWorkshopCapabilities?.drive_time || '',
      });
    }
    if (!this.companyData?.CompanyWorkshopCapabilities) {
      this.companyData.CompanyWorkshopCapabilities = {};
    }

    if (this.companyData?.CompanyWorkshopCapabilities?.ownership === 'rented') {
      this.showDate = true;
    }
  }

  get rentExpiryDate() {
    return this.CompanyWorkshopCapabilitiesForm.get('rent_exp_date');
  }

  get workstation_large() {
    return this.CompanyWorkshopCapabilitiesForm.get('workstation_large');
  }
  get workstation_intermediate() {
    return this.CompanyWorkshopCapabilitiesForm.get('workstation_intermediate');
  }

  get workstation_basic() {
    return this.CompanyWorkshopCapabilitiesForm.get('workstation_basic');
  }

  get accident_repair() {
    return this.CompanyWorkshopCapabilitiesForm.get('accident_repair');
  }

  get diagnosis() {
    return this.CompanyWorkshopCapabilitiesForm.get('diagnosis');
  }

  get washing_other() {
    return this.CompanyWorkshopCapabilitiesForm.get('washing_other');
  }

  get drive_time() {
    return this.CompanyWorkshopCapabilitiesForm.get('drive_time');
  }

  nonNegativeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value >= 0 ? null : { negativeNumber: true };
    };
  }

  handleDropdown(event: any) {
    if (event.detail.value === 'not_specified') {
      this.showDate = false;
      this.CompanyWorkshopCapabilitiesForm.controls.rent_exp_date.removeValidators(
        Validators.required
      );
      this.CompanyWorkshopCapabilitiesForm.controls.ownership.setValue('');
    } else if (event.detail.value === 'rented') {
      this.showDate = true;
      this.focus = true;
      this.CompanyWorkshopCapabilitiesForm.controls.rent_exp_date.setValidators(
        [Validators.required]
      );
      this.CompanyWorkshopCapabilitiesForm.controls.ownership.setValue(
        event.detail.value
      );
      this.CompanyWorkshopCapabilitiesForm.controls.rent_exp_date.setValue('');
    } else {
      this.showDate = false;
      this.CompanyWorkshopCapabilitiesForm.controls.rent_exp_date.removeValidators(
        Validators.required
      );
      this.CompanyWorkshopCapabilitiesForm.controls.ownership.setValue(
        event.detail.value
      );
    }

    this.CompanyWorkshopCapabilitiesForm.markAllAsTouched();
    this.CompanyWorkshopCapabilitiesForm.markAsDirty();
    this.CompanyWorkshopCapabilitiesForm.updateValueAndValidity();
    this.saveDraft();
  }

  handleDatePicker(event: any) {
    this.CompanyWorkshopCapabilitiesForm.controls.rent_exp_date.setValue(
      event.target.value + 'T00:00:00'
    );
    this.CompanyWorkshopCapabilitiesForm.markAsDirty();
    this.saveDraft();
  }

  saveDraft() {
    // this.companyData.CompanyWorkshopCapabilities = this.CompanyWorkshopCapabilitiesForm?.value;
    this.companyData.CompanyWorkshopCapabilities.workstation_large =
      this.CompanyWorkshopCapabilitiesForm?.value?.workstation_large === ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.workstation_large;
    this.companyData.CompanyWorkshopCapabilities.workstation_intermediate =
      this.CompanyWorkshopCapabilitiesForm?.value?.workstation_intermediate ===
      ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.workstation_intermediate;
    this.companyData.CompanyWorkshopCapabilities.workstation_basic =
      this.CompanyWorkshopCapabilitiesForm?.value?.workstation_basic === ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.workstation_basic;
    this.companyData.CompanyWorkshopCapabilities.accident_repair =
      this.CompanyWorkshopCapabilitiesForm?.value?.accident_repair === ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.accident_repair;
    this.companyData.CompanyWorkshopCapabilities.diagnosis =
      this.CompanyWorkshopCapabilitiesForm?.value?.diagnosis === ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.diagnosis;
    this.companyData.CompanyWorkshopCapabilities.washing_other =
      this.CompanyWorkshopCapabilitiesForm?.value?.washing_other === ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.washing_other;
    this.companyData.CompanyWorkshopCapabilities.ownership =
      this.CompanyWorkshopCapabilitiesForm?.value?.ownership === ''
        ? ''
        : this.CompanyWorkshopCapabilitiesForm?.value?.ownership;
    this.companyData.CompanyWorkshopCapabilities.drive_time =
      this.CompanyWorkshopCapabilitiesForm?.value?.drive_time === ''
        ? 0
        : this.CompanyWorkshopCapabilitiesForm?.value?.drive_time;
    this.companyData.CompanyWorkshopCapabilities.rent_exp_date =
      this.CompanyWorkshopCapabilitiesForm?.value?.rent_exp_date === ''
        ? '1900-01-01T00:00:00.000'
        : this.CompanyWorkshopCapabilitiesForm?.value?.rent_exp_date;

    if (this.CompanyWorkshopCapabilitiesForm.dirty) {
      this.companyData.CompanyWorkshopCapabilities.IsUpdated = true;
    }

    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

<form [formGroup]="partsLogisticForm" (change)="saveDraft()">
  <div class="flex flex-row flex-wrap">
    <div
      class="w-full md:w-1/3 sm:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex-shrink-0"
    >
      <div class="inputField">
        <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-4">
          {{getLabelText('CustomerNoConsignee',infoBlockId)}}<span *ngIf="Required" class="text-red-600">*</span>
        </p>
        <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('CustomerNoConsignee',infoBlockId)}}"
            selector="#customerNumber-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="customerNumber-tooltip"
            size="15"
          ></tds-icon>
      </div>
        <div>
          <tds-text-field
            ngDefaultControl
            type="number"
            size="sm"
            formControlName="deliveryCustomerNo"
            (tdsFocus)="customerNoFocus = true"
            (tdsBlur)="customerNoFocus = true"
            [helper]="
              utilService.getErrorMsgs(
                deliveryCustomerNo,
                'Customer number',
                customerNoFocus,
                5
              )
            "
            [state]="
              deliveryCustomerNo?.invalid &&
              (deliveryCustomerNo?.dirty ||
                deliveryCustomerNo?.touched ||
                customerNoFocus)
                ? 'error'
                : 'success'
            "
          >
          </tds-text-field>
        </div>
      </div>
    </div>
    <div
      class="w-full md:w-1/3 sm:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex-shrink-0"
    >
      <div class="inputField">
        <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0">
          {{getLabelText('CustomerNoInvoice',infoBlockId)}}
        </p>
        <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('CustomerNoInvoice',infoBlockId)}}"
            selector="#CustomerNoInvoice-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="CustomerNoInvoice-tooltip"
            size="15"
          ></tds-icon>
      </div>
        <div>
          <tds-text-field
            ngDefaultControl
            type="number"
            size="sm"
            formControlName="invoiceReceiverNo"
            id="invoiceReceiverNo"
            (tdsFocus)="customerNoFocus = true"
            (tdsBlur)="customerNoFocus = true"
            [state]="
              invoiceReceiverNo?.invalid &&
              (invoiceReceiverNo?.dirty ||
                invoiceReceiverNo?.touched ||
                invoiceReceiverNo)
                ? 'error'
                : 'success'
            "
            [helper]="
              utilService.getErrorMsgs(
                invoiceReceiverNo,
                'Parent Customer number',
                invoiceReeiverNoFocus,
                5
              )
            "
          >
          </tds-text-field>
        </div>
      </div>
    </div>
    <div
      class="w-full md:w-1/3 sm:w-1/3 lg:w-1/3 xl:w-1/3 2xl:w-1/3 flex-shrink-0"
    >
      <div class="inputField">
        <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0">{{getLabelText('CustomerNoDistributor',infoBlockId)}}</p>
        <tds-tooltip
            placement="right"
            text="{{getPopupHelpText('CustomerNoDistributor',infoBlockId)}}"
            selector="#CustomerNoDistributor-tooltip"
            offset-distance="8"
            offset-skidding="0"
            mouse-over-tooltip="true"
            trigger="hover"
          >
          </tds-tooltip>
          <tds-icon
            name="info"
            class="mt-5 text-[#B0B7C4]"
            id="CustomerNoDistributor-tooltip"
            size="15"
          ></tds-icon>
      </div>
        <div class="mt-4">
          <tds-text-field
            ngDefaultControl
            type="Text"
            size="sm"
            formControlName="distributorCustomerNo"
            readOnly="true"
            style="opacity: 40%"
          ></tds-text-field>
        </div>
      </div>
    </div>
  </div>
</form>

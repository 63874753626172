import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { toastMode } from '../models/shared.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  refreshSubject = new BehaviorSubject(false);
  loaderSubject = new BehaviorSubject(false);
  refreshSubject$ = this.refreshSubject.asObservable();
  leftMenuNavSubject = new BehaviorSubject(false);
  sideMenuNavSubject = new BehaviorSubject(false);
  addEditCompanySubject = new BehaviorSubject('');
  validationCompanySubject = new BehaviorSubject(false);
  dealerTypeCompanySuject = new BehaviorSubject('');
  toastSubject = new BehaviorSubject({});
  companyCountrySubject = new BehaviorSubject('');

  toggleLoaderSubject(data: boolean) {
    this.loaderSubject.next(data);
  }

  getLoaderSubject() {
    return this.loaderSubject.asObservable();
  }

  toggleLeftMenuNavSubject(data: boolean) {
    this.leftMenuNavSubject.next(data);
  }

  toggleSideMenuNavSubject(data: boolean) {
    this.sideMenuNavSubject.next(data);
  }

  removeEditCompany(id: string) {
    this.addEditCompanySubject.next(id);
  }
  removeAddCompany(id: string) {
    this.addEditCompanySubject.next('Add');
  }

  emitAddEditValidationDetails() {
    this.validationCompanySubject.next(true);
  }

  emitDealerType(dealerTypeCode: string) {
    this.dealerTypeCompanySuject.next(dealerTypeCode);
  }

  emitToastMessage(messageObj: toastMode) {
    this.toastSubject.next(messageObj);
  }

  emitCompanyCountryChange(country: string) {
    this.companyCountrySubject.next(country);
  }
}

import { Routes } from '@angular/router';
import { AuthCallbackComponent } from './common/auth/auth-callback.component';
import { AuthRedirectComponent } from './common/auth/auth-redirect.component';
import { LogoutMsgComponent } from './common/auth/logout-msg.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { AuthGuard } from './shared/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdressTypesHomeComponent } from './administration/addressTypes/adress-types-home/adress-types-home.component';
import { UnauthorizedMsgComponent } from './common/auth/unauthorized-msg.component';
import { NoAccessComponent } from './common/auth/no-access/no-access.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./company/company.module').then((module) => module.CompanyModule),
  },

  {
    path: 'accessControl',
    loadChildren: () =>
      import('./access-control/access-control.module').then(
        (module) => module.AccessControlModule
      ),
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('./administration/administration.module').then(
        (module) => module.AdministrationModule
      ),
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
    data: { hideLeftMenu: true },
  },
  {
    path: 'auth-redirect',
    component: AuthRedirectComponent,
    data: { hideLeftMenu: true },
  },
  {
    path: 'logout',
    component: LogoutMsgComponent,
    data: { hideLeftMenu: true },
  },
  {
    path: 'unauthorized',
    component: UnauthorizedMsgComponent,
  },
  {
    path: 'noaccess',
    component: NoAccessComponent,
    data: { hideLeftMenu: true, hideHeader: true },
  },
  {
    path: 'addresstype',
    component: AdressTypesHomeComponent,
  },
  {
    path: 'globalManagementList',
    loadChildren: () =>
      import('./common/global-management-list.module').then(
        (module) => module.GlobalManagementListModule
      ),
  },
  { path: '**', component: NotFoundComponent },
];

<form>
  @if(this.mode == 'print'){
  <div class="flex flex-col" style="padding: 12px">
    <p class="my-0 py-0">
      Deleted :
      <span>
        {{
          companyData?.CompanyDeleted?.Datum | date : "yyyy-MM-dd HH:mm:ss"
        }}</span
      >
    </p>
    <p>
      From category
      <span> {{ companyData?.CompanyDeleted?.Old_category_name }}</span>
    </p>
    <tds-button
      id="my-modal-button-category"
      text="Move Back to Category"
      size="sm"
      variant="secondary"
    ></tds-button>
  </div>
  }@else {
  <div class="flex flex-col" style="padding: 12px">
    <p class="my-0 py-0">
      Deleted :
      <span>
        {{
          companyData?.CompanyDeleted?.Datum | date : "yyyy-MM-dd HH:mm:ss"
        }}</span
      >
    </p>
    <p>
      From category
      <span> {{ companyData?.CompanyDeleted?.Old_category_name }}</span>
    </p>
    <tds-button
      id="my-modal-button-moveback"
      text="Move Back to Category"
      size="sm"
      variant="secondary"
    ></tds-button>
    <tds-modal
        header="Move this Company"
        selector="#my-modal-button-moveback"
        id="my-modal"
        size="sm"
        actions-position="static"
        prevent="false"
        closable="true"
      >
        <span slot="body">
            Are you sure you want to move this company back to {{ companyData?.CompanyDeleted?.Old_category_name }}
        </span>
        <span slot="actions" class="tds-u-flex tds-u-gap2">
          <tds-button
            type="button"
            data-dismiss-modal
            (click)="MovetoCategory()"
            size="sm"
            text="Move Company Back"
            variant="primary"
          ></tds-button>
          <tds-button
            data-dismiss-modal
            size="sm"
            text="Cancel"
            variant="secondary"
          ></tds-button>
        </span>
      </tds-modal>
  </div>
  }
</form>

import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-contracted-partner-info',
  standalone: false,
  templateUrl: './contracted-partner-info.component.html',
  styleUrl: './contracted-partner-info.component.css',
})
export class ContractedPartnerInfoComponent implements OnChanges {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId:string ='';
  partnerInfoToolTip =
    'If check meaning that this company is a Contracted partner';

  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    public appService: AppService
  ) {}
  ContractedPartnerInfoForm =
    inject(CompanyFormService).form.controls.CompanyContractedPartner;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.ContractedPartnerInfoForm.patchValue({
        Is_ContractedPartner:
          this.companyData?.CompanyContractedPartner?.Is_ContractedPartner,
        Company_id: this.companyData?.CompanyContractedPartner?.Company_id,
      });
    } else {
      this.ContractedPartnerInfoForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();

      this.ContractedPartnerInfoForm.patchValue({
        Is_ContractedPartner:
          this.companyData?.CompanyContractedPartner?.Is_ContractedPartner,
        Company_id: this.companyData?.CompanyContractedPartner?.Company_id,
      });
    }
    if (!this.companyData.CompanyContractedPartner) {
      this.companyData.CompanyContractedPartner = {};
    }
  }

  get IsContractedPartnerLabel(){
    return this.appService.getLabelText('IsContractedPartner',this.infoBlockId) || '';
  }

  get IsContractedPartnerPopupText(){
    return this.appService.getPopupHelpText('IsContractedPartner',this.infoBlockId) || '';
  }

  handleChecked(event: any) {
    if (event.target.checked) {
      this.ContractedPartnerInfoForm.controls.Is_ContractedPartner.setValue(
        true
      );
    } else {
      this.ContractedPartnerInfoForm.controls.Is_ContractedPartner.setValue(
        false
      );
    }
    this.ContractedPartnerInfoForm.markAsDirty();
    this.saveDraft();
  }

  saveDraft() {
    this.companyData.CompanyContractedPartner.Is_ContractedPartner =
      this.ContractedPartnerInfoForm?.get('Is_ContractedPartner')?.value;

    if (this.ContractedPartnerInfoForm.dirty) {
      this.companyData.CompanyContractedPartner.IsUpdated = true;
    }

    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

<div
  *ngIf="socialMediaTypes && socialMediaTypes.length"
  [class]="this.mode == 'print'? '' : 'overflow-auto p-4'"
>
  <tds-table
    class="min-w-full table-auto"
    vertical-dividers="true"
    compact-design="true"
    responsive="true"
    no-min-width
  >
    <tds-table-header>
      <tds-header-cell cell-key="name" cell-value="SocialMediaContacts"></tds-header-cell>
      <tds-header-cell cell-key="phone" cell-value="PhoneNumber"></tds-header-cell>
      <tds-header-cell cell-key="Comment" cell-value="Comment"></tds-header-cell>
      @if(this.mode !== 'print'){
        <tds-header-cell cell-key="edit" cell-value=""></tds-header-cell>
        <tds-header-cell cell-key="delete" cell-value=""></tds-header-cell>
      }
    </tds-table-header>
    <tds-table-body>
      @if(socialMediaTypes && socialMediaTypes.length){
      <tds-table-body-row
        *ngFor="let info of socialMediaTypes; let i = index"
      >
        <tds-body-cell cell-key="Socialmediatype_code">{{
          info?.Socialmediatype_code
        }}</tds-body-cell>
        <tds-body-cell cell-key="PhoneNumber">{{ info?.PhoneNumber || "-" }}</tds-body-cell>
        <tds-body-cell cell-key="Comment">{{ info?.Comment || "-" }}</tds-body-cell>
        @if(this.mode !== 'print'){
          <tds-body-cell cell-key="">
            <tds-icon
              size="18px"
              name="edit"
              class="cursor-pointer"
              (click)="editFunc(info, i)"
            ></tds-icon>
          </tds-body-cell>
          <tds-body-cell cell-key="">
            <tds-icon
              size="18px"
              name="trash"
              class="cursor-pointer"
              (click)="delFunc(info, i)"
            ></tds-icon
          ></tds-body-cell>
        }
      </tds-table-body-row>
      } @else {
      <tds-table-body-row>
        <span class="text-center p-4"> No Data Found</span></tds-table-body-row
      >
      }
    </tds-table-body>
  </tds-table>
</div>

<div class="p-5 mb-3">
  <tds-button
    (click)="AddNewSocialMediaType()"
    text="Add New Social Media Type"
    size="sm"
    variant="secondary"
    class="mt-5"
  >
    <tds-icon slot="icon" size="20px" name="contact"></tds-icon
  ></tds-button>
</div>

<tds-modal
  header="{{action === 'Add'?'Add New':'Edit'}} Social Media Type"
  selector="#scania-history-modal-button"
  id="my-modal"
  size="sm"
  actions-position="static"
  prevent="false"
  closable="true"
  #AddNewSocialMediaTypeModal
>
  <span slot="body">
    <form [formGroup]="inputForm">
      <div class="flex flex-col p-5 mb-5">
        <div class="p-3">
            <p class="tds-headline-06 mt-5 mb-0 pb-0">SocialMediaContacts</p>
            <div class="bg-gray-50 p-1">
              <tds-dropdown
                ngDefaultControl
                name="dropdown"
                size="sm"
                id="Socialmediatype_code"
                formControlName="Socialmediatype_code"
                [defaultValue]="socialMediaContacts[0]?.Code"
                open-direction="down"
                #tegeldropDown
                (tdsChange)="handleAccessDropDown($event)"
              >
                @for (type of socialMediaContacts; track $index) {
                <tds-dropdown-option value="{{ type.Code}}">{{
                  type.Title
                }}</tds-dropdown-option>
                }
              </tds-dropdown>
            </div>
            <p class="tds-headline-06 mt-5 mb-0 pb-0">Phone Number</p>
            <div class="bg-gray-50 p-1">
              <tds-text-field
                ngDefaultControl
                type="Text"
                size="sm"
                formControlName="PhoneNumber"
                id="PhoneNumber"
                placeholder="Enter Phone Number"
              >
              </tds-text-field>
            </div>
            <p class="tds-headline-06 mt-5 mb-0 pb-0">Comment</p>
            <div class="bg-gray-50 p-1">
              <tds-text-field
                ngDefaultControl
                type="Text"
                size="sm"
                formControlName="Comment"
                id="Comment"
                placeholder="Enter Comment"
              >
              </tds-text-field>
            </div>
          </div>
        <div class="flex flex-row-reverse space-x-2 p-3 pl-5">
          <tds-button
            text="Save"
            size="sm"
            variant="primary"
            type="button"
            (click)="save()"
            style="padding: 10px"
          ></tds-button>
          <tds-button
            text="Cancel"
            size="sm"
            variant="secondary"
            (click)="cancelForm()"
          >
            <tds-icon slot="icon" size="15px" name="cross"></tds-icon>
          </tds-button>
        </div>
      </div>
    </form>
  </span>
</tds-modal>

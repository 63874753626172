import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DealerworkshopOwnershipService {
  dealerworkshopOwnerships: any;

  constructor(private http: HttpClient) {}

  getdealerworkshopOwnershipList() {
    return this.http
      .get(
        environment.baseUrl +
          environment.administrationServices.DealerworkshopOwnershipService
      )
      .pipe(retry(3));
  }

  getEditDealer(dealer: any) {
    this.dealerworkshopOwnerships = dealer;
  }

  updateDealerData(request: any) {
    return this.http.put(
      environment.baseUrl +
        environment.administrationServices.DealerworkshopOwnershipService +
        '/' +
        request.Code,
      request
    );
  }

  addDealerData(editForm: any) {
    return this.http.post(
      environment.baseUrl +
        environment.administrationServices.DealerworkshopOwnershipService,
      editForm.value
    );
  }

  getDisplayOrderUrl() {
    return (
      environment.baseUrl +
      environment.administrationServices.DealerworkshopOwnershipService
    );
  }
}

import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TegelModule } from '@scania/tegel-angular';

@Component({
  selector: 'app-unauthorized-msg',
  templateUrl: './unauthorized-msg.component.html',
  standalone: true,
  imports: [TegelModule],
})
export class UnauthorizedMsgComponent {
  constructor() {}
}

import { Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { AppService } from '../../../app.service';
import { UtilsService } from '../../utils.service';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-other-information',
  standalone: false,
  templateUrl: './other-information.component.html',
  styleUrl: './other-information.component.css',
})
export class OtherInformationComponent implements OnInit {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any = {};
  @Input() companyId?: string = '';
  @Input() infoBlockId: string = '';
  catFieldConfig: any;

  companyOtherCardsForm =
    inject(CompanyFormService).form.controls.CompanyOtherCards;
  otherTxtFocus = false;
  constructor(
    public utilService: UtilsService,
    public appService: AppService,
    private subjectService: SubjectService
  ) {}

  get OtherTxt() {
    return this.companyOtherCardsForm.get('OtherTxt');
  }

  get OtherCards_Title() {
    return this.catFieldConfig['Company_OtherCards_Title'] || '';
  }

  ngOnInit(): void {
    this.catFieldConfig = this.appService.fetchCatgoryFieldConfig();
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.otherTxtFocus = true;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.companyOtherCardsForm.patchValue({
        OtherTxt: this.companyData?.CompanyOtherCards?.OtherTxt,
        Company_id: this.companyData?.CompanyOtherCards?.Company_id,
      });
    } else {
      this.companyOtherCardsForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (this.companyData.CompanyOtherCards) {
        this.companyOtherCardsForm.patchValue({
          OtherTxt: this.companyData?.CompanyOtherCards?.OtherTxt,
          Company_id: this.companyData?.Id,
        });
      } else {
        this.companyData.CompanyOtherCards = {};
      }
    }
  }

  saveDraft() {
    this.companyData.CompanyOtherCards.OtherTxt =
      this.companyOtherCardsForm.get('OtherTxt')?.value;
    this.companyData.CompanyOtherCards.Company_id =
      this.companyOtherCardsForm.get('Company_id')?.value || this.myId;
    if (this.companyOtherCardsForm.dirty) {
      this.companyData.CompanyOtherCards.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { AppService } from '../../../app.service';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-used-vehicle-power-solutions',
  templateUrl: './used-vehicle-power-solutions.component.html',
  styleUrl: './used-vehicle-power-solutions.component.css'
})
export class UsedVehiclePowerSolutionsComponent {
  @Input() companyId: string = '';
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId:string ='';
  options: any;

  constructor(
    public CompanyService: CompanyService,
    private appService: AppService
  ) {}
  companyUsedVehicleAndPowerSolutionsForm =
    inject(CompanyFormService).form.controls.CompanyUsedVehicleAndPowerSolutions;

  ngOnChanges(changes: SimpleChanges): void {
    this.CompanyService.getUsedvehicleandpowersolutions().subscribe((data: any) => {
      this.options = data;
    });
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
    } else {
      this.companyUsedVehicleAndPowerSolutionsForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
    }
    if (!this.companyData.CompanyUsedVehicleAndPowerSolution) {
      this.companyData.CompanyUsedVehicleAndPowerSolution = {};
      this.companyUsedVehicleAndPowerSolutionsForm.controls.Company_id.setValue(this.companyData.Id ||this.myId)
    } else {
      this.companyUsedVehicleAndPowerSolutionsForm.patchValue({
        UsedVehicleAndPowerSolutions_Code: this.companyData.CompanyUsedVehicleAndPowerSolution.UsedVehicleAndPowerSolutions_Code,
        Company_id: this.companyData.Id ||this.myId
      })
    }
  }

  handleChecked(code: any) {
    this.companyUsedVehicleAndPowerSolutionsForm.controls.UsedVehicleAndPowerSolutions_Code.setValue(code);
    this.companyUsedVehicleAndPowerSolutionsForm.markAsDirty();
    this.saveDraft();
  }

  saveDraft() {
    this.companyData.CompanyUsedVehicleAndPowerSolution = this.companyUsedVehicleAndPowerSolutionsForm.value;
    if (this.companyUsedVehicleAndPowerSolutionsForm.dirty) {
      this.companyData.CompanyUsedVehicleAndPowerSolution.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-payment-methods',
  standalone: false,
  templateUrl: './payment-methods.component.html',
  styleUrl: './payment-methods.component.css',
})
export class PaymentMethodsComponent implements OnChanges {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() companyId?: string = '';
  @Input() infoBlockId:string ='';
  
  paymentMethods: any;
  paymentMethodArr: any = [];
  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    private appService: AppService
  ) {}
  paymentMethodForm =
    inject(CompanyFormService).form.controls.paymentMethodForm;

  ngOnChanges(changes: SimpleChanges): void {
    this.CompanyService.getPaymentMethods().subscribe((data: any) => {
      this.paymentMethods = data;
      this.paymentMethods = this.paymentMethods.sort((a:any,b:any)=> a?.DisplayOrder?.localeCompare(b?.DisplayOrder))
    });
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.paymentMethodArr = [...this.companyData.CompanyAcceptedCreditCards];
    } else {
      this.paymentMethodForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (!this.companyData.CompanyAcceptedCreditCards) {
        this.companyData.CompanyAcceptedCreditCards = [];
      }
    }
  }

  handleChecked(id: any) {
    let index = this.paymentMethodArr.findIndex(
      (item: any) => item.Paymentcard_id === id
    );
    if (index !== -1) {
      this.paymentMethodArr.splice(index, 1);
      this.paymentMethodForm.markAsDirty();
    } else {
      if (this.mode === 'Add') {
        this.paymentMethodArr.push({
          Paymentcard_id: id,
        });
      } else {
        this.paymentMethodArr.push({
          Paymentcard_id: id,
          Company_id: this.companyData.myId,
        });
      }
    }
    this.paymentMethodForm.markAsDirty();
    this.saveDraft();
  }

  isAvailabel(id: any) {
    let value = this.companyData.CompanyAcceptedCreditCards.find(
      (i: any) => i.Paymentcard_id === id
    );
    if (value === undefined || '') {
      return false;
    }
    return true;
  }

  saveDraft() {
    if (this.paymentMethodForm.dirty) {
      this.companyData.CompanyAcceptedCreditCards.forEach(
        (item: any) => (item.IsUpdated = true)
      );
      //  this.companyData.CompanyAcceptedCreditCards.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.companyData.CompanyAcceptedCreditCards = [...this.paymentMethodArr];
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.companyData.CompanyAcceptedCreditCards = [...this.paymentMethodArr];
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-has-scania-assistance',
  standalone: false,
  templateUrl: './has-scania-assistance.component.html',
  styleUrl: './has-scania-assistance.component.css',
})
export class HasScaniaAssistanceComponent implements OnChanges {
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId:string ='';

  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    public appService: AppService
  ) {}
  HasAssistanceForm =
    inject(CompanyFormService).form.controls.CompanyAssistance;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.HasAssistanceForm.patchValue({
        IsScaniaAssistance:
          this.companyData?.CompanyAssistance?.IsScaniaAssistance,
        Company_id: this.companyData?.CompanyAssistance?.Company_id,
      });
    } else {
      this.HasAssistanceForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();

      this.HasAssistanceForm.patchValue({
        IsScaniaAssistance:
          this.companyData?.CompanyAssistance?.IsScaniaAssistance,
        Company_id: this.companyData?.CompanyAssistance?.Company_id,
      });
    }
    if (!this.companyData.CompanyAssistance) {
      this.companyData.CompanyAssistance = {};
    }
  }

  get IsScaniaAssistanceLabel(){
    return this.appService.getLabelText('IsScaniaAssistance',this.infoBlockId) || '';
  }

  handleChecked(event: any) {
    if (event.target.checked) {
      this.HasAssistanceForm.controls.IsScaniaAssistance.setValue(true);
    } else {
      this.HasAssistanceForm.controls.IsScaniaAssistance.setValue(false);
    }

    this.HasAssistanceForm.markAsDirty()
    this.saveDraft()
  }

  saveDraft() {
    this.companyData.CompanyAssistance.IsScaniaAssistance =
      this.HasAssistanceForm?.get('IsScaniaAssistance')?.value;

      if (this.HasAssistanceForm.dirty) {
        this.companyData.CompanyAssistance.IsUpdated = true;
      }

    if (this.mode !== 'Add') {
     

      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

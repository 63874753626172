import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { DealerworkshopOwnershipService } from '../../../services/dealerworkshop-ownership.service';
import { DealerworkshopTypesService } from '../../../services/dealerworkshop-types.service';
import { forkJoin, Subject } from 'rxjs';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';
import { UtilsService } from '../../utils.service';
import { DatePipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-dealer-workshop',
  templateUrl: './dealer-workshop.component.html',
  styleUrl: './dealer-workshop.component.css',
})
export class DealerWorkshopComponent implements OnChanges, OnInit {
  distributorList: any;
  selectedValues: any = {};
  uniqueDealerTypeList: any;
  @Input() infoBlockId: string = '';
  constructor(
    private dealerworkshopOwnershipService: DealerworkshopOwnershipService,
    private dealerworkshopTypesService: DealerworkshopTypesService,
    private companyService: CompanyService,
    public appService: AppService,
    public utilService: UtilsService,
    public companyFormService: CompanyFormService,
    private subjectService: SubjectService
  ) {}
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  companyDealerWorkshopForm =
    inject(CompanyFormService).form.controls.CompanyDealerWorkshop;
  ownershipOptions: any;
  workshopTypes: any;
  dealerworkshopList: any;
  dealerIdFocus: boolean = false;
  parentIdFocus: boolean = false;
  dealerIdlist: any[] = [];
  uniqueCountryList: any[] = [];
  rowsPerPage = 10;
  pageOptions: number[] = [10, 20, 30];
  page = 0;
  pages = Math.ceil(this.dealerIdlist.length / this.rowsPerPage);
  tableData = this.dealerIdlist.slice(this.page, this.page + this.rowsPerPage);
  parentDealer = {
    id: '',
    name: '',
    dealerId: '',
  };

  dealerItems: any = {};

  dealertypeOptions: any = {
    DealerWorkshopType_code: '',
  };

  ownership: any = {
    DealerWorkshopOwnership_code: '',
  };

  @ViewChild('recursiveList', { static: true }) recursiveList: any;
  @ViewChild('dealerModal', { static: true }) dealerModal: any;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.companyFormService.checkAllFormValidationDetails()) {
      this.companyDealerWorkshopForm.controls.DealerId.addValidators(
        Validators.required
      );
      this.companyDealerWorkshopForm.controls.DealerWorkshopType_code.addValidators(
        Validators.required
      );
      this.companyDealerWorkshopForm.controls.DealerWorkshopOwnership_code.addValidators(
        Validators.required
      );
    } else {
      this.companyDealerWorkshopForm.controls.DealerId.removeValidators(
        Validators.required
      );
      this.companyDealerWorkshopForm.controls.DealerWorkshopType_code.removeValidators(
        Validators.required
      );
      this.companyDealerWorkshopForm.controls.DealerWorkshopOwnership_code.removeValidators(
        Validators.required
      );
    }

    if (this.mode == 'Add' && this.appService.validationRequired()) {
      this.companyDealerWorkshopForm.controls.ParentId.addValidators(
        Validators.required
      );
    } else {
      this.companyDealerWorkshopForm.controls.ParentId.removeValidators(
        Validators.required
      );
    }

    const $ownershipOptions =
      this.dealerworkshopOwnershipService.getdealerworkshopOwnershipList();
    const $workshopTypes =
      this.dealerworkshopTypesService.getdealerworkshopTypeList();

    forkJoin([$ownershipOptions, $workshopTypes]).subscribe(
      ([ownershipOptions, workshopTypes]) => {
        if (ownershipOptions && workshopTypes) {
          this.ownershipOptions = ownershipOptions;
          this.ownershipOptions = this.ownershipOptions.sort((a:any,b:any)=> 
            a?.Displayorder?.localeCompare(b?.Displayorder));
          this.workshopTypes = workshopTypes;

          if (changes['companyData'].currentValue && this.mode !== 'Add') {
            this.companyData = changes['companyData'].currentValue;
          } else {
            this.companyDealerWorkshopForm.reset();
            this.companyData = this.appService.fetchCompanyAddDraft();
          }

          if (!this.companyData.CompanyDealerWorkshop)
            this.companyData.CompanyDealerWorkshop = {};
          //TODO : comment for  change in CATNoRequired field validation
          // if (
          //   this.companyData?.CompanyDealerWorkshop &&
          //   !this.companyData?.CompanyDealerWorkshop?.DealerWorkshopType_code
          // )
          //   this.companyData.CompanyDealerWorkshop.DealerWorkshopType_code =
          //     this.workshopTypes[0]?.Code;

          // if (
          //   this.companyData?.CompanyDealerWorkshop &&
          //   !this.companyData?.CompanyDealerWorkshop
          //     ?.DealerWorkshopOwnership_code
          // )
          //   this.companyData.CompanyDealerWorkshop.DealerWorkshopOwnership_code =
          //     this.ownershipOptions[0]?.Code;

          this.companyDealerWorkshopForm.patchValue({
            Company_id: this.companyData?.Id,
            DealerId: this.companyData?.CompanyDealerWorkshop?.DealerId,
            DealerWorkshopOwnership_code:
              this.companyData?.CompanyDealerWorkshop
                ?.DealerWorkshopOwnership_code,
            DealerWorkshopType_code:
              this.companyData?.CompanyDealerWorkshop?.DealerWorkshopType_code,
            Termination: this.companyData?.CompanyDealerWorkshop?.Termination,

            comment: this.companyData?.CompanyDealerWorkshop?.comment,
            notice: this.companyData?.CompanyDealerWorkshop?.notice,
            ParentId: this.companyData?.CompanyDealerWorkshop?.ParentId,
          });

          this.dealertypeOptions.DealerWorkshopType_code =
            this.companyData?.CompanyDealerWorkshop?.DealerWorkshopType_code;
          //Emiting value for product & services
          // this.subjectService.emitDealerType(
          //   this.dealertypeOptions.DealerWorkshopType_code
          // );
          this.ownership.DealerWorkshopOwnership_code =
            this.companyData?.CompanyDealerWorkshop?.DealerWorkshopOwnership_code;

          this.companyService
            .getDealerRelationTree(this.companyData.Id)
            .subscribe((res: any) => {
              if (res?.Items && res.Items.length > 0) {
                this.companyData.CompanyDealerWorkshop.Parent_DealerId =
                  res.Items[0].Id;
                this.companyData.CompanyDealerWorkshop.ParentDistributor_Id =
                  res.Items[res.Items.length - 1].Id;
                this.dealerItems = this.arrayToTree(res.Items);
                console.log(this.dealerItems);
              }
            });
        }
      }
    );
  }

  ngOnInit(): void {
    const $ownershipOptions =
      this.dealerworkshopOwnershipService.getdealerworkshopOwnershipList();
    const $workshopTypes =
      this.dealerworkshopTypesService.getdealerworkshopTypeList();

    forkJoin([$ownershipOptions, $workshopTypes]).subscribe(
      ([ownershipOptions, workshopTypes]) => {
        if (ownershipOptions && workshopTypes) {
          this.ownershipOptions = ownershipOptions;
          this.ownershipOptions = this.ownershipOptions.sort((a:any,b:any)=> 
            a?.Displayorder?.localeCompare(b?.Displayorder));
          this.workshopTypes = workshopTypes;
        }
      }
    );
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.dealerIdFocus = true;
      }

      if (this.mode == 'Add' && this.appService.validationRequired()) {
        this.parentIdFocus = true;
      }
    });

    if (!this.appService.validationRequired())
      this.companyDealerWorkshopForm.removeValidators(Validators.required);
  }

  get DealerWorkshopType_code() {
    return this.companyDealerWorkshopForm.get('DealerWorkshopType_code');
  }

  get DealerWorkshopOwnership_code() {
    return this.companyDealerWorkshopForm.get('DealerWorkshopOwnership_code');
  }

  get DealerId() {
    return this.companyDealerWorkshopForm.get('DealerId');
  }

  get Required() {
    return this.companyFormService.checkAllFormValidationDetails();
  }

  get DealerIdLabel() {
    return this.appService.getLabelText('DealerId', this.infoBlockId) || '';
  }

  get DealerIdPopupText() {
    return this.appService.getPopupHelpText('DealerId', this.infoBlockId) || '';
  }

  handlePrevPage(event: any) {
    if (event >= 0 && event < this.pages) {
      this.page = Number(event);

      const startIndex = Number(event) * Number(this.rowsPerPage);
      const endIndex = startIndex + Number(this.rowsPerPage);
      let tableData = this.filterData();
      this.tableData = tableData.slice(startIndex, endIndex);
    }
  }

  filterData() {
    let tableData: any = this.dealerIdlist;
    for (let key in this.selectedValues) {
      tableData = tableData.filter((obj: any) =>
        obj[key]
          ?.toLowerCase()
          .trim()
          .includes(this.selectedValues[key]?.toLowerCase().trim())
      );
    }
    return tableData;
  }

  updateRowCount(event: any) {
    if (event.detail.value) {
      this.rowsPerPage = Number(event.detail.value);
      let tableData = this.filterData();
      this.page = 0;
      this.pages = Math.ceil(tableData.length / this.rowsPerPage);
      this.tableData = tableData.slice(this.page, this.page + this.rowsPerPage);
    }
  }

  filterColumns(event: any, columnName: string) {
    if (event?.target?.value || event?.value) {
      this.selectedValues[columnName] = event?.target?.value
        ? event.target.value
        : event.value;

      let tableData: any = this.dealerIdlist;

      for (let key in this.selectedValues) {
        if (key == 'Type' && this.selectedValues[key]) {
          tableData = tableData.filter((obj: any) =>
            obj[key]
              ?.toLowerCase()
              .trim()
              .includes(this.selectedValues[key]?.toLowerCase().trim())
          );
        } else {
          tableData = tableData.filter((obj: any) => !obj[key]);
        }
      }
      this.page = 0;

      this.pages = Math.ceil(tableData.length / Number(this.rowsPerPage));
      this.tableData = tableData.slice(
        this.page,
        this.page + Number(this.rowsPerPage)
      );
    } else {
      this.page = 0;

      if (this.selectedValues[columnName])
        delete this.selectedValues[columnName];
      let tableData: any = this.dealerIdlist;
      for (let key in this.selectedValues) {
        tableData = tableData.filter((obj: any) =>
          obj[key]
            ?.toLowerCase()
            .trim()
            .includes(this.selectedValues[key]?.toLowerCase().trim())
        );
      }
      this.pages = Math.ceil(tableData.length / Number(this.rowsPerPage));
      this.tableData = tableData.slice(
        this.page,
        this.page + Number(this.rowsPerPage)
      );
    }
  }

  showDealersAndDistributors() {
    if (this.dealerIdlist.length == 0) {
      let companyId: string = this.companyData.Id;
      const $dealerworkshopList =
        this.companyService.getCompanyList('dealerworkshops');
      const $distributorList =
        this.companyService.getCompanyList('Distributor');
      // if (this.mode == 'Add') {
      //   companyId = '';
      // }
      const $dealerIdList =
        this.companyService.getDealerAccessToCompany(companyId);

      forkJoin([$dealerIdList]).subscribe(([dealerIdList]) => {
        if (dealerIdList) {
          // this.dealerworkshopList = dealerworkshopList;
          // this.distributorList = distributorList;

          this.dealerIdlist = dealerIdList as any[];
          this.dealerIdlist.sort((a: any, b: any) => {
            return a.Country.localeCompare(b.Country);
          });
          this.uniqueCountryList = this.dealerIdlist.reduce((acc, obj) => {
            if (!acc.some((o: any) => o.Country === obj.Country)) {
              acc.push(obj);
            }
            return acc;
          }, []);
          // this.uniqueDealerTypeList = this.dealerIdlist.reduce((acc, obj) => {
          //   if (!acc.some((o: any) => o.Category_Name === obj.Category_Name)) {
          //     acc.push(obj);
          //   }
          //   return acc;
          // }, []);

          let filterTableData = JSON.parse(JSON.stringify(this.dealerIdlist));

          this.pages = Math.ceil(filterTableData.length / this.rowsPerPage);
          this.tableData = filterTableData.slice(
            this.page,
            this.page + this.rowsPerPage
          );

          this.dealerModal.showModal();
          // console.log('Drealers' + this.dealerIdlist);
        }
      });
    } else {
      this.dealerModal.showModal();
    }
  }
  edit(
    id: string,
    ParentDealerName: string,
    ParentDealerId: string = '',
    country: string
  ) {
    //check Access
    if (
      id &&
      country &&
      this.companyData?.Id &&
      this.appService.fetchSelectedCategoryData()
    ) {
      let categoryId: string = this.appService.fetchSelectedCategoryData().Id;

      this.parentDealer.id = id;
      this.parentDealer.name = ParentDealerName;
      this.parentDealer.dealerId = ParentDealerId;

      this.companyService
        .getDealerRelationTreeByDealerId(
          this.companyData.Id,
          this.parentDealer.id
        )
        .subscribe((res: any) => {
          this.dealerItems = this.arrayToTree(res.Items);

          this.companyData.CompanyDealerWorkshop.Parent_DealerId =
            res.Items[0].Id;
          this.companyData.CompanyDealerWorkshop.ParentDistributor_Id =
            res.Items[res.Items.length - 1].Id;

          this.companyData.CompanyDealerWorkshop.ParentId = id;
          this.companyDealerWorkshopForm.get('ParentId')?.setValue(id);
        });

      this.dealerModal.closeModal();
      this.parentIdFocus = false;
      this.saveDraft();
    }
  }

  handleOwnershipChecked(code: string) {
    this.ownership.DealerWorkshopOwnership_code = code;
    this.companyDealerWorkshopForm.controls.DealerWorkshopOwnership_code.setValue(
      this.ownership.DealerWorkshopOwnership_code
    );
  }

  handleTypeChecked(code: string) {
    this.dealertypeOptions.DealerWorkshopType_code = code;
    this.subjectService.emitDealerType(
      this.dealertypeOptions.DealerWorkshopType_code
    );
    this.companyDealerWorkshopForm.controls.DealerWorkshopType_code.setValue(
      this.dealertypeOptions.DealerWorkshopType_code
    );
  }

  arrayToTree(arr: any[]) {
    let result: any = null;
    arr.reverse().forEach((item: any) => {
      result = { ...item, children: result ? [result] : [] };
    });
    return result;
  }

  saveDraft() {
    this.companyData.CompanyDealerWorkshop.DealerId =
      this.companyDealerWorkshopForm.get('DealerId')?.value;
    this.companyData.CompanyDealerWorkshop.DealerWorkshopType_code =
      this.dealertypeOptions.DealerWorkshopType_code;
    this.companyData.CompanyDealerWorkshop.DealerWorkshopOwnership_code =
      this.ownership.DealerWorkshopOwnership_code;
    this.companyData.CompanyDealerWorkshop.comment =
      this.companyDealerWorkshopForm.get('comment')?.value;
    this.companyData.CompanyDealerWorkshop.notice =
      this.companyDealerWorkshopForm.get('notice')?.value;
    this.companyData.CompanyDealerWorkshop.Termination =
      this.companyDealerWorkshopForm.get('Termination')?.value;

    if (this.parentDealer?.id)
      this.companyData.CompanyDealerWorkshop.ParentId = this.parentDealer?.id;

    if (this.companyDealerWorkshopForm.dirty) {
      this.companyData.CompanyDealerWorkshop.IsUpdated = true;
    }
    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }

  setNoticeValue(event: any) {
    this.companyDealerWorkshopForm.controls.notice.setValue(
      event.target.value + 'T00:00:00'
    );
  }

  setTerminationValue(event: any) {
    this.companyDealerWorkshopForm.controls.Termination.setValue(
      event.target.value + 'T00:00:00'
    );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressTypeService {
  constructor(private http: HttpClient) {}

  getAddressTypes() {
    return this.http
      .get(
        environment.baseUrl +
          environment.administrationServices.addressTypeService
      )
      .pipe(retry(3));
  }

  updateAddressData(editForm: any) {
    return this.http.put(
      environment.baseUrl +
        environment.administrationServices.addressTypeService +
        `/` +
        editForm.Code,
      editForm
    );
  }

  addAddressData(editForm: any) {
    return this.http.post<any>(
      environment.baseUrl +
        environment.administrationServices.addressTypeService,
      editForm
    );
  }

  getDisplayOrderUrl() {
    return (
      environment.baseUrl +
      environment.administrationServices.addressTypeService
    );
  }

}

<form [formGroup]="companyUsedVehicleAndPowerSolutionsForm" (change)="saveDraft()">
    <div
      class="flex flex-row justify-start"
      style="padding: 10px; max-height: 350px; overflow-y: auto"
    >
          @for(option of options; track $index ;let infoIndex =
          $index){
          <div class="pr-2">
            <tds-radio-button
              name="{{ option.Code }}"
              radio-id ="{{ option.Code }}"
              checked="{{ option.Code === companyUsedVehicleAndPowerSolutionsForm.value.UsedVehicleAndPowerSolutions_Code }}"
              class="pt-1"
              (tdsChange)="handleChecked(option.Code)"
            >
              <div slot="label">
                {{ option.Title }}
              </div>
            </tds-radio-button>
          </div>
          }
        </div>
  </form>
  
import { Component, OnInit, output } from '@angular/core';
import { TegelModule } from '@scania/tegel-angular';
import { UpperCasePipe, CommonModule } from '@angular/common';
import { SubjectService } from '../../services/subject.service';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../app.service';
import { UserManualService } from '../../services/user-manual.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TegelModule, UpperCasePipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {

  title = 'SIS Update';
  mobileNavOpen = false;

  mode: 'tds-mode-light' | 'tds-mode-dark' = 'tds-mode-light';
  modeVariant: 'tds-mode-variant-primary' | 'tds-mode-variant-secondary' =
    'tds-mode-variant-primary';
  isAuthenticated = false;
  userName = '';
  email? = '';

  constructor(
    private subjectService: SubjectService,
    public authService: AuthService,
    private userManualService: UserManualService
  ) {
    this.subjectService.leftMenuNavSubject.subscribe((data: boolean) => {
      this.mobileNavOpen = data;
    });

    this.isAuthenticated = this.authService.isAuthenticated;
    this.userName = this.authService.identityClaims?.given_name + " " + this.authService.identityClaims?.family_name;
    this.email = this.authService.identityClaims?.email;
  }

  ngOnInit() {
    this.authService.isAuthenticatedObservable().subscribe(isAuthenticated => {

      this.isAuthenticated = isAuthenticated;
      this.userName = this.authService.identityClaims?.given_name + " " + this.authService.identityClaims?.family_name;
      this.email = this.authService.identityClaims?.email;

    });
  }

  toggleMobileSideMenu() {
    this.mobileNavOpen = !this.mobileNavOpen;
    this.subjectService.toggleLeftMenuNavSubject(this.mobileNavOpen);
  }
  logout() {
    return this.authService.logout();
  }

  openUserManual() {

    this.userManualService.downloadUserManual().subscribe((res: any) => {
      let blob: Blob = new Blob([res.body], { type: 'appplication/pdf' });
      let url = URL.createObjectURL(blob);

      let a = document.createElement('a');
      a.download = "sismanual.pdf";
      a.setAttribute('target', "_blank");
      a.href = url;
      a.click();

    });

  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhoneTypeService {
  constructor(private http: HttpClient) {}

  getPhoneTypes() {
    return this.http
      .get(
        environment.baseUrl +
          environment.administrationServices.phoneTypeService
      )
      .pipe(retry(3));
  }

  updatePhoneData(editForm: any) {
    return this.http.put(
      environment.baseUrl +
        environment.administrationServices.phoneTypeService +
        '/' +
        editForm.Code,
      editForm
    );
  }

  addPhoneData(editForm: any) {
    return this.http.post<any>(
      environment.baseUrl + environment.administrationServices.phoneTypeService,
      editForm
    );
  }

  getDisplayOrderUrl() {
    return (
      environment.baseUrl +
      environment.administrationServices.phoneTypeService
    );
  }

}

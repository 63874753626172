@if(subjectService.getLoaderSubject() | async){
<div
  class="spinner w-screen min-h-screen fixed"
  style="z-index: 2; background-color: rgba(0, 0, 0, 0.5)"
>
  <div
    style="
      margin: 0 auto;
      text-align: center;
      position: relative;
      margin-top: 15%;
    "
  >
    <tds-spinner size="lg" variant="standard"></tds-spinner>
  </div>
</div>
}
<app-header *ngIf="!hideHeader" />
<div class="flex">
  <app-left-menu *ngIf="!hideLeftMenu" />

  <div class="flex-1" [ngClass]="navOpen ? 'width-expanded' : 'width-default'">
    <router-outlet />
  </div>
</div>

@if(showToast){
<div class="overlay" (click)="showToast = false">
  <tds-toast
    [variant]="toastVariant"
    [header]="toastMessage"
    [subheader]="toastSubMessage"
    closable="true"
    (tdsClose)="showToast = false"
    class="customToast"
  >
  </tds-toast>
</div>

}

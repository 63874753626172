<form [formGroup]="distributorOptionsForm" (change)="saveDraft()">
  <div
    class="flex flex-row"
    style="padding: 10px; max-height: 350px; overflow-y: auto"
  >
    <div>
      <div>
        <p *ngIf="distributorCode?.invalid" class="text-[#ff2340] text-xs p-0 m-0">
          <tds-icon
            name="info"
            class="text-[#ff2340] pb-1 pr-2"
            id="Mandatory"
            size="16"
          ></tds-icon
          >Scania Ownership is requied
        </p>
        @for(imports of distributorOptions; track $index ;let infoIndex =
        $index){
        <div class="flex justify-between">
          <tds-radio-button
            name="{{ imports.Code }}"
            radio-id="{{ imports.Code }}"
            checked="{{ imports.Code === options[0]?.Distributoroption_code }}"
            class="pt-1"
            (tdsChange)="handleChecked(imports.Code)"
          >
            <div slot="label">
              {{ imports.Title }} <span *ngIf="required" class="text-red-600">*</span>
            </div>
          </tds-radio-button>
        </div>
        }
      </div>
    </div>
  </div>
</form>

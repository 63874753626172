import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { SubjectService } from './services/subject.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { toastMode } from './models/shared.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  hideLeftMenu: boolean = false;
  hideHeader: boolean = false;

  title = 'SIS Update';
  navOpen: boolean = false;
  showToast: boolean = false;
  toastMessage: string = '';
  toastVariant: string = 'success';
  toastSubMessage: string = 'Your changes have been saved';

  constructor(
    public subjectService: SubjectService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.getRouteData();

    this.subjectService.sideMenuNavSubject.subscribe((data: boolean) => {
      this.navOpen = data;
    });

    this.subjectService.toastSubject.subscribe((toastObj: any) => {
      if (toastObj.message) {
        this.showToast = true;
        this.toastMessage = toastObj.message;
        this.toastVariant = toastObj.mode;
        this.toastSubMessage = toastObj.subMessage;
        setTimeout(() => {
          this.showToast = false;
        }, 5000);
      }
    });
  }

  private getRouteData() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.hideLeftMenu = event['hideLeftMenu'];
        this.hideHeader = event['hideHeader'];
      });
  }
  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AddressTypeService } from '../../../services/address-type.service';
import { User } from '../../../models/user.model';

@Component({
  selector: 'app-adress-types-home',
  templateUrl: './adress-types-home.component.html',
  styleUrl: './adress-types-home.component.css',
})
export class AdressTypesHomeComponent implements OnInit {
  addressTypes: addressTypes[] = [];
  addressType: any;
  mode: string = '';
  highestDisplayOrder: string = '';
  displayOrderUrl: string = '';
  user: User | undefined;
  actionList: ActionList[] = [];
  selectedValues: any = {};

  constructor(private addressService: AddressTypeService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.displayOrderUrl = this.addressService.getDisplayOrderUrl();
    this.addressService.getAddressTypes().subscribe((data: any) => {
      this.addressTypes = data;
      this.addressTypes = this.addressTypes.filter((item: any) => {
        return !Number.isNaN(parseInt(item.Displayorder));
      });
      this.addressTypes.sort((a: any, b: any) => {
        return Number(a.Displayorder) - Number(b.Displayorder);
      });
      this.highestDisplayOrder =
        this.addressTypes[this.addressTypes.length - 1].Displayorder;
    });

    this.actionList.push({
      name: 'List',
      active: true,
      id: '',
      displayOrderUrl: this.displayOrderUrl,
      data: {
        Name: '',
        Code: '',
        Displayorder: '',
      },
    });
  }

  getAddressType(data: addressTypes) {
    this.handleEdit(data);
    //this.addressType = data;
  }

  discardAll() {
    this.mode = '';
    this.actionList = this.actionList.filter((item) => item.name == 'List');
    this.actionList[0].active = true;
  }

  //Navigation event for tab
  setMode(mode: any) {
    this.mode = mode;
    this.actionList = this.actionList.filter(
      (item: any) => this.actionList.indexOf(item) !== this.selectedIndex
    );
    if (this.actionList && this.actionList.length)
      this.actionList[this.actionList.length - 1].active = true;
  }

  updatedList(obj: any) {
    let index = this.addressTypes.findIndex((item) => item.Code === obj.Code);
    if (index !== -1) {
      this.addressTypes[index] = obj;
    } else {
      this.addressTypes.push(obj);
    }
  }

  getMode(mode: any) {
    this.mode = mode;
    this.handleAdd();
  }

  //Handle for Add
  handleAdd() {
    if (this.mode == 'Add') {
      if (!this.actionList.some((item: any) => item.name == 'Add')) {
        this.actionList.forEach((item: any) => (item.active = false));
        let obj = {
          name: 'Add',
          active: true,
          id: 'Add',
          displayOrderUrl: this.displayOrderUrl,
          data: {
            Name: '',
            Code: '',
            Displayorder: '',
          },
          mode: 'Add',
        };
        this.actionList.push(obj);
        this.actionList.forEach((item: any) => (item.active = false));
        this.actionList[this.actionList.length - 1].active = true;
      } else {
        let addItem = this.actionList.find((item) => item.name === 'Add');
        this.actionList.forEach((item: any) => (item.active = false));
        if (addItem) addItem.active = true;
      }
    }
  }

  get selectedIndex() {
    return Number(this.actionList.findIndex((ele: any) => ele.active));
  }

  onEmitIndex(event: number) {
    this.actionList.forEach((item: any) => (item.active = false));
    let addItem = this.actionList.find(
      (item: any) => this.actionList.indexOf(item) == event
    );
    if (addItem) addItem.active = true;
  }

  onEmitDeleteIndex(event: number) {
    this.actionList.forEach((item: any) => (item.active = false));
    this.actionList = this.actionList.filter(
      (item: any) => this.actionList.indexOf(item) !== event
    );
    this.actionList[this.actionList.length - 1].active = true;
    this.mode = this.actionList[this.actionList.length - 1]?.mode || '';
  }

  //Handle for Edit Tab
  handleEdit(data: addressTypes) {
    this.actionList.forEach((item: any) => (item.active = false));
    let selectedActionList = this.actionList.find(
      (item: ActionList) => item.id == data.Code
    );
    if (selectedActionList) {
      selectedActionList.active = true;
    } else {
      let clonedData = Object.assign({}, data);
      let obj = {
        name: clonedData.Name,
        active: true,
        id: clonedData.Code,
        displayOrderUrl: this.displayOrderUrl,
        data: {
          Name: clonedData.Name,
          Code: clonedData.Code,
          Displayorder: clonedData.Displayorder,
        },
        mode: 'Edit',
      };
      this.actionList.push(obj);
    }
  }
}

export interface addressTypes {
  Name: string;
  Code: string;
  Displayorder: string;
}

export interface ActionList {
  name: string;
  active: boolean;
  id: string;
  mode?: string;
  highestDisplayOrder?: string;
  displayOrderUrl: string;
  data: addressTypes;
}

<form
  [formGroup]="phoneFaxForm"
  (change)="saveDraft()"
  class="p-2"
  [style]="this.mode == 'print' ? '' : 'max-height: 400px; overflow: auto'"
>
  <!-- <table class="sistable table table-responsive">
    <thead>
      <th class="table-head-cell" style="border-top-left-radius: 5px">Type</th>
      <th class="table-head-cell" style="border-top-left-radius: 5px">
        Phone/Fax number
      </th>
      <th class="table-head-cell" style="border-top-left-radius: 5px">
        Comment
      </th>
    </thead>

    <tbody>
      <tr></tr>
      @for(item of this.controlArr;track $index){ @if(item.maxFieldLengthArr &&
      item.maxFieldLengthArr.length >1){ @for( fields of item.maxFieldLengthArr;
      track $index; let idx = $index){
      <tr>
        <td>
          {{ item.Name }}
          @if(item.required && item.minFieldLength-1 ==idx){<span
            class="text-red-600"
            >*</span
          >}
        </td>

        <td>
          <tds-text-field
            ngDefaultControl
            type="Text"
            size="sm"
            state="default"
            label="Label"
            label-position="no label"
            no-min-width
            formControlName="{{ item.Code }}{{ idx > 0 ? idx : '' }}"
            (tdsFocus)="item.Focus = true"
            (tdsBlur)="item.Focus = true"
            [state]="
              getCurrentControl(item.Code, idx)?.invalid &&
              (getCurrentControl(item.Code, idx)?.dirty ||
                getCurrentControl(item.Code, idx)?.touched ||
                item.Focus)
                ? 'error'
                : 'success'
            "
            [helper]="
              utilService.getErrorMsgs(
                getCurrentControl(item.Code, idx),
                item.Name,
                item.Focus,
                3,
                item.Message
              )
            "
          ></tds-text-field>
        </td>
        <td>
          <tds-text-field
            ngDefaultControl
            type="Text"
            size="sm"
            state="default"
            label="Label"
            label-position="no label"
            no-min-width
            formControlName="{{ item.Code }}{{ idx > 0 ? idx : '' }}_comment"
          ></tds-text-field>
        </td>
      </tr>
      } } @else {
      <tr>
        <td>
          {{ item.Name }} @if(item.required ){<span class="text-red-600">*</span
          >}
        </td>
        <td>
          <tds-text-field
            ngDefaultControl
            type="Text"
            size="sm"
            state="default"
            label="Label"
            label-position="no label"
            no-min-width
            formControlName="{{ item.Code }}"
            (tdsFocus)="item.Focus = true"
            (tdsBlur)="item.Focus = true"
            [state]="
              getCurrentControl(item.Code)?.invalid &&
              (getCurrentControl(item.Code)?.dirty ||
                getCurrentControl(item.Code)?.touched ||
                item.Focus)
                ? 'error'
                : 'success'
            "
            [helper]="
              utilService.getErrorMsgs(
                getCurrentControl(item.Code),
                item.Name,
                item.Focus,
                3,
                item.Message
              )
            "
          ></tds-text-field>
        </td>
        <td>
          <tds-text-field
            ngDefaultControl
            type="text"
            size="sm"
            state="default"
            label="Label"
            label-position="no label"
            no-min-width
            formControlName="{{ item.Code }}_comment"
          ></tds-text-field>
        </td>
      </tr>
      } }
    </tbody>
  </table> -->

  @for(item of this.controlArr;track $index){ @if(item.maxFieldLengthArr &&
  item.maxFieldLengthArr.length >1){ @for( fields of item.maxFieldLengthArr;
  track $index; let idx = $index){
  <div class="flex flex-wrap mb-4">
    <div
      class="inputField w-full md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
    >
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ item.Name }}
          @if(isRequired(item.Code,idx)){<span class="text-red-600">*</span>}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('phone no', infoBlockId) }}"
          selector="{{ '#phoneno-' + $index + '-tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'phoneno-' + $index + '-tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          state="default"
          label="Label"
          label-position="no label"
          no-min-width
          formControlName="{{ item.Code }}{{ idx > 0 ? idx : '' }}"
          (tdsFocus)="item.Focus = true"
          (tdsBlur)="item.Focus = true"
          [state]="
            getCurrentControl(item.Code, idx)?.invalid &&
            (getCurrentControl(item.Code, idx)?.dirty ||
              getCurrentControl(item.Code, idx)?.touched ||
              item.Focus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code, idx),
              item.Name,
              item.Focus,
              3,
              item.Message
            )
          "
        ></tds-text-field>
      </div>
    </div>
    <div
      class="inputField w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 3xl:w-1/2"
    >
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("Comment", infoBlockId) }}
      </p>

      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          state="default"
          label="Label"
          label-position="no label"
          no-min-width
          formControlName="{{ item.Code }}{{ idx > 0 ? idx : '' }}_comment"
          placeholder="Comment if any"
        ></tds-text-field>
      </div>
    </div>
  </div>
  } } @else {
  <div class="flex flex-wrap mb-4">
    <div
      class="inputField w-full md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 3xl:w-2/5"
    >
      <div class="flex justify-between">
        <p class="tds-headline-06 mt-5 mb-0 pb-0">
          {{ item.Name }}
          @if(isRequired(item.Code) ){<span class="text-red-600">*</span>}
        </p>
        <tds-tooltip
          placement="right"
          text="{{ getPopupHelpText('phone no', infoBlockId) }}"
          selector="{{ '#phoneno-' + $index + '-tooltip' }}"
          offset-distance="8"
          offset-skidding="0"
          mouse-over-tooltip="true"
          trigger="hover"
        >
        </tds-tooltip>
        <tds-icon
          name="info"
          class="mt-5 text-[#B0B7C4]"
          id="{{ 'phoneno-' + $index + '-tooltip' }}"
          size="15"
        ></tds-icon>
      </div>
      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          state="default"
          label="Label"
          label-position="no label"
          no-min-width
          formControlName="{{ item.Code }}"
          (tdsFocus)="item.Focus = true"
          (tdsBlur)="item.Focus = true"
          [state]="
            getCurrentControl(item.Code)?.invalid &&
            (getCurrentControl(item.Code)?.dirty ||
              getCurrentControl(item.Code)?.touched ||
              item.Focus)
              ? 'error'
              : 'success'
          "
          [helper]="
            utilService.getErrorMsgs(
              getCurrentControl(item.Code),
              item.Name,
              item.Focus,
              3,
              item.Message
            )
          "
        ></tds-text-field>
      </div>
    </div>
    <div
      class="inputField w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 3xl:w-1/2"
    >
      <p class="tds-headline-06 mt-5 mb-0 pb-0">
        {{ getLabelText("Comment", infoBlockId) }}
      </p>

      <div>
        <tds-text-field
          ngDefaultControl
          type="Text"
          size="sm"
          state="default"
          label="Label"
          label-position="no label"
          no-min-width
          formControlName="{{ item.Code }}_comment"
          placeholder="Comment if any"
        ></tds-text-field>
      </div>
    </div>
  </div>
  } }
</form>

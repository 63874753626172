<form [formGroup]="HasAssistanceForm" (change)="saveDraft()">
  @if(this.mode == 'print'){
  <div class="flex flex-row" style="padding: 12px">
    <div>
      <div class="grid grid-cols-1">
        <div class="flex">
          <input type="checkbox" [checked]="HasAssistanceForm.value.IsScaniaAssistance" />
          <label style="font-size: 14px;">{{IsScaniaAssistanceLabel}}</label><br>
        </div>
      </div>
    </div>
  </div>
  }@else {
  <div class="flex flex-row overflow-auto no-scrollbar" style="padding: 12px">
    <div>
      <div class="grid grid-cols-1">
        <div class="flex justify-between">
          <tds-checkbox checked="{{
                HasAssistanceForm.value.IsScaniaAssistance || false
              }}" (tdsChange)="handleChecked($event)" class="pt-1">
            <div slot="label">{{IsScaniaAssistanceLabel}}</div>
          </tds-checkbox>
        </div>
      </div>
    </div>
  </div>
  }

</form>
import {
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CompanyFormService } from '../../../company/company-form.service';
import { UtilsService } from '../../utils.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-imports',
  standalone: false,
  templateUrl: './imports.component.html',
  styleUrl: './imports.component.css',
})
export class ImportsComponent implements OnChanges {
  @Input() companyId: string = '';
  @Input() myId: string = '';
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() infoBlockId:string ='';
  distributorImports: any;
  imports: any = [];
  constructor(
    public utilService: UtilsService,
    public CompanyService: CompanyService,
    private appService: AppService
  ) {}
  distributorImportForm =
    inject(CompanyFormService).form.controls.CompanyDistributorImport;

  ngOnChanges(changes: SimpleChanges): void {
    this.CompanyService.getDistributorImports().subscribe((data: any) => {
      this.distributorImports = data;
      this.distributorImports = this.distributorImports.sort((a:any,b:any)=> 
        a?.Displayorder?.localeCompare(b?.Displayorder));
    });
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.imports = [...this.companyData.CompanyDistributorImport];
    } else {
      this.distributorImportForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (!this.companyData.CompanyDistributorImport) {
        this.companyData.CompanyDistributorImport = [];
      }
    }
  }

  handleChecked(code: any) {
    let index = this.imports.findIndex(
      (item: any) => item.DistributorImportType_code === code
    );
    if (index !== -1) {
      this.imports.splice(index, 1);
      this.distributorImportForm.markAsDirty();

    } else {
      if (this.mode === 'Add') {
        this.imports.push({
          DistributorImportType_code: code,
        });
      } else {
        this.imports.push({
          DistributorImportType_code: code,
          Company_id: this.companyData.myId,
        });
      }
    }
    this.distributorImportForm.markAsDirty();

    this.saveDraft();

  }

  isavilabel(code: any) {
    let value = this.companyData.CompanyDistributorImport.find(
      (i: any) => i.DistributorImportType_code === code
    );
    if (value === undefined || '') {
      return false;
    }
    return true;
  }

  saveDraft() {
    if (this.distributorImportForm.dirty) {
      // this.companyData.CompanyDistributorImport.IsUpdated = true;
      this.companyData.CompanyDistributorImport.forEach(
        (item: any) => (item.IsUpdated = true)
      );
    }
    if (this.mode !== 'Add') {
      this.companyData.CompanyDistributorImport = [...this.imports];
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.companyData.CompanyDistributorImport = [...this.imports];
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}

<form [formGroup]="paymentMethodForm" (change)="saveDraft()">
  @if(this.mode == 'print'){
  <div class="flex flex-row- overflow-auto no-scrollbar" style="padding: 12px">
    <div>
      <div class="grid grid-cols-1 ">
        @for(options of paymentMethods; track $index ;let infoIndex = $index){
          @if(isAvailabel(options.Id)){
            <div class="flex">
              <input type="checkbox" checked name="{{ options.Id }}" value="{{ options.Id }}" />
              <label style="font-size: 14px;" for="{ options.Id }}">{{ options.Name }}</label><br>
            </div>
          }
        }
      </div>
    </div>
  </div>
  }@else {
  <div class="flex flex-row- overflow-auto no-scrollbar" style="padding: 12px">
    <div>
      <div class="grid grid-cols-1 ">
        @for(options of paymentMethods; track $index ;let infoIndex = $index){
        <div class="flex justify-between">
          <tds-checkbox value="{{ options.Id }}" checkbox-id="{{ options.Id }}" checked="{{ isAvailabel(options.Id) }}"
            (tdsChange)="handleChecked(options.Id)" class="pt-1">
            <div slot="label">{{ options.Name }}</div>
          </tds-checkbox>
        </div>
        }
      </div>
    </div>
  </div>
  }

</form>
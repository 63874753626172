import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyService } from './services/company.service';
import { SharedService } from './services/shared.service';
import {
  fieldConfig,
  categorizationConfig,
  locationConfig,
} from './shared/shared';
import { labelTextType } from './administration/label-text/label-text-home/label-text-home.component';
import { Company } from './models/company.model';
import { environment } from '../environments/environment';
import { AccessTypeEnum, TreeTypeEnum, User } from './models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private http: HttpClient,
    private category: CompanyService,
    private sharedService: SharedService
  ) {}

  user: User | undefined;
  private accessTypeEnum: AccessTypeEnum;
  private treeTypeEnum: TreeTypeEnum;

  categoryData: any = [];
  infoBlockData: any = [];
  countriesWithScaniaAssistance: any[] = [];
  labelTextData: labelTextType[] = [];
  companyEditDraftData: any = [];
  companyAddDraftData: Company = new Company();
  addressTypeFieldConfig: fieldConfig[] = [];
  phoneTypeFieldConfig: fieldConfig[] = [];
  contactFieldConfig: fieldConfig[] = [];
  categorizationConfig: categorizationConfig[] = [];
  locationFieldConfig: locationConfig = {
    Latitude: '',
    Longitude: '',
    Timezone: '',
    Type: '',
  };
  selectedCategoryId: string = '';
  selectedCategoryName: string = '';
  countryList: any[] = [];
  displayOrderUrl: string = '';

  get User(): User | undefined {
    return this.user;
  }

  get AccessTypeEnum() {
    return AccessTypeEnum;
  }

  get TreeTypeEnum() {
    return TreeTypeEnum;
  }

  storeUserDetails(user: any) {
    this.user = user;
  }

  storeCountries(countries: any) {
    this.countryList = countries;
  }

  fetchContries() {
    return this.countryList.sort((a, b) => a.Name.localeCompare(b.Name));
  }

  storeCategories(categories: any) {
    this.categoryData = categories;
  }

  fetchCatgories() {
    return this.categoryData;
  }

  storeLabelText(labelText: any) {
    this.labelTextData = labelText;
  }

  fetchLabelText() {
    return this.labelTextData;
  }

  storeCountriesWithScaniaAssistance(countries: any) {
    this.countriesWithScaniaAssistance = countries;
  }

  fetchCountriesWithScaniaAssistance() {
    return this.countriesWithScaniaAssistance;
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    let labelText = this.labelTextData.find(
      (item: any) =>
        item.Label_id.toLocaleLowerCase() === labelId.toLocaleLowerCase() &&
        item.Infoblock_id === infoBlockId
    );
    return labelText?.PopupHelp;
  }

  getLabelText(labelId: string, infoBlockId: string) {
    let labelText = this.labelTextData.find(
      (item: any) =>
        item.Label_id.toLocaleLowerCase() === labelId.toLocaleLowerCase() &&
        item.Infoblock_id === infoBlockId
    );
    return labelText?.Labeltext1;
  }

  fetchCatgoryFieldConfig() {
    let catFieldConfig: any = '';
    var category = this.categoryData.find(
      (item: any) => item?.Name == this.selectedCategoryName
    );

    if (category && category?.FieldconfigDataJson) {
      catFieldConfig = JSON.parse(category?.FieldconfigDataJson);

      for (let prop in catFieldConfig) {
        try {
          catFieldConfig[prop] = catFieldConfig[prop]
            ? JSON.parse(catFieldConfig[prop])
            : '';
        } catch {
          catFieldConfig[prop] = catFieldConfig[prop];
        }
      }
    }

    return catFieldConfig;
  }

  validationRequired() {
    if (this.selectedCategoryName) {
      var fieldConfig = this.fetchCatgoryFieldConfig();

      if (fieldConfig && fieldConfig['CAT-NoRequired'] === false) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  storeCategoryId(id: string) {
    this.selectedCategoryId = id;
  }

  storeCategoryName(name: string) {
    this.selectedCategoryName = name;
  }

  storeCategoryIdByName(name: string) {
    this.selectedCategoryId = this.categoryData.find(
      (item: any) => item.Name == name
    )?.Id;
  }

  fetchCategoryTitle(name: string) {
    return this.categoryData.find((item: any) => item.Name == name)?.Title;
  }

  fetchCateegoryDataByID(id: string) {
    return this.categoryData.find((item: any) => item.Id == id);
  }

  fetchCateegoryDataByName(name: string) {
    return this.categoryData.find((item: any) => item.Name == name);
  }

  fetchSelectedCategoryData() {
    return this.categoryData.find(
      (item: any) => item.Name === this.selectedCategoryName
    );
  }

  formViewModelForPhoneTypes(phoneTypes: any[]) {
    this.phoneTypeFieldConfig = [];
    phoneTypes.forEach((item) => {
      this.phoneTypeFieldConfig.push({
        Name: item.Name,
        Min: 'CP' + item.Code + 'Min',
        Max: 'CP' + item.Code + 'Max',
        Code: item.Code,
        Displayorder: item.Displayorder,
        Validationrule: item.Validationrule,
        Regex: '',
        Type: 'phone',
        Message: '',
      });
    });
  }

  formViewModelForAddressTypes(addressTypes: any[]) {
    this.addressTypeFieldConfig = [];
    addressTypes.forEach((item) => {
      this.addressTypeFieldConfig.push({
        Name: item.Name,
        Min: 'CA' + item.Code + 'Min',
        Max: 'CA' + item.Code + 'Max',
        Code: item.Code,
        Displayorder: item.Displayorder,
        Validationrule: item.Validationrule,
        Regex: '',
        Type: 'address',
        Message: '',
      });
    });
  }

  formViewModelForCategorization(categorizationTypes: any[]) {
    this.categorizationConfig = [];
    categorizationTypes.forEach((item) => {
      this.categorizationConfig.push({
        Name: item.Name,
        Id: item.Id,
        useraccesses: item.useraccesses,
        UsersAllowedToAdminister: [...item.UsersAllowedToAdminister],
        Type: 'categorization',
      });
    });
  }

  formViewModelForContactTypes(contactTypes: any[]) {
    this.contactFieldConfig = [];
    contactTypes.forEach((item) => {
      this.contactFieldConfig.push({
        Name: item.Name,
        Min: 'CC' + item.Code + 'Min',
        Max: 'CC' + item.Code + 'Max',
        Code: item.Code,
        Displayorder: item.Displayorder,
        Validationrule: item.Validationrule,
        Regex: '',
        Type: 'contact',
        Message: '',
      });
    });
  }

  fetchViewModelForPhoneTypes() {
    return this.phoneTypeFieldConfig.filter((item: any) => {
      return item.Type === 'phone';
    });
  }

  fetchViewModelForContactTypes() {
    return this.contactFieldConfig.filter((item: any) => {
      return item.Type === 'contact';
    });
  }

  fetchViewModelForAddressTypes() {
    return this.addressTypeFieldConfig.filter((item: any) => {
      return item.Type === 'address';
    });
  }

  fetchViewModelForCategorizationTypes() {
    return this.categorizationConfig.filter((item: any) => {
      return item.Type === 'categorization';
    });
  }

  storeInfoBlockdata(infoblocks: any) {
    this.infoBlockData = infoblocks;
  }

  fetchInfoBlockdata() {
    return this.infoBlockData;
  }

  fetchCategoryName() {
    return this.selectedCategoryName;
  }

  saveCompanyEditDrafts(companyData: any, id: string) {
    let existingData = this.companyEditDraftData.find(
      (item: any) => item.Id === id
    );

    if (existingData) {
      existingData = companyData;
    } else this.companyEditDraftData.push(companyData);
  }

  saveCompanyAddDrafts(companyData: any) {
    this.companyAddDraftData = companyData;
  }

  fetchCompanyEditDrafts(id: string) {
    let existingData = this.companyEditDraftData.find(
      (item: any) => item.Id === id
    );

    if (existingData) {
      return existingData;
    } else return null;
  }

  fetchCompanyAddDraft() {
    return this.companyAddDraftData;
  }

  removeCompanyEditDrafts(id: string) {
    this.companyEditDraftData = this.companyEditDraftData.filter(
      (item: any) => item.Id !== id
    );
  }

  removeCompanyAddDraft() {
    this.companyAddDraftData = new Company();
  }

  setDisplayOrder(url: string, requestBody: any) {
    return this.http.post(url + '/set-display-order', requestBody);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { addressTypes } from '../adress-types-home/adress-types-home.component';

@Component({
  selector: 'app-list-adress-type',
  templateUrl: './list-adress-type.component.html',
  styleUrl: './list-adress-type.component.css',
})
export class ListAdressTypeComponent {
  @Input() addressTypes: addressTypes[] = [];
  @Output() addressType = new EventEmitter<addressTypes>();
  @Output() mode = new EventEmitter<string>();

  @Input() displayOrderUrl: string = '';

  pageSizes = [10, 20, 30];
  pageSize: number = 10;
  pageIndex: number = 0;
  tableData: addressTypes[] = [];
  @Input() selectedValues: any = {};
  totalPages: any = 0;
  startIndex: number = 0;
  endIndex: number = 0;
  totalItems: number = 0;
  isSort: boolean = true;
  showDisplayOrderModal: boolean = false;
  sortAddressTypeList: any[] = [];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['addressTypes'].previousValue) {
      this.addressTypes = changes['addressTypes'].currentValue;
    }
    this.sortAddressTypeList = this.addressTypes.filter(
      (item: any) => item.Code
    );
    this.sortAddressTypeList = this.sortAddressTypeList.sort((a, b) =>
      a.Code.localeCompare(b.Code)
    );
    this.handlePagination();
  }

  handlePagination() {
    this.totalItems = this.filterData().length;
    this.totalPages = Math.ceil(this.filterData().length / this.pageSize);
    this.startIndex = this.pageIndex * this.pageSize;
    this.endIndex = Number(this.startIndex + this.pageSize);
    this.tableData = this.filterData().slice(this.startIndex, this.endIndex);
  }

  onPageChange(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.handlePagination();
  }

  updateRowCount(event: CustomEvent) {
    this.pageSize = event?.detail?.value;
    this.pageIndex = 0;
    this.handlePagination();
  }

  handlePageSizeChange(event: Event) {
    let element = event.target as HTMLSelectElement;
    this.pageSize = Number(element.value);
    this.handlePagination();
  }

  edit(address: addressTypes) {
    this.addressType.emit(address);
    this.mode.emit('Edit');
  }

  add() {
    this.mode.emit('Add');
  }

  filterList(event: any, columnName: any) {
    if (event?.target?.value || event?.value) {
      this.selectedValues[columnName] = event?.target?.value
        ? event.target.value
        : event.value;
      let tableData: any = this.addressTypes;
      for (let key in this.selectedValues) {
        tableData = tableData.filter((obj: any) =>
          obj[key]
            ?.toString()
            .toLowerCase()
            .trim()
            .includes(this.selectedValues[key]?.toString().toLowerCase().trim())
        );
      }
      this.pageIndex = 0;

      this.totalPages = Math.ceil(tableData.length / Number(this.pageSize));
      this.tableData = tableData.slice(
        this.pageIndex,
        this.pageIndex + Number(this.pageSize)
      );
    } else if (this.selectedValues[columnName]) {
      delete this.selectedValues[columnName];
      this.tableData = this.addressTypes.filter((obj: any) => {
        for (let key in this.selectedValues) {
          if (
            this.selectedValues[key] &&
            !obj[key]
              .toString()
              .toLowerCase()
              .includes(this.selectedValues[key].toLowerCase().toString())
          ) {
            return false;
          }
        }
        return true;
      });
      this.pageIndex = 0;
      this.totalPages = Math.ceil(this.tableData.length / this.pageSize);
      this.startIndex = this.pageIndex * this.pageSize;
      this.endIndex = this.startIndex + this.pageSize;
      this.totalItems = this.tableData.length;
      this.tableData = this.tableData.slice(this.startIndex, this.endIndex);
    }
    this.handlePagination();
  }

  toggleSort(event: any, state: boolean) {
    event.preventDefault();
    this.isSort = state;
  }

  sortColumns(event: any, columnName: string) {
    let tableData: any = this.filterData();
    // console.log(tableData);
    if (event.detail.sortingDirection) {
      if (event.detail.sortingDirection === 'desc') {
        tableData = tableData.sort((a: any, b: any) => {
          return b[columnName]
            .toString()
            ?.localeCompare(a[columnName].toString());
        });
      } else {
        tableData = tableData.sort((a: any, b: any) => {
          return a[columnName]
            .toString()
            ?.localeCompare(b[columnName].toString());
        });
      }
    }

    this.pageIndex = 0;

    const startIndex = 0;
    const endIndex = startIndex + Number(this.pageSize);
    this.tableData = tableData.slice(startIndex, endIndex);
  }

  get fetchOrderUrl() {
    return this.displayOrderUrl;
  }

  filterData() {
    let tableData: any = this.addressTypes;
    tableData = tableData.filter((obj: any) => {
      for (let key in this.selectedValues) {
        if (
          this.selectedValues[key] &&
          !obj[key]
            .toString()
            .toLowerCase()
            .includes(this.selectedValues[key].toLowerCase().toString())
        ) {
          return false;
        }
      }
      return true;
    });
    return tableData;
  }
}

export interface AccessDataItem {
  Data: string;
  CountryList: string;
  CategoryId: string;
}

export interface userDataList {
  Id: string;
  Loginname: string;
  Name: string;
  AccessTypeId: string;
  AccessType: string;
  UniqueCountry: DataEntry[];
  AccessData: AccessDataItem[];
}

export interface categoryList {
  Id: string;
  CatGroup: string;
  DisplayOrder: string;
  FieldconfigDataJson: string;
  Leftinfoblock_Ids: string;
  Name: string;
  Rightinfoblock_Ids: string;
  Title: string;
  leftInfoBlockIdsArrObj?: any[];
  rightInfoBlockIdsArrObj?: any[];
}

export interface countryList {
  Code: string;
  Name: string;
}

export interface DataEntry {
  Data: string;
  CountryList: string[];
}

export interface Infoblock {
  Id: string;
  Name: string;
  Title: string;
  Tablename: string;
  OnlyVisibleToUsers: string;
  OnlyVisibleToGroups: string;
}

export interface Taggroup {
  Id: string;
  Name: string;
  useraccesses?: string;
  UsersAllowedToAdminister?: string[];
}

export interface ActionList {
  name: string;
  active: boolean;
  id: string;
  mode?: string;
  highestDisplayOrder?: string;
  displayOrderUrl: string;
  data: any;
}

export interface toastMode {
  message: string;
  mode?: string;
  subMessage?: string;
}
export class menuItem {
  public static readonly companyMenuItem = 'company';
  public static readonly accessControlMenuItem = 'accessControl';
  public static readonly administrationMenuItem = 'administration';
  public static readonly globalManagementListMenuItem = 'globalManagementList';
}

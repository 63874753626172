import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TegelModule } from '@scania/tegel-angular';
import { CategoryFieldConfig } from '../../../models/category-field.model';
import { CompanyFormService } from '../../../company/company-form.service';
import { CommonModule } from '@angular/common';
import { PhoneTypeService } from '../../../services/phone-type.service';
import { AppService } from '../../../app.service';
import * as uuid from 'uuid';
import { UtilsService } from '../../utils.service';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-phone-fax',

  templateUrl: './phone-fax.component.html',
  styleUrl: './phone-fax.component.css',
})
export class PhoneFaxComponent implements OnChanges, OnInit {
  @Input() catFieldConfig: CategoryFieldConfig | undefined;
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() myId: string = '';
  @Input() infoBlockId: string = '';
  phoneFaxForm = inject(CompanyFormService).form.controls.phoneFaxForm;
  controlArr: any;

  constructor(
    private companyFormService: CompanyFormService,
    private phoneTypeService: PhoneTypeService,
    public appService: AppService,
    public utilService: UtilsService,
    private subjectService: SubjectService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
    } else {
      this.companyData = this.appService.fetchCompanyAddDraft();
    }

    this.phoneFaxForm.reset();
    this.phoneTypeService.getPhoneTypes().subscribe((res: any) => {
      if (res) {
        this.appService.formViewModelForPhoneTypes(res);
        let controlArr = this.companyFormService.getphoneFormFields();
        this.controlArr = controlArr;
        if (controlArr && controlArr.length) {
          controlArr.sort((a: any, b: any) => {
            return Number(a.Displayorder) - Number(b.Displayorder);
          });
          let formGroup =
            this.companyFormService.formphoneFormFields(controlArr);
          if (formGroup) {
            this.phoneFaxForm = formGroup;
          }
          if (
            this.companyData?.CompanyPhone &&
            this.companyData?.CompanyPhone.length
          ) {
            for (let control in this.phoneFaxForm.controls) {
              this.companyData?.CompanyPhone.forEach(
                (obj: any, index: number) => {
                  let num: any = '';
                  if (obj.Childnum) num = Number(obj.Childnum);
                  if (obj.Childnum) {
                    if (control === obj?.Phonetype_code + num) {
                      this.phoneFaxForm?.get(control)?.reset();
                      this.phoneFaxForm.patchValue({
                        [control]:
                          this.companyData?.CompanyPhone[index].Phoneno,
                      });
                    } else if (
                      control ===
                      obj?.Phonetype_code + num + '_comment'
                    ) {
                      this.phoneFaxForm?.get(control)?.reset();
                      this.phoneFaxForm.patchValue({
                        [control]:
                          this.companyData?.CompanyPhone[index].Comment,
                      });
                    }
                  } else {
                    if (control === obj?.Phonetype_code) {
                      this.phoneFaxForm?.get(control)?.reset();
                      this.phoneFaxForm.patchValue({
                        [control]:
                          this.companyData?.CompanyPhone[index].Phoneno,
                      });
                    } else if (control === obj?.Phonetype_code + '_comment') {
                      this.phoneFaxForm?.get(control)?.reset();
                      this.phoneFaxForm.patchValue({
                        [control]:
                          this.companyData?.CompanyPhone[index].Comment,
                      });
                    }
                  }
                }
              );
            }
          }
        }
      }
      this.validate();
    });
  }

  ngOnInit(): void {
    //   let controlArr = this.companyFormService.getphoneFormFields();
    //   this.controlArr = controlArr;
    //   if (controlArr && controlArr.length) {
    //     controlArr.sort((a: any, b: any) => {
    //       return Number(a.Displayorder) - Number(b.Displayorder);
    //     });
    //     let formGroup = this.companyFormService.formphoneFormFields(controlArr);
    //     if (formGroup) {
    //       this.phoneFaxForm = formGroup;
    //       console.log(this.phoneFaxForm);
    //       console.log(this.phoneFaxForm.controls);
    //     }
    //   }
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    return this.appService.getPopupHelpText(labelId, infoBlockId);
  }

  isRequired(code: any, index: number = 0) {
    return this.phoneFaxForm.get(code + (index > 0 ? index : ''))
      ? this.phoneFaxForm
          .get(code + (index > 0 ? index : ''))
          ?.hasValidator(Validators.required)
      : false;
  }

  validate() {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.controlArr?.forEach((item: any) => {
          item.Focus = true;
        });
      }
    });
  }
  saveDraft() {
    for (let control in this.phoneFaxForm.controls) {
      if (this.mode !== 'Add') {
        this.companyData?.CompanyPhone.forEach((obj: any, index: number) => {
          if (obj.Phonetype_code == control) {
            obj['Phoneno'] = this.phoneFaxForm.get(control)?.value;
            obj['Comment'] = this.phoneFaxForm.get(control + '_comment')?.value;
          }
        });
      } else {
      }
    }
    let companyPhoneArr: any[] = [];

    let controlArr = this.companyFormService.getphoneFormFields();
    let obj = {
      Id: '',
      Phonetype_code: '',
      Phoneno: '',
      Comment: '',
      Company_id: '',
    };
    if (controlArr && controlArr.length) {
      controlArr.forEach((item: any) => {
        let subObjArr: any[] = [];
        let subObj: any = {};
        if (item.maxFieldLength > 1) {
          for (let i = 0; i < item.maxFieldLength; i++) {
            subObj = {};
            subObj.Id = uuid.v4();
            subObj.Phonetype_code = item.Code;
            subObj.Phoneno = this.phoneFaxForm.get(
              item.Code + (i ? i : '')
            )?.value;
            subObj.Comment = this.phoneFaxForm.get(
              item.Code + (i ? i : '') + '_comment'
            )?.value;
            subObj.Company_id = this.myId;
            if (i > 0) {
              subObj.Childnum = i + 1;
            }
            subObjArr.push(subObj);
          }
        } else {
          obj = Object.create({});
          obj.Id = uuid.v4();
          obj.Phonetype_code = item.Code;
          obj.Phoneno = this.phoneFaxForm.get(item.Code)?.value;
          obj.Comment = this.phoneFaxForm.get(item.Code + '_comment')?.value;
          obj.Company_id = this.myId;
        }

        if (subObjArr.length) {
          companyPhoneArr = companyPhoneArr.concat(...subObjArr);
        } else {
          companyPhoneArr.push(obj);
        }
      });
      if (companyPhoneArr && companyPhoneArr.length) {
        this.companyData.CompanyPhone = companyPhoneArr;
      }
    }

    if (this.mode !== 'Add') {
      // this.companyData.CompanyPhone = companyPhoneArr;

      if (this.phoneFaxForm?.dirty) {
        this.companyData.CompanyPhone.forEach(
          (item: any) => (item.IsUpdated = true)
        );
      }
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      if (companyPhoneArr && companyPhoneArr.length) {
        this.companyData.CompanyPhone = companyPhoneArr;
        if (this.phoneFaxForm?.dirty) {
          this.companyData.CompanyPhone.forEach(
            (item: any) => (item.IsUpdated = true)
          );
        }
        this.appService.saveCompanyAddDrafts(this.companyData);
      }
    }
  }

  getCurrentControl(code: any, index: number = 0) {
    return this.phoneFaxForm.get(code + (index > 0 ? index : ''));
  }
}

<div class="side-menu-and-main">
  <tds-side-menu aria-label="Side menu" persistent open="{{ mobileNavOpen }}">
    <tds-side-menu-collapse-button (click)="toggleSideMenu()"
      >Collapse</tds-side-menu-collapse-button
    >
    <tds-side-menu-overlay
      (click)="toggleMobileSideMenu()"
      slot="overlay"
    ></tds-side-menu-overlay>

    <tds-side-menu-close-button
      slot="close-button"
      (click)="toggleMobileSideMenu()"
    ></tds-side-menu-close-button>

    @for (item of menuItems; track $index) {
    <tds-side-menu-item selected="{{ item.isActive }}">
      @if(item.Name !== 'Administration' && item.Name !== 'AccessControl'){
      <a
        style="height: 50px"
        routerLink="{{ item.url }}"
        routerLinkActive="active-item"
        (click)="
          this.storeCurrentCategory(item);
          toggleMobileSideMenu();
          toggleCurrentItem(item)
        "
      >
        <img
          src="/images/{{ item.Name.toLowerCase() }}.svg"
          onerror="this.src='images/deleted.svg';"
          alt=""
          width="32"
          height="32"
          title="{{ item.Title }}"
        />
        {{ item.Title }}
      </a>
      } @if(item.Name === 'AccessControl'){
      <!-- <tds-side-menu-dropdown [class]="accessAct===true ? 'active' : ''"> -->

      <tds-side-menu-dropdown>
        <span slot="icon">
          <img
            src="{{ item.image }}"
            alt="{{ item.image }}"
            width="32"
            height="32"
            title="{{ item.Name }}"
          />
        </span>
        <!-- <span slot="label" style="display: contents">
          <img
            src="{{ item.image }}"
            alt="{{ item.image }}"
            width="32"
            height="32"
            title="{{ item.Name }}"
          />
         </span
        > -->
        <span slot="label" style="display: contents">{{ item.Title }}</span>

        @for (submitem of item.submenuItem; track $index) {
        <tds-side-menu-dropdown-list>
          <tds-side-menu-dropdown-list-item selected="{{ submitem.isActive }}">
            <a (click)="navigetAccessUrl(submitem); toggleMobileSideMenu()">
              <img
                src="{{ submitem.image }}"
                alt=""
                width="20"
                height="20"
                style="padding: 10px"
              />
              {{ submitem.description }}
            </a>
          </tds-side-menu-dropdown-list-item>
        </tds-side-menu-dropdown-list>
        }
      </tds-side-menu-dropdown>
      } @if(item.Name === 'Administration'){
      <tds-side-menu-dropdown>
        <span slot="icon">
          <img
            src="{{ item.image }}"
            alt="{{ item.image }}"
            width="32"
            height="32"
          />
        </span>

        <span slot="label" style="display: contents">{{ item.Title }}</span>

        @for (subritem of item.submenuItem; track $index) {
        <tds-side-menu-dropdown-list>
          <tds-side-menu-dropdown-list-item selected="{{ subritem.isActive }}">
            <a (click)="navigetAdminUrl(subritem); toggleMobileSideMenu()">
              <img
                src="{{ subritem.image }}"
                alt=""
                width="20"
                height="20"
                style="padding: 10px"
              />
              {{ subritem.description }}
            </a>
          </tds-side-menu-dropdown-list-item>
        </tds-side-menu-dropdown-list>
        }
      </tds-side-menu-dropdown>
      }
    </tds-side-menu-item>
    }

    <tds-side-menu-item slot="end">
      @if(this.isAuthenticated){

      <div>
        <tds-side-menu-dropdown>
          <tds-side-menu-user
            slot="label"
            heading="{{ userName }}"
            subheading="{{ email }}"
          ></tds-side-menu-user>

          <tds-side-menu-dropdown-list>
            <tds-side-menu-dropdown-list-item>
              <a routerLink="logout">
                <!-- <fa-icon [icon]="faPowerOff" style="width: 15px"></fa-icon> -->
                <div
                  class="tds-u-pl1"
                  (click)="logout()"
                  style="padding-left: 5px"
                >
                  Logout
                </div>
                <!--  <tds-button 
                  id="my-tooltip-button"
                  type="button"
                  style="padding-left: 18px;"
                  variant="primary"
                  size="xs"
                  mode-variant="primary"
                  text="{{ 'Logout' }}"
                  (click)="logout()"
                >
                </tds-button> -->
              </a>
            </tds-side-menu-dropdown-list-item>
          </tds-side-menu-dropdown-list>
        </tds-side-menu-dropdown>
      </div>
      }
    </tds-side-menu-item>
  </tds-side-menu>
</div>

<!-- <main class="tds-u-w-100">
    <div class="tds-u-h-100">
      <div class="switcher-container" *ngIf="!is404page">
        <app-mode-switcher
          (modeToggle)="handleModeToggle()"
        ></app-mode-switcher>
        <app-mode-variant-switcher
          (modeVariantToggle)="handleModeVariantToggle()"
        ></app-mode-variant-switcher>
      </div>
      <div class="tds-u-h-100 tds-u-flex tds-u-flex-dir-col">
        <nav-breadcrumbs *ngIf="!is404page"></nav-breadcrumbs>

        <div
          [ngClass]="
            is404page
              ? 'tds-u-h-100'
              : 'main-container tds-u-pl3 tds-u-pr3 tds-u-pb3 tds-u-relative'
          "
        >
          <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </main> -->
<!-- </div> -->

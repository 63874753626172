import {
  Component,
  inject,
  Input,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CategoryFieldConfig } from '../../../models/category-field.model';
import { CompanyFormService } from '../../../company/company-form.service';
import { AddressTypeService } from '../../../services/address-type.service';
import { AppService } from '../../../app.service';
import { UtilsService } from '../../utils.service';
import { CountryService } from '../../../services/country.service';
import * as uuid from 'uuid';
import { FormControl, Validators } from '@angular/forms';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrl: './addresses.component.css',
})
export class AddressesComponent {
  @Input() catFieldConfig: CategoryFieldConfig | undefined;
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() myId: string = '';
  @Input() infoBlockId: string = '';
  addressForm = inject(CompanyFormService).form.controls.addressForm;
  controlArr: any;
  countryList: any[] = [];

  constructor(
    private companyFormService: CompanyFormService,
    private addressTypeService: AddressTypeService,
    public appService: AppService,
    public utilService: UtilsService,
    private subjectService: SubjectService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
    } else {
      this.companyData = this.appService.fetchCompanyAddDraft();
    }
    this.countryList = this.appService.fetchContries();
    this.addressForm.reset();
    this.addressTypeService.getAddressTypes().subscribe((res: any) => {
      if (res) {
        this.appService.formViewModelForAddressTypes(res);
        let controlArr = this.companyFormService.getaddressFormFields();
        this.controlArr = controlArr;
        if (controlArr && controlArr.length) {
          controlArr.sort((a: any, b: any) => {
            return Number(a.Displayorder) - Number(b.Displayorder);
          });
          let formGroup =
            this.companyFormService.formAddressFormFields(controlArr);
          if (formGroup) {
            this.addressForm = formGroup;
            // console.log(this.addressForm);
          }
          if (
            this.companyData?.CompanyAddress &&
            this.companyData?.CompanyAddress.length
          ) {
            for (let control in this.addressForm.controls) {
              this.companyData?.CompanyAddress.forEach(
                (obj: any, index: number) => {
                  let num: number = 0;
                  if (obj.Childnum) num = Number(obj.Childnum);
                  if (obj.Childnum || obj.Childnum == 0) {
                    switch (control) {
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_company_name':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index].CompanyName,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_country':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index].Country,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_postal_city':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index].Postalcity,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_postal_city_local':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index]
                              .Postalcity_local,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_postal_code':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index].Postalcode,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_streetAddress':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index]
                              .Streetaddress,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_streetAddress_local':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index]
                              .Streetaddress_local,
                        });
                        break;
                      case obj?.['Addresstype_code'] + (num ? num : '') + '_id':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]: this.companyData?.CompanyAddress[index].Id,
                        });
                        break;
                      case obj?.['Addresstype_code'] +
                        (num ? num : '') +
                        '_comment':
                        this.addressForm?.get(control)?.reset();
                        this.addressForm.patchValue({
                          [control]:
                            this.companyData?.CompanyAddress[index].Comment,
                        });
                        break;
                    }
                  }
                }
              );
            }
          }

          this.validate();
        }
      }
    });
  }

  ngOnInit(): void {
    //   let controlArr = this.companyFormService.getphoneFormFields();
    //   this.controlArr = controlArr;
    //   if (controlArr && controlArr.length) {
    //     controlArr.sort((a: any, b: any) => {
    //       return Number(a.Displayorder) - Number(b.Displayorder);
    //     });
    //     let formGroup = this.companyFormService.formphoneFormFields(controlArr);
    //     if (formGroup) {
    //       this.addressForm = formGroup;
    //       console.log(this.addressForm);
    //       console.log(this.addressForm.controls);
    //     }
    //   }
  }

  validate() {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.controlArr?.forEach((item: any) => {
          if (item.maxFieldLength > 1) {
            for (let i = 0; i < item.maxFieldLength; i++) {
              if (item.companyShow) {
                this.setFocusElement(item, 'CompanynameFocus', i);
                this.setFocusElement(item, 'countryFocus', i);
              }
              this.setFocusElement(item, 'postCodeFocus', i);
              this.setFocusElement(item, 'StreetaddressFocus', i);
              this.setFocusElement(item, 'StreetaddressLocalFocus', i);
              this.setFocusElement(item, 'commentFocus', i);
              this.setFocusElement(item, 'postalCityFocus', i);
              this.setFocusElement(item, 'postalCityLocalFocus', i);
            }
          } else {
            if (item.companyShow) {
              item.CompanynameFocus = true;
              item.countryFocus = true;
            }
            item.postCodeFocus = true;
            item.StreetaddressFocus = true;
            item.StreetaddressLocalFocus = true;
            item.commentFocus = true;
            item.postalCityFocus = true;
            item.postalCityLocalFocus = true;
          }
        });
      }
    });
  }

  getLabelText(labelId: string, infoBlockId: string) {
    return this.appService.getLabelText(labelId, infoBlockId);
  }

  getPopupHelpText(labelId: string, infoBlockId: string) {
    return this.appService.getPopupHelpText(labelId, infoBlockId);
  }

  saveDraft() {
    let CompanyAddressArr: any[] = [];

    let controlArr = this.companyFormService.getaddressFormFields();
    let obj = {
      Id: '',
      Addresstype_code: '',
      Comment: '',
      Company_id: '',
      Country: '',
      Postalcity: '',
      Postalcity_local: '',
      Postalcode: '',
      Postalcode_local: '',
      Streetaddress: '',
      Streetaddress_local: '',
      Companyname: '',
      Childnum: 0,
    };
    if (controlArr && controlArr.length) {
      controlArr.forEach((item: any) => {
        let subObjArr: any[] = [];
        let subObj: any = {};
        if (item.maxFieldLength > 1) {
          for (let i = 0; i < item.maxFieldLength; i++) {
            if (this.mode !== 'Add') {
              subObj.Id =
                this.addressForm.get(item.Code + (i ? i : '') + '_id')?.value ||
                uuid.v4();
            } else subObj.Id = uuid.v4();
            subObj.Addresstype_code = item.Code;
            subObj.Streetaddress = this.addressForm.get(
              item.Code + (i ? i : '') + '_streetAddress'
            )?.value;
            subObj.Streetaddress_local = this.addressForm.get(
              item.Code + (i ? i : '') + '_streetAddress_local'
            )?.value;
            subObj.Postal_city = this.addressForm.get(
              item.Code + (i ? i : '') + '_postal_city'
            )?.value;
            subObj.Postal_city_local = this.addressForm.get(
              item.Code + (i ? i : '') + '_postal_city_local'
            )?.value;
            subObj.Postal_code = this.addressForm.get(
              item.Code + (i ? i : '') + '_postal_code'
            )?.value;
            subObj.Postal_code_local = this.addressForm.get(
              item.Code + (i ? i : '') + '_postal_code_local'
            )?.value;
            subObj.Companyname = this.addressForm.get(
              item.Code + (i ? i : '') + '_company_name'
            )?.value;
            subObj.Comment = this.addressForm.get(
              item.Code + (i ? i : '') + '_comment'
            )?.value;
            subObj.Country = this.addressForm.get(
              item.Code + (i ? i : '') + '_country'
            )?.value;
            subObj.Company_id = this.myId;
            if (i > 0) {
              subObj.Childnum = i + 1;
            }
            subObjArr.push(subObj);
          }
        } else {
          obj = Object.create({});
          obj.Addresstype_code = item.Code;
          obj.Id = this.addressForm.get(item.Code + '_id')?.value || uuid.v4();
          obj.Streetaddress = this.addressForm.get(
            item.Code + '_streetAddress'
          )?.value;
          // console.log(obj.Streetaddress);
          obj.Streetaddress_local = this.addressForm.get(
            item.Code + '_streetAddress_local'
          )?.value;
          obj.Postalcity = this.addressForm.get(
            item.Code + '_postal_city'
          )?.value;
          obj.Postalcity_local = this.addressForm.get(
            item.Code + '_postal_city_local'
          )?.value;
          obj.Postalcode = this.addressForm.get(
            item.Code + '_postal_code'
          )?.value;
          obj.Postalcode_local = this.addressForm.get(
            item.Code + '_postal_code_local'
          )?.value;
          obj.Country = this.addressForm.get(item.Code + '_country')?.value;
          obj.Companyname = this.addressForm.get(
            item.Code + '_company_name'
          )?.value;
          obj.Comment = this.addressForm.get(item.Code + '_comment')?.value;
          obj.Company_id = this.myId;
          obj.Childnum = 0;
        }

        if (subObjArr.length) {
          CompanyAddressArr.push(...subObjArr);
        } else {
          CompanyAddressArr.push(obj);
        }
      });

      if (CompanyAddressArr && CompanyAddressArr.length) {
        this.companyData.CompanyAddress = CompanyAddressArr;
      }
    }

    if (this.mode !== 'Add') {
      if (this.addressForm?.dirty) {
        this.companyData.CompanyAddress.forEach(
          (item: any) => (item.IsUpdated = true)
        );
      }

      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      if (CompanyAddressArr && CompanyAddressArr.length) {
        this.companyData.CompanyAddress = CompanyAddressArr;
        if (this.addressForm?.dirty) {
          this.companyData.CompanyAddress.forEach(
            (item: any) => (item.IsUpdated = true)
          );
        }
        this.appService.saveCompanyAddDrafts(this.companyData);
      }
    }
  }

  getCurrentControl(code: any, index: number = 0, name: string) {
    return this.addressForm.get(code + (index > 0 ? index : '') + name);
  }

  isRequired(code: any, index: number = 0, name: string) {
    return this.addressForm.get(code + (index > 0 ? index : '') + name)
      ? this.addressForm
          .get(code + (index > 0 ? index : '') + name)
          ?.hasValidator(Validators.required)
      : false;
  }

  getFormName(code: any, index: number = 0, name: string) {
    return code + (index > 0 ? index : '') + name;
  }

  setFocusElement(item: any, name: string, index: number) {
    item[name + index] = true;
  }

  getFocusElement(item: any, name: string, index: number) {
    return item[name + index];
  }

  changeCountry(event: any, control: string, value: any) {
    if (event.detail.value) {
      this.addressForm.patchValue({
        [control]: event.detail.value,
      });
      value = event.detail.value;
      this.addressForm.markAsDirty();
      this.saveDraft();
    }
  }
}

import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CategoryFieldConfig } from '../../../models/category-field.model';
import { CompanyFormService } from '../../../company/company-form.service';
import { CompanyService } from '../../../services/company.service';
import { AppService } from '../../../app.service';
import { UtilsService } from '../../utils.service';
import * as uuid from 'uuid';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubjectService } from '../../../services/subject.service';

@Component({
  selector: 'app-categorization',
  standalone: false,
  templateUrl: './categorization.component.html',
  styleUrl: './categorization.component.css',
})
export class CategorizationComponent implements OnInit {
  @Input() catFieldConfig: CategoryFieldConfig | undefined;
  @Input() mode: string = '';
  @Input() companyData: any;
  @Input() companyId: string = '';
  @Input() myId: string = '';
  @Input() infoBlockId: string = '';

  categorizationForm = inject(CompanyFormService).form;
  controlArr: any;
  DropDowndata: any[][] = [];
  CompanyTag: any[] = [];
  categorizationFromFocus = false;
  validationRequired: boolean = false;

  constructor(
    public companyFormService: CompanyFormService,
    private companyService: CompanyService,
    private appService: AppService,
    public utilService: UtilsService,
    private cdr: ChangeDetectorRef,
    private subjectService: SubjectService
  ) {}
  ngOnInit(): void {
    this.subjectService.validationCompanySubject.subscribe((data) => {
      if (data) {
        this.categorizationFromFocus = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyData'].currentValue && this.mode !== 'Add') {
      this.companyData = changes['companyData'].currentValue;
      this.CompanyTag = [...this.companyData.CompanyTag];

      this.categorizationFromFocus = true;
      this.getCategorization().clear();

      this.companyData.CompanyTag.forEach((item: any) => {
        const TagGroup = new FormGroup({
          Company_id: new FormControl(item?.Company_id),
          Tag_id: new FormControl(item?.Tag_id),
        });

        // if (this.companyFormService.checkAllFormValidationDetails()) {
        //   this.validationRequired = true;
        //   TagGroup.controls.Tag_id.addValidators(Validators.required);
        // } else {
        //   this.validationRequired = false;
        //   TagGroup.controls.Tag_id.removeValidators(Validators.required);
        // }
        this.getCategorization().push(TagGroup);
      });
    } else {
      this.categorizationForm.reset();
      this.companyData = this.appService.fetchCompanyAddDraft();
      if (this.companyData.CompanyTag) {
        this.CompanyTag = [...this.companyData.CompanyTag];
      }
      this.getCategorization().clear();
    }

    this.companyService.getTagGroup().subscribe((res: any) => {
      if (res) {
        this.appService.formViewModelForCategorization(res);
        let controlArr = this.companyFormService.getCategorizationFormFields();
        this.controlArr = controlArr;

        if (controlArr && controlArr?.length) {
          this.getDropDownData();
          if (
            !this.companyData.CompanyTag ||
            !this.companyData.CompanyTag?.length
          ) {

            for (let formarr of this.controlArr) {
              const TagGroup = new FormGroup({
                Company_id: new FormControl(''),
                Tag_id: new FormControl(''),
              });
              if (formarr?.required) {

                this.validationRequired = true;
                TagGroup.controls.Tag_id.addValidators(Validators.required);
                TagGroup.controls.Tag_id.updateValueAndValidity();
              } else {
                this.validationRequired = false;
                TagGroup.controls.Tag_id.removeValidators(Validators.required);
              }
              this.getCategorization().push(TagGroup);
            }
          } else {
            while (this.getCategorization().length < controlArr.length) {
              const TagGroup = new FormGroup({
                Company_id: new FormControl(''),
                Tag_id: new FormControl(''),
              });          
              this.getCategorization().push(TagGroup);
            }
            for (let [ index, formarr ]of this.controlArr.entries()) {
              if (formarr.required) {
                this.validationRequired = true;
                this.getControls(index,'Tag_id').addValidators(Validators.required);
                this.getControls(index,'Tag_id').updateValueAndValidity();
              } else {
                this.validationRequired = false;
                this.getControls(index,'Tag_id').removeValidators(Validators.required);
              }
            }
          }
          if (this.mode == 'Add') {
            const tag = this.getCategorization().at(0) as FormGroup;
            tag.get('Tag_id')?.setValue('');
            this.categorizationFromFocus = true;
          }
        }
      }
    });
  }

  getDefaultValue(index: any): string {
    if (this.DropDowndata[index]) {
      for (let tag of this.CompanyTag) {
        for (let data of this.DropDowndata[index]) {
          if (tag?.Tag_id === data?.Id) {
            return tag?.Tag_id;
          }
        }
      }
    }

    return '';
  }

  getControls(index: any, fieldName: string) {
    return (this.getCategorization().at(index) as FormGroup)?.controls[
      fieldName
    ];
  }

  getCategorization() {
    return this.categorizationForm.get('categorizationForm') as FormArray;
  }

  getErrorMsgs(
    index: any,
    fieldName: string,
    focus: boolean = false,
    Name: string
  ) {
    const control = (this.getCategorization().at(index) as FormGroup)?.controls[
      fieldName
    ];
    if (control?.hasError('required')) {
      return `${Name} is required.`;
    }
    return '';
  }

  async getDropDownData() {
    for (let arr of this.controlArr) {
      let result: any = await this.companyService
        .getTagGroupValue(arr.Id)
        .toPromise();
      if (result) {
        result.Tag = result.Tag.sort(
          (a: any, b: any) => a.Name.localeCompare(b.Name)
        );
        this.DropDowndata.push([...result.Tag]);
      }
    }
    this.cdr.detectChanges();
    // console.log('res--new-DropDowndata---', this.DropDowndata[0]);
  }

  handleCategorizationDropDown(event: any, index: any, item: any) {
    const tag = this.getCategorization().at(index) as FormGroup;

    if (
      event.detail.value === 'acbbe539-eed2-4cc8-83e1-7f5607e5ba19' ||
      event.detail.value === '6aa5ec91-f8c4-437f-8229-8f0ab7564f0b' ||
      event.detail.value === 'f32f9cb3-c75b-4293-8a33-b8661c75375c' ||
      event.detail.value === '2f29278c-e279-46fc-b12a-72c6806d3988' ||
      event.detail.value === '51d80e8a-6804-4a3f-8eb7-96b53d72a06d'
    ) {
      tag.get('Tag_id')?.setValue('');
      this.categorizationFromFocus = true;
    } else {
      tag.get('Tag_id')?.setValue(event.detail.value);

      this.CompanyTag[index] = {
        Tag_id: event.detail.value,
        Company_id: this.companyData.Id,
        Tag: { Taggroup_id: item.Id },
      };
    }

    // console.log('res--new-CompanyTag---', this.CompanyTag);
    this.categorizationForm.markAsDirty();
    this.saveDraft();
  }

  saveDraft() {
    this.companyData.CompanyTag = [...this.CompanyTag];

    if (this.categorizationForm.dirty) {
      this.companyData.CompanyTag.IsUpdated = true;
    }

    if (this.mode !== 'Add') {
      this.appService.saveCompanyEditDrafts(
        this.companyData,
        this.companyData.Id
      );
    } else {
      this.appService.saveCompanyAddDrafts(this.companyData);
    }
  }
}
